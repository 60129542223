import "../styles/globals.css";
import { ThemeProvider } from "next-themes";
import Layout from "../components/layout";
import { Provider, useDispatch } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import "../utils/i18n";
import Meta from "../components/Meta";
import UserContext from "../components/UserContext";
import Header01 from "../components/header/Header01";
import { useEffect, useState, useRef } from "react";
import { ref, get, getDatabase, set, child, onValue } from "firebase/database";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";
import styles from "../styles/style";
import * as React from "react";
import { AuthContext, AuthContextProvider } from "../context/AuthContext";
import { ChatContextProvider } from "../context/ChatContext";
import { MetaMaskProvider } from "metamask-react";
import { deepmerge } from "@mui/utils";
import {
  useColorScheme,
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendMuiTheme,
} from "@mui/material/styles";
import { extendTheme as extendJoyTheme } from "@mui/joy/styles";
import { onSnapshot, doc } from "@firebase/firestore";
import { IconButton, Slide, Snackbar } from "@mui/material";
import { db } from "../utils/fire";
import Link from "next/link";
import CloseIcon from "@mui/icons-material/Close";
import { Trans, useTranslation } from "react-i18next";
import CookieConsent from "../components/ui/CookieConsent";
import Wallet_modal from "../components/modal/wallet_modal";
import { buyModalShow } from "../redux/counterSlice";

export const routes = [
  {
    name: "root",
    path: "/",
    public: true,
  },
  {
    name: "login",
    path: "/login",
    public: true,
  },
  {
    name: "requests",
    path: "/requests",
    public: false,
  },
  {
    name: "messages",
    path: "/messages",
    public: false,
  },
  {
    name: "create",
    path: "/create",
    public: false,
  },
  {
    name: "order",
    path: "/orders/newOrder/[newOrder]",
    public: false,
  },
  {
    name: "earnings",
    path: "/earnings",
    public: false,
  },
  {
    name: "editprofile",
    path: "/profile/editProfile",
    public: false,
  },
];
function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0,
  });

  const muidakTheme = React.useMemo(
    () =>
      createTheme({
        ...styles["dark"],
      }),
    []
  );

  const joyTheme = extendJoyTheme({
    // This is required to point to `var(--mui-*)` because we are using
    // `CssVarsProvider` from Material UI.
    palette: {
      mode: "dark",
    },
  });

  // Note: you can't put `joyTheme` inside Material UI's `extendMuiTheme(joyTheme)`
  // because some of the values in the Joy UI theme refers to CSS variables and
  // not raw colors.
  const muiTheme = extendMuiTheme(muidakTheme);

  // You can use your own `deepmerge` function.
  // muiTheme will deeply merge to joyTheme.
  const theme = deepmerge(joyTheme, muiTheme);

  useEffect(() => {
    // if (pid === '/home/home_8') {
    // 	const html = document.querySelector('html');
    // 	html.classList.remove('light');
    // 	html.classList.add('dark');
    // }
  }, []);
  const noNav = pid === "/login";
  //|| pid.includes('messages')

  return (
    <>
      <Meta />

      <Provider store={store}>
        {/* <CssVarsProvider theme={theme}> */}
        <ThemeProvider
          enableSystem={false}
          defaultTheme="dark"
          attribute="class"
        >
              <MetaMaskProvider>
          <AuthContextProvider>
            <ChatContextProvider>
              <UserContext.Provider value={{ scrollRef: scrollRef }}>
            
                  <InnerComponent
                    Component={Component}
                    pageProps={pageProps}
                    noNav={noNav}
                  />
              
              </UserContext.Provider>
            </ChatContextProvider>
          </AuthContextProvider>
          </MetaMaskProvider>
        </ThemeProvider>
        {/* </CssVarsProvider> */}
      </Provider>
    </>
  );
}

const InnerComponent = (props) => {
  const { currentUser } = React.useContext(AuthContext);
  const { pageProps, noNav, Component } = props;

  const [chats, setSortedChats] = useState(null);
  const [open, setOpen] = React.useState(true);
  // const [transition, setTransition] = React.useState(<Slide direction="up" />);
  const router = useRouter();
  useEffect(() => {
    if (router?.pathname) {
      const found = routes.find((r) => router?.pathname === r.path);

      if (found) {
        if (!currentUser && !found?.public) {
          router.push("/login");
        }
      }
    }
    if (currentUser) {
   
      if (currentUser.onboarded === false) {
       
        router.push("/login/intro");
      }
    }
  }, [router?.pathname, currentUser]);
  const dispatch = useDispatch()
  useEffect(() => {
    if(currentUser?.influencer){

      get(child(ref(getDatabase()), `purchases/${currentUser.uid}`)).then(
                   (snapshot) => {
                     if (snapshot.exists()) {
                      
                         const paymentData = snapshot.val()
                         const paymentarr = []
                         Object.keys(paymentData).map((key) => paymentarr.push(paymentData[key]))
                          let canUse = false
                         paymentarr.map((payment) => {
                           if(payment){
                             const purchaseDate = new Date(payment.date);
                             const expirationDate = new Date(purchaseDate.getFullYear(), purchaseDate.getMonth() + parseInt(payment.duration), purchaseDate.getDate());
                           
                           
                             const currentDate = new Date();
                             if (currentDate <= expirationDate && payment.paymentStatus === "paid") {
                                    payment.valid = true
                                    canUse = true
                             }
                             else{
                               payment.valid = false
                             }
                           
                           return payment
                           }
                         })
                         if (!canUse) {
                          
                           if (process.env.NODE_ENV === 'production') {
                             // Generates a random number between 0 and 99
                             const randomNumber = Math.floor(Math.random() * 100);

                             // Checks if the random number falls within the desired range
                             if (randomNumber >= 0 && randomNumber < 40) {
                               dispatch(buyModalShow())
                             } 
                          
                           } 
                           set(ref(getDatabase(), `influencers/${currentUser.uid}/paid`), false)
                         } 
                     }
                     else{
                      
                       if (process.env.NODE_ENV === 'production') {
                         const randomNumber = Math.floor(Math.random() * 100);

                         // Checks if the random number falls within the desired range
                         if (randomNumber >= 20 && randomNumber < 40) {
                           dispatch(buyModalShow())
                         } 
                         set(ref(getDatabase(), `influencer/${currentUser.uid}/paid`), false)
                       } 

                     }
                   })
                 
 }
  }, [currentUser])

  useEffect(() => {
    const getChats = () => {
      const unsub = onSnapshot(doc(db, "userChats", currentUser.uid), (doc) => {
        if (doc.exists()) {
          setSortedChats(
            Object.entries(doc.data())?.sort((a, b) => b[1].date - a[1].date)
          );
        }
      });

      return () => {
        unsub();
      };
    };

    if (currentUser) {
      const db = getDatabase();
      const order_path = `activeOrders/${currentUser.uid}`;
      const starCountRef = ref(db, order_path);

      onValue(starCountRef, (snapshot) => {
        if (snapshot.exists()) {
          const orders = snapshot.val();
        } else {
          currentUser?.uid && getChats();
        }
      });
    }
  }, [currentUser?.uid]);

  useEffect(() => {
    if (chats) {
      if (chats?.length>0 && chats[0][1]?.userInfo.uid === currentUser?.uid) {
        setOpen(false);
      }
    }
  }, [chats, currentUser]);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpen(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const handleClose = () => {
    setOpen(false);
  };
  const { t } = useTranslation();

  return (
    <>
      <Snackbar
        className="fade"
        open={open}
        onClose={handleClose}
        autoHideDuration={20 * 1000}
        // TransitionComponent={transition}
        // key={transition ? transition.name : ""}
      >
        <div
          className="toast__wrapper hover:bg-accent-dark block overflow-hidden text-ellipsis whitespace-nowrap max-w-[50vw] md:max-w-[50vw]  "
          style={{
            background:
              "linear-gradient(0deg, rgb(72 24 169) 0%, rgb(66 50 104 / 71%) 100%)",
            animation: `fadeIn ${2}s`,
          }}
        >
          {chats && chats?.length>0 && (
            <div className={`userChat `}>
              <img src={chats[0][1]?.userInfo?.photoURL} alt="" />
              <Link href={`messages/${chats[0][1].userInfo.uid}`}>
                <div className="userChatInfo">
                  <span>{chats[0][1].userInfo?.displayName}</span>
                  <p
                    className="break-all"
                    style={{ whiteSpace: "break-spaces" }}
                  >
                    {chats[0][1].lastMessage?.hasOwnProperty("offer") ? (
                      <Trans
                        i18nKey="newOffer"
                        t={t}
                        values={{
                          amount: chats[0][1]?.lastMessage.offer.amount,
                        }}
                        components={{
                          spanwrap: (
                            <b className="text-green font-bold">
                              {" "}
                              placeholder{" "}
                            </b>
                          ),
                        }}
                      />
                    ) : (
                      chats[0][1].lastMessage?.text.slice(0, 40)
                    )}
                  </p>
                </div>
              </Link>
              {action}
            </div>
          )}
        </div>
      </Snackbar>
    <Wallet_modal/>
      <CookieConsent />

      {noNav ? (
        <>
          <Component {...pageProps} />
        </>
      ) : (
        <Layout>
          <Component {...pageProps} />
        </Layout>
      )}
    </>
  );
};

export default MyApp;
