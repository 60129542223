import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bidsModalHide } from "../../redux/counterSlice";
import { useRouter } from "next/router";
import { ref, getDatabase, child, set, get } from "firebase/database";
import More_items from "./../../pages/item/more_items";
import { AuthContext } from "./../../context/AuthContext";
import { v4 as uuid } from "uuid";
import { useContext } from "react";
import { axiosInstance } from "../../utils/Axios";
import {
  doc,
  Timestamp,
  updateDoc,
  arrayUnion,
  serverTimestamp,
} from "@firebase/firestore";
import { db } from "../../utils/fire";
import { ChatContext } from "../../context/ChatContext";
import { useTranslation } from "react-i18next";
const BidsModal = () => {
  const { bidsModal } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const [price, setprice] = useState(0.05);
  const router = useRouter();
  const [selected, setSelected] = useState([]);
  const [total, setTotal] = useState(0);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    setprice(total);
  }, [total]);

  const { requestid, authorid, messageId } = router.query;
  const { t } = useTranslation();
  const { data } = useContext(ChatContext);
  const handleBid = async () => {
    // todo - register the bid in realtime database individually
    let totalFollowers = 1000;
    const snapshot = await get(
      child(ref(getDatabase()), `influencers/${currentUser?.uid}/followers`)
    );
    const categories = await get(
      child(ref(getDatabase()), `influencers/${currentUser?.uid}/categories`)
    );
    if (snapshot.exists) {
      totalFollowers = snapshot.val();
    }
    const followersPerUsd = parseInt(totalFollowers / price);
    const id = uuid();
    let bidData = {
      bid: price,
      followersPerUsd,
      totalFollowers,
      selected,
      id,
      categories: categories.val(),
      username: currentUser.displayName,
      imageURL: currentUser.imageURL,
      bidderUid: currentUser.uid,
      userObj: currentUser.influencerObj,
      totalWithoutDiscount: total,
      discount: parseInt(total - price),
      timestamp: Timestamp.now(),
    };
    if (requestid) {
      await set(
        ref(
          getDatabase(),
          `bids/requests/${requestid}/bids/${currentUser.uid}`
        ),
        {
          ...bidData,
        }
      );
      // WOULD BE GOOD TO GET SELLER UID FOR ANOTHER SET TO /OFFERS
    } else if (messageId) {
      const text = t("newOffer", {
        amount: price + "$",
      });
      const offer = {
        tkey: "newOffer",
        amount: price,
      };

      await set(
        ref(
          getDatabase(),
          `bids/offers/${data.user.uid}/${currentUser.uid}/${bidData.id}`
        ),
        {
          ...bidData,
          buyerUid: data.user.uid,
        }
      );

      await updateDoc(doc(db, "chats", data.chatId), {
        messages: arrayUnion({
          id,
          text,
          type: "OFFER",
          bidData,
          senderId: currentUser.uid,
          date: Timestamp.now(),
        }),
      });
      await updateDoc(doc(db, "userChats", data.user.uid), {
        [data.chatId + ".lastMessage"]: {
          text,
          offer,
        },
        [data.chatId + ".date"]: serverTimestamp(),
      });
    }

    dispatch(bidsModalHide());
  };

  const [user, setUser] = useState({
    images: [],
  });

  useEffect(() => {
    const getUser = async () => {
      const user = await axiosInstance.get(
        `/influencers/user/${currentUser?.uid}`
      );

      setUser(user.data.data);
    };
    if (currentUser?.uid && bidsModal && currentUser?.influencer) {
      getUser();
    }
  }, [currentUser?.uid, bidsModal]);

  const handleprice = (e) => {
    e.preventDefault();
    setprice(e.target.value);
  };
  const disabled = price < 1;
  return (
    <div>
      <div className={`${bidsModal ? "modal fade show block" : "modal fade"} `}>
        <div
          className="modal-dialog sm:w-[92%] md:w-[70%]  md:h-[auto] sm:
				
				"
        >
          <div
            className="modal-content 
					
				
					"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                Place a bid
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(bidsModalHide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div
              className="modal-body p-6 	
					
					sm:max-h-[60vh]
					fuckTailwind-modal
					scrollbar-custom
					overflow-auto"
            >
              <More_items
                priceHook={setTotal}
                selectedItemsHook={selected}
                title="Select what services you will provide"
                subtitle="Don't worry about the prices, you can set your own figure at the bottom of this dialog."
                user={user}
                fabHidden
                selectedHook={setSelected}
              />

             
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer flex w-full flex-col">
              <div className="text-center dark:border-jacarta-600 mb-2 border-jacarta-100 border-b border-b font-display text-jacarta-700 font-semibold dark:text-white  text-md">
                Client will be charged: &nbsp;
                <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border w-[auto]">
                  {/* <div className="border-jacarta-100 bg-jacarta-50 flex flex-1 items-center self-stretch border-r px-2">
									<span>
										<svg className="icon icon-ETH mr-1 h-5 w-5">
											<use xlinkHref="/icons.svg#icon-ETH"></use>
										</svg>
									</span>
									<span className="font-display text-jacarta-700 text-sm">ETH</span>
								</div> */}

                  <input
                    type="number"
                    className="focus:ring-accent h-12 w-full flex-[3]  focus:ring-inset dark:text-white text-end "
                    placeholder="Amount"
                    value={price}
                    onChange={(e) => handleprice(e)}
                  />

                  <div className="bg-jacarta-50 border-jacarta-100 flex flex-1 justify-end self-stretch border-l dark:text-jacarta-700">
                    <span className="self-center px-2 text-sm">${price}</span>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center space-x-4">
                <button
                  onClick={handleBid}
                  type="button"
                  disabled={disabled}
                  className={`
									${
                    disabled ? "bg-gray" : "bg-accent"
                  } shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all
									`}
                >
                  Place Bid
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BidsModal;
