const help_center_data = [
	{
		id: '0Getting started',
		title: 'Getting started',
		text: 'Learn how to create an account, set up your wallet, and what you can do.',
	},
	{
		id: '1Buying',
		title: 'Buying',
		text: 'Learn how to create an account, set up your wallet, and what you can do.',
	},
	{
		id: '2Selling',
		title: 'Selling',
		text: 'Learn how to create an account, set up your wallet, and what you can do.',
	},
	{
		id: '3Creating',
		title: 'Creating',
		text: 'Learn how to create an account, set up your wallet, and what you can do.',
	},
	{
		id: '4User Safety',
		title: 'User Safety',
		text: 'Learn how to create an account, set up your wallet, and what you can do.',
	},
	{
		id: '5Partners',
		title: 'Partners',
		text: 'Learn how to create an account, set up your wallet, and what you can do.',
	},
];

const accordion_data = [
	{
		id: '0How do I create an NFT?',
		title: 'How do I create an NFT?',
		text: 'Learn how to create your very first NFT and how to create your NFT collections. Unique, fully 3D and built to unite the design multiverse. Designed and styled by Digimental.',
	},
	{
		id: '1How can I stay safe and protect my NFTs ?',
		title: 'How can I stay safe and protect my NFTs ?',
		text: 'Learn how to create your very first NFT and how to create your NFT collections. Unique, fully 3D andbuilt to unite the design multiverse. Designed and styled by Digimental.\n                  ',
	},
	{
		id: '2What are the key terms to know in NFTs and Web3 ?',
		title: 'What are the key terms to know in NFTs and Web3 ?',
		text: 'Learn how to create your very first NFT and how to create your NFT collections. Unique, fully 3D andbuilt to unite the design multiverse. Designed and styled by Digimental.\n                  ',
	},
	{
		id: '3How do I sell an NFT ?',
		title: 'How do I sell an NFT ?',
		text: 'Learn how to create your very first NFT and how to create your NFT collections. Unique, fully 3D andbuilt to unite the design multiverse. Designed and styled by Digimental.\n                  ',
	},
	{
		id: '4Smart Contract Upgrade: What You Need to Know',
		title: 'Smart Contract Upgrade: What You Need to Know',
		text: 'Learn how to create your very first NFT and how to create your NFT collections. Unique, fully 3D andbuilt to unite the design multiverse. Designed and styled by Digimental.\n                  ',
	},
];

export { help_center_data, accordion_data };
