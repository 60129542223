import React, { useState } from 'react';

import 'react-tabs/style/react-tabs.css';


const getClassByVariation = (v) => {
	if(v){
	  return `alert-${v}`
	}
	return 'alert-error'
  }
  
   const AlertMessage = ({headline, text, children, variation})=> {
	  return (
			  <div className={`relative mx-auto self-start rounded-2.5xl  ${getClassByVariation(variation)} dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border p-8  shadow-2xl tranisition-all text-center pb-2 `} style={{animation: 'fadeIn 1s'}}>
							  <div className="absolute hidden md:block right-4 top-4">
								<svg
								  xmlns="http://www.w3.org/2000/svg"
								  viewBox="0 0 24 24"
								  width="24"
								  height="24"
								  className="h-12 w-12 fill-white/50"
								>
								  <path fill="none" d="M0 0h24v24H0z"></path>
								  <path d="M5.455 15L1 18.5V3a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1v12H5.455zm-.692-2H16V4H3v10.385L4.763 13zM8 17h10.237L20 18.385V8h1a1 1 0 0 1 1 1v13.5L17.545 19H9a1 1 0 0 1-1-1v-1z"></path>
								</svg>
							  </div>
							  <div>
								<span className="mb-2 block font-display text-lg text-white ">
								 {headline}
								</span>
								<p className="mb-4 text-white max-w-[90%] mx-auto">
							   {text}
								</p>
				   {children}
							   
							  </div>
							</div>
	  )
  }
export default AlertMessage


