import Head from "next/head";
export const getAppName = (appname) => {
  if (appname === "INFLUENCERS") {
    return "Influenz";
  }
  if (appname === "CARDOCS") {
    return "Cardocs";
  }
  if (appname === "DENERO") {
    return "Denero";
  }
};

export const getRedirectUrl = (appname) => {
  if (appname === "INFLUENCERS") {
    return "/influencers";
  }
  if (appname === "CARDOCS") {
    return "/car-services";
  }
  if (appname === "DENERO") {
    return "/service-providers";
  }
};

const getKeywordsAndDesc = (appname) => {
  let obj = {
    titleWords: "",
    desc: "",
    keyword: "",
  };
  if (appname === "INFLUENCERS") {
    obj = {
      titleWords: "Hire an influencer / content creator",
      desc: "Influenz is a peer to peer marketplace where influencers sell their campaigns and can find new clients. We also feature a robust bidding system, Stripe ESCROW payments, data-driven suggestions. Join our network of global content creators and let us create a community where sellers and buyers can prosper and thrive.",
      keyword:
        "Influencers, marketing, instagram, content creation, advertising, SEO, IG stories for sale, buy ig stories, buy shoutouts, gain followers",
    };
  }
  if (appname === "CARDOCS") {
    obj = {
      titleWords: "Visi servisai vienoje vietoje",
      desc: "Cardocs - vie6a visų servisų suvestinė. Raskite Jums prieinamą servisą greitai, patikimai ir patogiai. Platformoje veikia virš tūkstančio servisų Lietuvoje.",
      keyword:
        "servisai, auto remontas, servisas Vilniuje, servisas salia manes, servisas near me, servisas greitai, online servisas, masinos taisymas",
    };
  }
  if (appname === "DENERO") {
    obj = {
      titleWords: "Raskite paslaugos tiekėją",
      desc: "Denero - platforma vienijantį pačius įvairiausius meistrus. Rinkis iš plataus, patikimo darbininkų tinklo. Čia rasite viską - nuo mezgėjos iki IT architekto. Siekiame kurti skaidrią paslaugų platformą, todėl atsargiai atrūšiuojame savo vartotojus.",
      keyword:
        "santechnikas, meistras uber, issikviesti meistra, meistras i namus, meistras online, paslaugos pardavimas, santechniku aukcionas",
    };
  }
  return obj;
};

const appName = getAppName(process.env.NEXT_PUBLIC_APP_NAME || "INFLUENCERS");
const meta = getKeywordsAndDesc(appName);
const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{`${appName} | ${title}`}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: `${appName}`,
  keyword: meta.keyword,
  desc: meta.desc,
};

export default Meta;
