import Cookies from "js-cookie";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const USER_CONSENT_COOKIE_KEY = "cookie_consent_is_true";
const USER_CONSENT_COOKIE_EXPIRE_DATE = 365;

const CookieConsent = () => {
  const { t } = useTranslation();
  const [cookieConsentIsTrue, setCookieConsentIsTrue] = useState(true);

  useEffect(() => {
    const consentIsTrue = Cookies.get(USER_CONSENT_COOKIE_KEY) === "true";
    setCookieConsentIsTrue(consentIsTrue);
  }, []);

  const onClick = (e) => {
    e.preventDefault();

    if (!cookieConsentIsTrue) {
      Cookies.set(USER_CONSENT_COOKIE_KEY, "true", {
        expires: USER_CONSENT_COOKIE_EXPIRE_DATE,
      });
      setCookieConsentIsTrue(true);
    }
  };

  if (cookieConsentIsTrue) {
    return null;
  }

  return (
    <section
      className="fixed bottom-1 left-0 w-full py-2 md:py-4 z-50 "
      style={{ animation: "fadeIn 2s" }}
    >
      <div className="block container mx-auto bg-jacarta-700  rounded-xl">
        <div className="flex  flex-col items-start p-5 md:p-3 space-y-2 bg-gray-200 md:flex-row md:space-y-0 md:items-stretch md:space-x-2">
          <div className="flex items-center flex-grow text-gray-900">
            <p className="text-sm ">
              {t("privacyPolicyConsent")}{" "}
              <Link href="/tarms">
                <a className="text-sm underline hover:text-lightAccent">
                  {t("privacyPolicy")}
                </a>
              </Link>
              .
            </p>
          </div>
          <div className="flex items-center self-end">
            <button
              className="rounded-full bg-white py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
              onClick={onClick}
            >
              {t("gotIt")}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CookieConsent;
