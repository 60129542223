import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import OfferTab from './OfferTab';

import 'react-tabs/style/react-tabs.css';

import { CircularProgress, Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { child, getDatabase,get, ref, set } from 'firebase/database';
import FileUpload from '../ui/FileUpload';
import { uuidv4 } from '@firebase/util';
import { HeadLine } from '../component';
import { CustomDropdown } from '../collections/collection_category_filter';
const socialData = [
	{ id: 1, text: "Instagram" },
	{ id: 2, text: "Facebook" },
	{ id: 3, text: "Tiktok" },
	{ id: 4, text: "Twitch" },
	{ id: 5, text: "RealLife" },
	{ id: 6, text: "Youtube" },
  ]
  
const ItemsTabs = ({bids, actionsEnabled,uid, schedule, type, services, shadow, customServices, images, canEdit, moreItems, specialOffers}) => {
	const [tabsActive, setTabsActive] = useState(1);
	const tabsHeadText = [
		{
			id: 1,
			text: 'Pasiūylmai',
			icon: 'activity',
		},
		{
			id: 2,
			text: 'Kliento grafikas',
			icon: 'details',
		},
		// {
		// 	id: 3,
		// 	text: 'details',
		// 	icon: 'details',
		// },
		// {
		// 	id: 4,
		// 	text: 'activities',
		// 	icon: 'activity',
		// },
		// {
		// 	id: 5,
		// 	text: 'price history',
		// 	icon: 'price',
		// },
	];

	const serviceTabs = [
		{
			id: 1,
			text: `Custom packages (${customServices?.length || 0})`,
			icon: 'price',
		},
		...(!shadow
			? [
					{
						id: 2,
						text: `Services`,
						icon: 'offers',
					},
			  ]
			: []),
	];
	
	// customServices ?
	// [
		
	// 	{
	// 		id: 1,
	// 		text: `Paslaugų rinkiniai`,
	// 		icon: 'price',
	// 	},
	// 	{
	// 		id: 2,
	// 		text: `Paslaugos (${services ? services?.length : ''})`,
	// 		icon: 'offers',
	// 	},
	// 	{
	// 		id: 3,
	// 		text: 'Užimtumas',
	// 		icon: 'details',
	// 	},
	// ]
	// :
	// [
		
	
	// 	{
	// 		id: 1,
	// 		text: `Paslaugos (${services ? services?.length : ''})`,
	// 		icon: 'offers',
	// 	},
	// 	{
	// 		id: 2,
	// 		text: 'Užimtumas',
	// 		icon: 'details',
	// 	},
	// ]

	
	

  const activeTabs = type === 'service' ? serviceTabs : tabsHeadText
 const {t} = useTranslation()
	return (
		<>
		<div className=" mt-14  rounded-lg">
				{/* <!-- Tabs Nav --> */}
				<Tabs className="min-w-auto tabs " >
					<TabList className="nav nav-tabs flex items-center overflow-hidden overflow-x-auto ">
						{/* <!-- Offers --> */}
						{activeTabs.map(({ id, text, icon }) => {
							
							// if(id===2  && schedule?.length<3){
								
							// 		return <div></div>
							// }
							return (
								<Tab className="nav-item bg-transparent" key={id}  >
									<button
										className={
											tabsActive === id
												? 'nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white active'
												: 'nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white'
										}
										onClick={() => setTabsActive(id)}
									>
										<svg className="icon mr-1 h-5 w-5 fill-current">
											<use xlinkHref={`/icons.svg#icon-${icon}`}></use>
										</svg>
										<span className="font-display text-base font-medium">{text}</span>
									</button>
								</Tab>
							);
						})}
					</TabList>

					{
						type!=='service' &&
						<>
						<TabPanel className="tab-content">
						<OfferTab bids={bids} actionsEnabled={actionsEnabled} />
					</TabPanel>
					<TabPanel className="tab-content">
					<>
					{
						!schedule || schedule?.length<3  &&
							<div className={`relative mx-auto self-start rounded-2.5xl alert-error dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border p-8  shadow-2xl tranisition-all text-center pb-2 `} style={{animation: 'fadeIn 1s'}}>
							<div className="absolute hidden md:block right-4 top-4">
							  <svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								width="24"
								height="24"
								className="h-12 w-12 fill-white/50"
							  >
								<path fill="none" d="M0 0h24v24H0z"></path>
								<path d="M5.455 15L1 18.5V3a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1v12H5.455zm-.692-2H16V4H3v10.385L4.763 13zM8 17h10.237L20 18.385V8h1a1 1 0 0 1 1 1v13.5L17.545 19H9a1 1 0 0 1-1-1v-1z"></path>
							  </svg>
							</div>
							<div>
							  <span className="mb-2 block font-display text-lg text-white ">
							   {schedule ? t('notSelectedTimesClient') : t('notSelectedTimesClient')}
							  </span>
							  <p className="mb-4 text-white max-w-[90%] mx-auto">
							  {schedule ? t('notSelectedTimesSubClient') : t('notSelectedTimesSub')}
							  </p>
				
							 
							</div>
						  </div>
						}
					
			
					
					</>
					
					</TabPanel>
						</>
					}

{
						type==='service' &&
						<>

{
	
	<TabPanel className="tab-content ">
		
					{canEdit ? <SpecialOffers uid={uid} canEdit={canEdit} images={images} customServices={customServices}/> : specialOffers}
					</TabPanel>
}
					
						<TabPanel className="tab-content">
						{moreItems}
					</TabPanel>
					<TabPanel className="tab-content">
					<>
					{
						
						!schedule || schedule?.length<3  &&
							<div className={`relative mx-auto self-start rounded-2.5xl alert-error dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border p-8  shadow-2xl tranisition-all text-center pb-2 `} style={{animation: 'fadeIn 1s'}}>
							<div className="absolute hidden md:block right-4 top-4">
							  <svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								width="24"
								height="24"
								className="h-12 w-12 fill-white/50"
							  >
								<path fill="none" d="M0 0h24v24H0z"></path>
								<path d="M5.455 15L1 18.5V3a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1v12H5.455zm-.692-2H16V4H3v10.385L4.763 13zM8 17h10.237L20 18.385V8h1a1 1 0 0 1 1 1v13.5L17.545 19H9a1 1 0 0 1-1-1v-1z"></path>
							  </svg>
							</div>
							<div>
							  <span className="mb-2 block font-display text-lg text-white ">
							   {schedule ? t('notSelectedTimes') : t('notSelectedTimes')}
							  </span>
							  <p className="mb-4 text-white max-w-[90%] mx-auto">
							  {schedule ? t('notSelectedTimesSub') : t('notSelectedTimesSub')}
							  </p>
				
							 
							</div>
						  </div>
						}
						
				
					
					</>
					
					</TabPanel>
						</>
					}
					
					{/* <TabPanel>
						<Properties />
					</TabPanel>
					<TabPanel>
					
						<div
							className="tab-pane fade"
							id="details"
							role="tabpanel"
							aria-labelledby="details-tab"
						>
							<div className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-white p-6 md:p-10">
								<div className="mb-2 flex items-center">
									<span className="dark:text-jacarta-300 mr-2 min-w-[9rem]">Contract Address:</span>
									<a href="#" className="text-accent">
										0x1cBB182322Aee8ce9F4F1f98d7460173ee30Af1F
									</a>
								</div>
								<div className="mb-2 flex items-center">
									<span className="dark:text-jacarta-300 mr-2 min-w-[9rem]">Token ID:</span>
									<span
										className="js-copy-clipboard text-jacarta-700 cursor-pointer select-none dark:text-white"
										data-tippy-content="Copy"
									>
										7714
									</span>
								</div>
								<div className="mb-2 flex items-center">
									<span className="dark:text-jacarta-300 mr-2 min-w-[9rem]">Token Standard:</span>
									<span className="text-jacarta-700 dark:text-white">ERC-721</span>
								</div>
								<div className="flex items-center">
									<span className="dark:text-jacarta-300 mr-2 min-w-[9rem]">Blockchain:</span>
									<span className="text-jacarta-700 dark:text-white">Ethereum</span>
								</div>
							</div>
						</div>
					</TabPanel>
					<TabPanel>
						<Activity_tab />
					</TabPanel>
					<TabPanel>
						<Price_history classes="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-white p-6" />
					</TabPanel> */}

				</Tabs>
			</div>
		</>
	);
};

const SpecialOffers = ({customServices, canEdit, uid, images}) => {
	const [services, setServices] = useState([])

	const [editableService, setEditable] = useState({
		product: '', price: '$', detail:'', img: '', socialmedias: [], social: '', pricePostTax: 0
	})
	
	const [socialmedias, setSocial] = useState(null);
	const [files, setFiles] = useState([]);
	const [fetching, setFetching] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
       if(socialmedias){
		setEditable({
			...editableService, social: socialmedias[0]?.text, socialmedias
		})
	   }
	}, [socialmedias])

	useEffect(() => {
		if(editableService?.price){
		try{
			const newPrice = parseInt(parseInt(editableService?.price)*1.2)
			setEditable({
				...editableService, pricePostTax: newPrice
			})
		}
		catch(e){
			console.log(e)
		}
		}
	 }, [editableService?.price])


   useEffect(() => {
         if(files){
			setEditable({...editableService, img: files[0]?.fileURL})
		 } else{
			setEditable({...editableService, img: ''})
		 }
   }, [files])

   useEffect(() => {
	if(customServices){
		setServices([...customServices, ...services])
	}
	
}, [customServices])


const handleDelete = async (service) => {
	if(!canEdit){
		return
	}
  const arrayOfServices = services.filter((s) => JSON.stringify(s)!==JSON.stringify(service))
	setServices(arrayOfServices)

await set(ref(getDatabase(), `influencers/${uid}/customServices`), arrayOfServices);
}

   const saveData = async () => {
	if(disabled){
		return
	}
	setFetching(true);
	try {
	  const snapshot = await get(child(ref(getDatabase()), `influencers/${uid}/customServices`));
	  let arrayOfServices = [];
     
	  if (snapshot.exists()) {
		arrayOfServices = [...snapshot.val(), editableService]
		
		
	  } else {
		arrayOfServices = [editableService];
	  }

	
  
	  await set(ref(getDatabase(), `influencers/${uid}/customServices`), arrayOfServices);

	  setServices(arrayOfServices);
	  setEditable({ 	product: '', price: '$', detail:'', img: '', socialmedias: [], social: '', pricePostTax: 0});
	  setSocial(null)
	  setFiles([]);
	} catch (error) {
	  console.log(error)
	}
  
	setFetching(false);
  };
  
   
	const disabled = !editableService.product || !editableService.price || !editableService.detail || !editableService.img || fetching || !files || loading
    const activeClasses = disabled ? 'bg-jacarta-400 cursor-not-allowed' : 'bg-white cursor-pointer hover:bg-accent'
	
	return (
		<div className='dark:bg-jacarta-800 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-white 	 max-w-[85vw]
		md:max-w-auto p-6 md:p-5 '>

      {
		!!services?.length>0 ?
<div className='py-8'>
<HeadLine   image="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/1f525.png" 
classes='font-display text-jacarta-700  text-center text-3xl dark:text-white container' text={'Special bundles'} ></HeadLine>
      <h2 className="dark:text-jacarta-300  text-lg leading-normal2 text-center mb-5 container">
       
          <>
            <span className="text-accent-light">
              <b>Hot</b>{" "}
            </span>{" "}
           deals
         
          </>
       
      </h2>
</div>
		:
		<div className='mb-[20px]'> <AlertMessage headline={'No custom deals'} text={'Influencer has not set his special deals'}/> </div>
	  }


			<Grid spacing={3} container>

			
			
           {
			services?.map((service, key) => {
				if(service?.product?.length<2 || !service?.product)
				return <></>


				return (
					<Grid item xs={12} md={key===0 ? 12 : true} key={key}>
					 
                    <div
                      className={` 
					  min-w-auto md:min-w-[33vw]
            ${0===2 ? 'dark:bg-jacarta-700 border borders border-accent' : 'dark:bg-jacarta-900 border borders border-transparent'}
										 dark:border-jacarta-700s
										 border-jacarta-100s 
										 borders
										 shadow-lg
                    ${!canEdit ? ' zoom-sm' : ''}
										 hover:shadow-[0_16px_24px_-8px_rgba(131,88,255,.05),0_-6px_2px_-8px_rgba(131,88,255,.05)]
										 hover:shadow-[0_16px_24px_-8px_rgba(131,88,255,.1),0_-6px_2px_-8px_rgba(131,88,255,.1)]
										 h-full

                     transition-all
										 rounded-2.5xl relative flex items-center  bg-white  transition-shadow flex flex-col md:flex-row gap-3 py-0   `}
                     style={{animation: 'fadeIn .7s'}}
                    // onClick={() => setProducts(products.includes(key) ?  products.filter((p) => p!==key ) : [...products, key])}
                    >
                     
                        <img
                          src={service.img || key<images.length && images[key]?.fileURL || '/images/avatars/man_1.png' }
                          alt={key+'img'}
                          
                          objectfit="cover"
                          className={`rounded-2lg rounded-[20px] overflow-hidden w-full h-full min-w-[150px] my-auto object-cover border-accent  border-b-8 md:border-b-0  border-r-0 md:border-r-8 `}
                          loading="lazy"
                          style={{animation: 'fadeIn 1s'}}
                        />
                     

                      <div className="flex flex-col self-stretch    justify-center w-full px-5 my-3 md:my-5">
                   
                        {/* <div style={{display:'inline-flex'}}>
                        <div className="my-1 flex text-center dark:border-jacarta-600 group bg-accent border-jacarta-100 font-display  h-9 items-center rounded-lg border px-4 text-sm font-semibold trans">
                             {service?.social}
                          </div>
                          </div> */}
                        <h3 className="font-display text-lg text-jacarta-700 mb-1  font-semibold dark:text-white">
                    {service?.product}
                        </h3>
                       <div>
                      
                       </div>
                        <div className="">
                          <span className="dark:text-jacarta-200 text-jacarta-500 mb-3 block text-sm  mr-1">
                            <a href="#" className="text-green text-sm font-bold tracking-tight ">
                            {service?.price} $
                            </a>
                           

                         
                         
                          </span>
                       

                          <span className="dark:text-jacarta-200 text-jacarta-500 mb-3 mt-2 block text-sm  mr-1">
                            <span className=" text-md  tracking-tight " style={{wordBreak:'break-word'}}>
							{service?.detail}
                            </span>
                            

                         
                         
                          </span>
                        </div>
                        </div>
                    
                       
                     

                      
                    

                      <div className={`dark:border-jacarta-600 border-jacarta-100 ml-auto block ${canEdit ? 'alert-red w-13 h-9 items-center justify-center flex' : ''} rounded-full
					   border p-3 m-1 md:p-1 md:m-1 md:mx-3`}>
                       <button disabled={!canEdit}  onClick={() => handleDelete(service)}>
						{
							!canEdit ? <>
							   <svg className="icon fill-jacarta-700 dark:fill-white h-6 w-6">
                          <use xlinkHref={`/icons.svg#icon-purchases`}></use>
                        </svg>
							</>
							:
							<svg
							xmlns="http://www.w3.org/2000/svg"
							
							className="fill-jacarta-700 h-6 w-6 dark:fill-white"
						>
							<path fill="none" d="M0 0h24v24H0z" />
							<path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
						</svg>
						}
					  
					   </button>
                      </div>
                    </div>
                 
				  </Grid>
				)
			})
		   }

{
			
				


				canEdit && (
                  <Grid item xs={12} md={12} sx={{pb:7}}>
					         <hr className="dark:bg-jacarta-600 bg-jacarta-100 my-10 mt-6 md:mt-6 mx-0 h-px border-0" />
                    <div
                      className={` 
					  dark:bg-jacarta-900
										 dark:border-jacarta-700s
										 border-accent-dark
										 border
										 borders
										 shadow-lg
										 shadow-[0_-5px_0_0_#8358ff] 
        shadow-[0_-5px_0_0_#8358ff,0_5px_0_0_#8358ff]
        shadow-[0_16px_24px_-8px_rgba(131,88,255,.3),0_-6px_2px_-8px_rgba(131,88,255,.3)]
										 h-full
									
										 rounded-2.5xl relative flex items-center  bg-white  transition-shadow flex flex-col md:flex-row gap-5 p-5 py-10`}
                     style={{animation: 'fadeIn .7s'}}
                    >
                      <figure className="h-full flex w-full   rounded-lg">
                       <div className='rounded-2lg w-full h-auto min-w-auto md:min-w-[322px] my-auto'>
						<FileUpload
						hideOnLoad
						  hook={files}
						  label={'Image*'}
						  fileTypes={'images'}
						  maxFiles={1}
						  loadingHook={setLoading}
						  setHook={setFiles}
						  uploadKey={`influencers/${uid}/customServices/${uuidv4()}`}
						/>
					   </div>
                      </figure>

                      <div className="flex flex-col self-stretch  justify-center w-full">
                   <div className='pb-3 max-w-[250px]'>
				   <CustomDropdown
            data={socialData}
            type="Social media platforms"
			cls={"social-media"}
            
            onSelect={setSocial}
          />
				   </div>
                        <div>
                        <span className="font-display items-center gap-3 text-lg text-jacarta-700 mb-1  font-semibold dark:text-white">
						{
								    <input
									type="text"
									id="item-title"
									maxLength={70}
									value={editableService.product}
									onChange={(e) => setEditable({ ...editableService, product: e.target.value })}
									className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-auto rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
									placeholder={("Service name")}
									required
								  />
							}
				    {/* {service?.title} */}
					 {/* <div class="flex cursor-pointer p-2 rounded-full bg-white hover:bg-accent transition-all
					  border-jacarta-100 border items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="fill-jacarta-400 h-4 w-4 group-hover:fill-white"><path fill="none" d="M0 0h24v24H0z"></path><path d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z"></path></svg></div> */}
                        </span>

                        <div className="mt-3">
                          <span className="dark:text-jacarta-200 text-jacarta-500 mb-3 block text-sm  mr-1">
						  {
								    <input
									type="number"
									id="item-cost"
									maxLength={70}
									value={editableService.price}
									onChange={(e) => setEditable({ ...editableService, price: e.target.value })}
									className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-auto rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
									placeholder={("Price")}
									required
								  />
							}
                            <span  className="text-green ml-2 text-lg font-bold tracking-tight ">
							$
                            </span>
                           

                         
                         
                          </span>
                       

                          <span className="dark:text-jacarta-200 text-jacarta-500 mb-3 mt-2 block text-sm  mr-1">

						  <textarea
                id="item-desc"
                className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                rows="4"
                value={editableService.detail}
                onChange={(e) =>
                  setEditable({ ...editableService, detail: e.target.value })
                }
                required
                placeholder={'Description'}
              ></textarea>

                            {/* <span className=" text-md  tracking-tight ">
							{service?.desc}
                            </span>
                             */}

                         
                         
                          </span>
                        </div>
                        </div>
                    
                       
                     

                      
                      </div>

                      <div className="dark:border-jacarta-600 border-jacarta-100 ml-auto rounded-full border p-3 m-3">
				{
					!fetching ? 
					<button
					onClick={saveData}
					disabled={disabled}
					className={
					  `group 
					   border-accent flex h-12 w-12 shrink-0 items-center justify-center rounded-full border
						bg-white ${activeClasses}
					   hover:border-transparent transtion-all`
					}
				   style={{transition: 'all .3s'}}
				  >
					<svg
					  xmlns="http://www.w3.org/2000/svg"
					  viewBox="0 0 24 24"
					  width="24"
					  height="24"
					  className="fill-accent group-hover:fill-white transition-all"
					>
					  <path fill="none" d="M0 0h24v24H0z" />
					  <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
					</svg>
				  </button>
				  :
				 <div className='bg-white rounded-full p-2'>
					 <CircularProgress sx={{ color: "#5b2abd" }} />
					 </div>
				}
                      </div>
                    </div>
                 
				  </Grid>
				)
			}
		   
		   </Grid>
		</div>
	)
}



const getClassByVariation = (v) => {
  if(v){
	return `alert-${v}`
  }
  return 'alert-error'
}

export const AlertMessage = ({headline, text, children, variation})=> {
	return (
			<div className={`relative mx-auto self-start rounded-2.5xl  ${getClassByVariation(variation)} dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border p-8  shadow-2xl tranisition-all text-center pb-2 `} style={{animation: 'fadeIn 1s'}}>
							<div className="absolute hidden md:block right-4 top-4">
							  <svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								width="24"
								height="24"
								className="h-12 w-12 fill-white/50"
							  >
								<path fill="none" d="M0 0h24v24H0z"></path>
								<path d="M5.455 15L1 18.5V3a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1v12H5.455zm-.692-2H16V4H3v10.385L4.763 13zM8 17h10.237L20 18.385V8h1a1 1 0 0 1 1 1v13.5L17.545 19H9a1 1 0 0 1-1-1v-1z"></path>
							  </svg>
							</div>
							<div>
							  <span className="mb-2 block font-display text-lg text-white ">
							   {headline}
							  </span>
							  <p className="mb-4 text-white max-w-[90%] mx-auto">
							 {text}
							  </p>
				 {children}
							 
							</div>
						  </div>
	)
}


export default ItemsTabs;
