import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../locale/en.json'

const resources = {
    lt: {
        translation: en,
    },
    en: {
        translation: en,
    },

};


i18n.use(initReactI18next).init({
    resources,
    lng: 'en',
    interpolation: {
        escapeValue: false,
    },
});
