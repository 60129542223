import { useContext, useState } from "react";
import { getRedirectUrl } from "../Meta";
import { v4 as uuidv4 } from "uuid";
import Router, { useRouter } from "next/router";
import { ref, get, getDatabase, set, child } from "firebase/database";
import { serverTimestamp } from "@firebase/firestore";
import { AuthContext } from "../../context/AuthContext";
import React from "react";
import GroupedAutocomplete from "./GroupedAutocomplete";

export const SearchBar = ({ label, onSearch,setToggle, small }) => {
  const [search, setSearch] = useState("");
  const { currentUser } = useContext(AuthContext);
    const r = useRouter()
    
  const handleKey = (e) => {
    if (e.key === "Enter") {
      set(ref(getDatabase(), `searches/${uuidv4()}`), {
        timestamp: serverTimestamp(),
        author: currentUser ? currentUser?.uid : "anonymous",
        keyword: search,
      });
      Router.push(
        `${getRedirectUrl(
          process.env.NEXT_PUBLIC_APP_NAME || "INFLUENCERS"
        )}?search=${search}`
      );
      if(setToggle){
        setToggle(false)
      }
    }
  };


  const handleSearch = (e) => {
   
      set(ref(getDatabase(), `searches/${uuidv4()}`), {
        timestamp: serverTimestamp(),
        author: currentUser ? currentUser?.uid : "anonymous",
        keyword: e?.title,
      });
      Router.push(
        `${getRedirectUrl(
          process.env.NEXT_PUBLIC_APP_NAME || "INFLUENCERS"
        )}?search=${e?.title?.replace(/"/g, '')}`
      );
      if(setToggle){
        setToggle(false)
      }
    
  };
  //  if(hideSearch.some((s) => s===r.pathname)){
  //   return <React.Fragment></React.Fragment>
  //  }

  return (
  
      <GroupedAutocomplete freeSolo onSelect={handleSearch} label={label}
    classes={small ? 'relative ml-12 mr-8 hidden basis-3/12 lg:block xl:ml-[8%]' : 'relative mb-4'}/>
   
  )

  return small ? (
    <div className="relative ml-12 mr-8 hidden basis-3/12 lg:block xl:ml-[8%]">
      <div className="relative mb-0">
        <input
          type="search"
          className="w-2/3 rounded-2xl border border-jacarta-100 py-4 px-4 pl-10 text-md text-jacarta-700 placeholder-jacarta-300 focus:ring-accent dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
          placeholder={label? label : 'Search'}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={(e) => handleKey(e)}
          value={search}
        />
      </div>
      <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={24}
          height={24}
          className="fill-jacarta-500 h-4 w-4 dark:fill-white"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
        </svg>
      </span>
    </div>
  ) : (
    <div className="relative mb-4">
      <input
        type="search"
        className="w-full rounded-2xl border border-jacarta-100 py-4 px-4 pl-10 text-md text-jacarta-700 placeholder-jacarta-300 focus:ring-accent dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
        placeholder={label}
        onChange={(e) => setSearch(e.target.value)}
        onKeyPress={(e) => handleKey(e)}
        value={search}
      />
      <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className="h-4 w-4 fill-jacarta-500 dark:fill-white"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
        </svg>
      </span>
    </div>
  );
};
