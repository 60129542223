import React, { useState } from "react";
import Link from "next/link";
import { items_offer_data } from "../../data/items_tabs_data";
import CountUp from "react-countup";
import SendIcon from "@mui/icons-material/Send";
import ChatIcon from "@mui/icons-material/Chat";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import More_items from "../../pages/item/more_items";
import { OfferComponent } from "../chat/Message";
const OfferTab = ({ bids, actionsEnabled }) => {
  const {currentUser} = useContext(AuthContext)
  const [show, setShow] = useState(false)
  const [activeBid, setBid] = useState(null)
 
  return (
    <>
      {/* <!-- Offers --> */}
       {
        show && <OfferModal setShow={setShow} activeBid={activeBid}     disabled={!actionsEnabled} currentUser={currentUser} />
       }
      <div
        className="tab-pane fade show active  "
        id="offers"
        role="tabpanel"
        aria-labelledby="offers-tab"
      >
        <div
          role="table"
          className={`scrollbar-custom dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 grid max-h-[50vh]
					 w-full ${
             actionsEnabled ? "grid-cols-5" : "grid-cols-4"
           } overflow-y-auto rounded-lg rounded-tl-none border 
					 bg-white text-sm dark:text-white`}
        >
          <div className="contents  " role="row">
            <div
              className="dark:bg-jacarta-600 bg-light-base sticky top-0 py-2 px-4 z-10 "
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Followers per $
              </span>
            </div>
            <div
              className="dark:bg-jacarta-600 bg-light-base sticky top-0 py-2 px-4 z-10"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Bid price
              </span>
            </div>
            <div
              className="dark:bg-jacarta-600 bg-light-base sticky top-0 py-2 px-4 z-10"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Offered campaigns
              </span>
            </div>


            

            <div
              className="dark:bg-jacarta-600 bg-light-base sticky top-0 py-2 px-4 z-10 text-center"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis ">
                User
              </span>
            </div>
            {actionsEnabled && (
              <div
                className="dark:bg-jacarta-600 bg-light-base sticky top-0 py-2 px-4 z-10 text-center"
                role="columnheader"
              >
                <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                  Actions
                </span>
              </div>
            )}
          </div>
        
          {bids?.map((item) => {
            const {
              followersPerUsd,
              id,
              bid,
              discount,
              username,
              user,
              imageURL,
              bidderUid,
              totalFollowers,
            } = item;
            return (
              <div className="contents" role="row" key={id}>
                <div
                  className="dark:border-jacarta-600 border-jacarta-100 flex items-center whitespace-nowrap border-t py-4 px-4"
                  role="cell"
                >
                  {/* <span className="-ml-1" data-tippy-content="ETH">
										<svg className="icon mr-1 h-4 w-4">
											<use xlinkHref="/icons.svg#icon-ETH"></use>
										</svg> */}
                  {/* </span> */}
                  <span className="text-green text-sm font-bold tracking-tight">
                    {followersPerUsd?.toFixed(0)}
                  </span>
                </div>
                <div
                  className="dark:border-jacarta-600 border-jacarta-100 flex items-center border-t py-4 px-4"
                  role="cell"
                >
                 <span>
                 {bid}$
                  <br></br>
                  <span
                    className={`font-bold text-sm ${
                      discount > 0 ? "text-green" : "text-red"
                    } `}
                  >
                    {discount}$ {discount > 0 ? "cheaper" : "more expensive"}
                  </span>
                 </span>
                </div>
                <div
                  className="dark:border-jacarta-600 border-jacarta-100 flex items-center border-t py-4 px-4"
                  role="cell"
                >
                 <button 
             
                 onClick={() => {setShow(true); setBid(item)}}
                 className="rounded-full bg-green py-3 px-8 text-center font-semibold text-white shadow-green-volume transition-all  hover:text-white ">
                    View offer
                 </button>
                </div>
                <div
                  className="dark:border-jacarta-600 border-jacarta-100 flex items-center border-t py-4 px-4 "
                  role="cell"
                >
                  <Link href={`/influencers/${bidderUid}`}>
                    <a className="relative block flex items-center flex-col justify-center w-full">
                      <img
                        src={`${imageURL || "/images/socialmedia1.jpg"} `}
                        alt="avatar"
                        className="rounded-2lg h-auto w-16 "
                        loading="lazy"
                      />
                      <span className="text-accent font-bold mt-2">
                        {username}
                      </span>
                      <div
                        className="dark:border-jacarta-600 
						 mt-2
						 group bg-accent border-jacarta-100 font-display   
						 items-center rounded-lg border p-1.5 text-sm font-semibold transition-colors border-transparent
						  text-white dark:border-transparent capitalize"
                      >
                        <CountUp
                          className=""
                          start={1}
                          duration={2}
                          end={parseInt(
                            totalFollowers / 1000 > 999
                              ? totalFollowers / 1000000
                              : totalFollowers / 1000
                          )}
                        >
                          {" "}
                        </CountUp>
                        {totalFollowers / 1000 > 999 ? "M" : "k"}+
                      </div>
                    </a>
                  </Link>
                </div>

                {actionsEnabled && (
                  <div
                    className="dark:border-jacarta-600 border-jacarta-100 flex items-center justify-center border-t py-4 px-4 text-center"
                    role="cell"
                  >
                    <div
                      style={{ display: actionsEnabled ? "flex" : "none" }}
                      className="flex flex-col h-full justify-around"
                    >
                      {/* <Link href={`/messages/${bidderUid}`}>
                        <a
                          className={`bg-accent ${
                            !actionsEnabled && "bg-accent-lighter"
                          } shadow-accent-volume hover:bg-accent-dark ${
                            !actionsEnabled &&
                            "hover:bg-accent-lighter cursor-default"
                          } 
													inline-block w-full md:min-w-[150px] rounded-full  py-3 px-4 text-center font-semibold text-white transition-all`}
                        >
                          <ShoppingCartIcon sx={{ mr: 1 }} />
                          {"Buy"}
                        </a>
                      </Link> */}
                      <Link className="" href={`/messages/${bidderUid}`}>
                        <a className=" cursor-pointer  py-3  px-4 rounded-full block  text-center alert-success ">
                          <SendIcon />
                          <b> Chat </b>{" "}
                        </a>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          
        </div>
        {
            bids?.length===0 || !bids &&
            <div className=" text-center w-full p-7 dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 font-display " role="row" > 
            No bids yet
            </div>
          }
      </div>
    </>
  );
};


const OfferModal = ({setShow, activeBid, currentUser, disabled}) => {
  return (
    <div>
      <div className={`${0===0 ? "modal fade show block" : "modal fade"} `}>
        <div
          className="modal-dialog sm:w-[92%] md:w-[70%]  md:h-[auto] sm:
				
				"
        >
          <div
            className="modal-content 
					
				
					"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
               Review offer
              </h5>
              <button
                type="button"
                className="btn-close"
               onClick={() => setShow(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div
              className="modal-body p-6 	
					
					sm:max-h-[60vh]
					offer-modal
					scrollbar-custom
					overflow-auto"
            >
            <OfferComponent
            currentUser={currentUser}
            activeBid={activeBid}
            influencer={currentUser ? currentUser?.influencer : true}
            user={activeBid.userObj}

             />
             
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer flex w-full flex-col" style={{display:'none'}}>
              <div className="text-center dark:border-jacarta-600 mb-2 border-jacarta-100 border-b border-b font-display text-jacarta-700 font-semibold dark:text-white  text-md">
                Client will be charged: &nbsp;
                <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border w-[auto]">
                  {/* <div className="border-jacarta-100 bg-jacarta-50 flex flex-1 items-center self-stretch border-r px-2">
									<span>
										<svg className="icon icon-ETH mr-1 h-5 w-5">
											<use xlinkHref="/icons.svg#icon-ETH"></use>
										</svg>
									</span>
									<span className="font-display text-jacarta-700 text-sm">ETH</span>
								</div> */}

                

                  <div className="bg-jacarta-50 border-jacarta-100 flex flex-1 justify-end self-stretch border-l dark:text-jacarta-700">
                    <span className="self-center px-2 text-sm">${50}</span>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center space-x-4">
                <button
                
                  type="button"
               
                  className={`
									${
                 "bg-accent"
                  } shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all
									`}
                >
                  Place Bid
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfferTab;
