import React, { useState, useEffect, useContext } from "react";
import { useRouter } from "next/router";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import Fab from "@mui/material/Fab";
import Zoom from "@mui/material/Zoom";

import More_items from "../../pages/item/more_items";
import Likes from "../../components/likes";
import Meta from "../../components/Meta";
import { countries } from "../../components/ui/CountrySelect";
import { useDispatch } from "react-redux";
import Link from "next/link";
import ChatIcon from "@mui/icons-material/Chat";
import { axiosInstance } from "../../utils/Axios";
import CountUp from "react-countup";
import Skeleton from "@mui/material/Skeleton";
export const gigKeys = ["instagram", "tiktok", "facebook", "twitch", "youtube"];
import { AuthContext } from "../../context/AuthContext";
import { ImageItem } from "../ui/FileUpload";
import { Grid, Stack, Typography } from "@mui/material";
import { HeadLine, ItemsTabs } from "../component";
import CopyToClipboard from "react-copy-to-clipboard";
import AlertMessage from "../ui/AlertMessage";
import { buyModalShow } from "../../redux/counterSlice";

export const getMetrics = (user) => {
  const arr = [];
  const socialPlatforms = new Set();
  let priceArr = [];
  Object.keys(user).map((key) => gigKeys.includes(key) && arr.push(user[key]));

  arr.map((val) => {
    val.items.map((sub) => {
      if (sub.checked) {
        socialPlatforms.add(val);
        priceArr.push(sub.price);
      }
    });
  });
  priceArr = priceArr.sort(function (a, b) {
    return a - b;
  });
  const returnVal = priceArr.length > 0 ? priceArr[0] : 29.99;

  return {
    lowestPrice: returnVal,
    socialPlatforms: Array.from(socialPlatforms).length,
  };
};

export const TAX_MULTIPLIER = 1.35

const ItemPage = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const id = router.query.influencer;

  const { currentUser } = useContext(AuthContext);

  const viewingYourself = currentUser?.uid === id;

  const [copied, setCopied] = useState(false);
  const [url, setUrl] = useState('');
	useEffect(() => {
		setTimeout(() => {
			setCopied(false);
		}, 2000);
	}, [copied]);

  useEffect(() => {
    setUrl(window.location.href)
   }, [])

  const [imageModal, setImageModal] = useState(false);
  const [user, setUser] = useState({
    images: [],
  });
  const [selected, setSelected] = useState([]);

  const [metrics, setMetrics] = useState({
    lowestPrice: 20,
    socialPlatforms: 3,
  });

  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    const getUser = async () => {
      const user = await axiosInstance.get(`/influencers/user/${id}`);
      setUser(user.data.data);
      setMetrics(getMetrics(user.data.data));
      setFetching(false);
    };
    if (id) {
      getUser();
    }
  }, [id]);

  user.shadow = user?.shadow;

  const { username, bio } = user?.userInfo || "";
  const { followers, images, audienceAgeFrom, audienceAgeTo } = user;
  const chatdisabled = user?.shadow || !currentUser || viewingYourself
  const igName =
    user?.instagram?.username.length > 1 ? "@" + user?.instagram?.username : "";
  const tikTokName =
    user?.tiktok?.username.length > 1 ? "@" + user?.tiktok?.username : "";
  const youtubeName =
    user?.youtube?.username.length > 1 ? "@" + user?.youtube?.username : "";


  
    
    let { success } = router.query
   
     success = success === 'true';

    const showBuyBanner =  viewingYourself && !user.paid && !fetching
    
  return (
    <>
      <Meta title={`${!fetching ? user?.userInfo?.username : ""} `} />
      {/*  <!-- Item --> */}
      <section className={`relative  lg:pt-24 lg:pb-24 mt-12  ${showBuyBanner ? 'md:mt-4' : 'md-mt-20'} pt-12 pb-24"`}>
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img
            src="/images/gradient_light.jpg"
            alt="gradient"
            className="h-full"
          />
        </picture>
        <div className="container">
        {
       !!showBuyBanner &&!success && 
        <div className="mb-3 md:mb-5">
        <AlertMessage variation={'purple-bright'} headline={'Activate your page'} text={'Your page is not activated, users cannot see it in search.'} >
 
         <button onClick={() => dispatch(buyModalShow())} className="rounded-full bg-white py-3 mb-3 px-8 text-center font-semibold zoom-sm
                     text-accent shadow-white-volume transition-all ">
                       Order
                     </button>
        </AlertMessage>
        </div>
      }
     
{
  router.query?.success &&
  <div className="mb-3 mt-3 md:mb-5">
    <AlertMessage
      variation={!!success ? 'green' : 'red'}
      headline={success  ? 'Successfully purchased!' : 'Oops, something went wrong.'}
      text={success  ? 'Thank you. We wish you glorious success in our platform!' : 'Try again'}
    />
  </div>
}
          <div className="md:flex md:flex-wrap" key={id}>
            {/* <!-- Image --> */}
            <figure className="mb-5 md:w-2/5 md:flex-shrink-0 md:flex-grow-0 md:basis-auto lg:w-1/2 w-full">
              <button className=" w-full" onClick={() => setImageModal(true)}>
                {fetching ? (
                  <Skeleton
                  sx={{ bgcolor: "grey.700", transform: 'initial', transformOrigin:'initial' }}
                    height={550}
                    width={"100%"}
                  >
                    <img
                      src={"/images/products/item_1.jpg"}
                      alt={"item_1"}
                      className="rounded-2xl cursor-pointer  w-full"
                    />
                  </Skeleton>
                ) : (
                  <img
                    src={images[0]?.imageURL || images[0]?.fileURL}
                    alt={"heroimg"}
                    className="rounded-2xl cursor-pointer max-h-[60vh] mx-auto  w-auto"
                  />
                )}
              </button>

              {images?.length > 0 && (
                <div className="flex flex-wrap flex-row justify-start gap-5 pb-8 dark:border-jacarta-600 border-jacarta-100 border-t pt-4 mt-4 justify-center md:justify-start">
                  {images?.map(
                    (file, index) =>
                      file.fileURL?.length > 1 && (
                        <ImageItem file={file} key={index} />
                      )
                  )}
                </div>
              )}

              {/* <!-- Modal --> */}
              <div
                className={imageModal ? "modal fade show block" : "modal fade"}
              >
                <div className="relative">
                  <div className="modal-dialog !my-0 flex h-auto w-auto max-w-[80vw] items-center justify-center relative">
                    <img
                      src={images[0]?.imageURL || images[0]?.fileURL}
                      alt={"hero image"}
                      className="h-auto w-auto max-h-[60vh] rounded-2xl"
                      style={{ animation: "fadeIn 1.2s" }}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn-close absolute top-[-25px] right-[-25px]"
                    onClick={() => setImageModal(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="h-6 w-6 fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                    </svg>
                  </button>
                </div>
              </div>
              {/* <!-- end modal --> */}
            </figure>

            {/* <!-- Details --> */}
            <div className="md:w-3/5 md:basis-auto md:pl-8 lg:w-1/2 lg:pl-[3.75rem]">
              {/* <!-- Collection / Likes / Actions --> */}
              <div className="mb-3 flex">
                {/* <!-- Collection --> */}
                <div className="flex items-center">
                  <Link href="#">
                    {fetching ? (
                      <Skeleton
                        sx={{ bgcolor: "grey.700", mr: 2 }}
                        variant="rounded"
                        width={"100%"}
                      >
                        <span className="text-accent mr-2 text-sm font-bold">
                          Influencer / Content creator
                        </span>
                      </Skeleton>
                    ) : (
                      <span className="text-accent mr-2 text-sm font-bold">
                        Influencer / Content creator
                      </span>
                    )}
                  </Link>
                  <span
                    className="dark:border-jacarta-600 bg-green inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-white"
                    data-tippy-content="Verified "
                  >
                    <Tippy content={<span>Verified </span>}>
                      <svg className="icon h-[.875rem] w-[.875rem] fill-white">
                        <use xlinkHref="/icons.svg#icon-right-sign"></use>
                      </svg>
                    </Tippy>
                  </span>
                </div>

                <div className="ml-auto flex items-stretch space-x-2 relative">
            <Tippy hideOnClick={false} style={{ background: 'transparent', backgroundColor: 'transparent', boxShadow: 'none' }} content={copied ? <span className="p-2 bg-jacarta-700 bg-opacity-50 rounded-lg">Copied!</span> : <span className="p-2 bg-jacarta-700 bg-opacity-50 rounded-lg" >Copy link</span>}>
				<button >
					<CopyToClipboard text={url} onCopy={() => setCopied(true)}>
          <div className="dark:border-jacarta-600 dark:hover:bg-jacarta-600 border-jacarta-100 dropdown hover:bg-jacarta-100
           dark:bg-jacarta-700 rounded-xl border bg-white">
            <div className="inline-flex h-10 w-10 items-center justify-center text-sm" aria-expanded="false">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="dark:fill-jacarta-200
               fill-jacarta-500 h-4 w-4"><path fill="none" d="M0 0h24v24H0z"></path><path d="M13.576 17.271l-5.11-2.787a3.5 3.5 0 1 1 0-4.968l5.11-2.787a3.5 3.5 0 1 1 .958 1.755l-5.11 2.787a3.514 3.514 0 0 1 0 1.458l5.11 2.787a3.5 3.5 0 1 1-.958 1.755z"></path></svg>
               </div></div>
					</CopyToClipboard>

				
				</button>
			</Tippy>
          
            </div>

                {/* <div className="ml-auto flex items-stretch space-x-2 relative">
												<Likes
													like={likes}
													classes="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 flex items-center space-x-1 rounded-xl border bg-white py-2 px-4"
												/>

											
												<Auctions_dropdown classes="dark:border-jacarta-600 dark:hover:bg-jacarta-600 border-jacarta-100 dropdown hover:bg-jacarta-100 dark:bg-jacarta-700 rounded-xl border bg-white" />
											</div> */}
              </div>

              {fetching ? (
                <Skeleton
                sx={{ bgcolor: "grey.700", transform: 'initial', transformOrigin:'initial' }}
                  variant="text"
                  width={"100%"}
                >
                  <h1 className="font-display text-jacarta-700 mb-4 text-4xl font-semibold dark:text-white">
                    {"username"}
                  </h1>
                </Skeleton>
              ) : (
                <h1 className="font-display text-jacarta-700 mb-2 text-4xl font-semibold dark:text-white">
                  {username}
                </h1>
              )}

              {!fetching && (igName.length > 2 || tikTokName?.length > 2) && (
                <div className="flex mb-2">
                  {igName && (
                    <a
                      target="_blank"
                      href={user?.instagram?.link || `https://www.instagram.com/${user?.instagram?.username}`}
                      className="text-accent mr-2 flex align-center justify-center gap-1  text-sm font-bold hover:text-accent-light zoom-sm  transition-all " 
                    >
                      			
                      		<svg
											aria-hidden="true"
											focusable="false"
											data-prefix="fab"
											data-icon="instagram"
											className="fill-accent dark:fill-accent-light pointer-events-none mt-[2px]  h-4 w-4 "
											role="img"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 448 512"
										>
											<path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
										</svg>
                      {igName?.length < 16 ? igName : "instagram"}
                    </a>
                  )}

                  {(user?.youtube?.link || youtubeName) && (
                    <a
                      target="_blank"
                      href={user?.youtube?.link || `https://www.youtube.com/user/${youtubeName}`}
                      className="text-accent mr-2 flex align-center justify-center gap-1  text-sm font-bold hover:text-accent-light zoom-sm transition-all"
                    >
                      {youtubeName?.length < 16 ? youtubeName : "YouTube"}
                    </a>
                  )}

                  {(user?.tiktok?.link || tikTokName) && (
                    <a
                      target="_blank"
                      href={user?.tiktok?.link || `https://www.tiktok.com/${tikTokName}`}
                      className="text-accent mr-2 flex align-center justify-center gap-1  text-sm font-bold hover:text-accent-light zoom-sm transition-all"
                    >
                      {tikTokName?.length < 16 ? tikTokName : "TikTok"}
                    </a>
                  )}
                </div>
              )}
              {fetching ? (
                <Skeleton
                sx={{ bgcolor: "grey.700", transform: 'initial', transformOrigin:'initial' }}
                  variant="text"
                  width={"100%"}
                >
                  <div className="mb-8 flex items-center space-x-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <Tippy content={<span>USD</span>}>
                        <span className="-ml-1">
                          <svg className="icon mr-1 h-4 w-4">
                            <use xlinkHref="/icons.svg#icon-USD"></use>
                          </svg>
                        </span>
                      </Tippy>
                    </div>
                    <span className="text-green text-sm font-bold tracking-tight">
                      {metrics.lowestPrice}$
                    </span>
                    <span className="dark:text-jacarta-300 text-jacarta-400 text-sm">
                      3 social media platforms
                    </span>
                  </div>
                </Skeleton>
              ) : (
                <>
                  <div className="mb-5 flex items-center space-x-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <Tippy content={<span>USD</span>}>
                        <span className="-ml-1">
                          <svg className="icon mr-1 h-4 w-4">
                            <use xlinkHref="/icons.svg#icon-ETH"></use>
                          </svg>
                        </span>
                      </Tippy>
                      <span className="text-green ml-0 text-sm font-bold tracking-tight">
                        { user?.shadow ? parseInt(user?.price*TAX_MULTIPLIER).toFixed(0) : parseInt(metrics.lowestPrice*1).toFixed(0)}$
                      </span>
                    </div>

                    {!user?.shadow && (
                      <span className="dark:text-jacarta-300 text-jacarta-400 text-sm">
                        {metrics.socialPlatforms} social media platforms
                      </span>
                    )}
                  </div>

                  <div className="flex flex-wrap items-center">
                    {" "}
                    {user?.categories?.map((cat, index) => {
                      return (
                        <div
                          key={index}
                          className="my-1 mr-2.5
										dark:border-jacarta-600 group bg-accent border-jacarta-100 font-display flex h-9 items-center rounded-lg border px-4 text-sm font-semibold transition-colors border-transparent text-white dark:border-transparent capitalize
										"
                        >
                          {cat}
                        </div>
                      );
                    })}{" "}
                  </div>
                </>
              )}

              {fetching ? (
                <Skeleton
                  sx={{ bgcolor: "grey.700", p: 2 }}
                  variant="text"
                  height={150}
                  width={"100%"}
                />
              ) : (
                <p className="dark:text-jacarta-200 pt-3 mb-10">{bio}</p>
              )}

              {/* <!-- Creator / Owner --> */}
              {fetching ? (
                <Skeleton
                sx={{ bgcolor: "grey.700", transform: 'initial', transformOrigin:'initial' }}
                  variant="text"
                  width={"100%"}
                >
                  <div className="mb-8 flex flex-wrap">
                    <div className="mr-8 mb-4 flex">
                      <figure className="mr-4 shrink-0">
                        {/* <Link href="/user/avatar_6"> */}
                        {/* TODO LINK TO COUNTRY */}
                        <a className="relative block">
                          <img
                            src={`https://flagcdn.com/w80/${getCountryCode(
                              user?.country
                            )}.png`}
                            alt={user?.country}
                            className="rounded-2lg h-12 w-auto"
                            loading="lazy"
                          />
                          <div
                            className="dark:border-jacarta-600 bg-green absolute -right-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white"
                            data-tippy-content="Verified "
                          >
                            <Tippy content={<span>Verified </span>}>
                              <svg className="icon h-[.875rem] w-[.875rem] fill-white">
                                <use xlinkHref="/icons.svg#icon-right-sign"></use>
                              </svg>
                            </Tippy>
                          </div>
                        </a>
                        {/* </Link> */}
                      </figure>
                      <div className="flex flex-col justify-center">
                        <span className="text-jacarta-400 block text-sm dark:text-white">
                          From <strong>{user?.country}</strong>
                        </span>

                        <a className="text-accent block">
                          <span className="text-sm font-bold">Confirmed</span>
                        </a>
                      </div>
                    </div>

                    <div className="mb-4 flex">
                      <figure className="mr-4 shrink-0">
                        <a className="relative block">
                          <img
                            src={images[0]?.imageURL || images[0]?.fileURL}
                            alt={"Influencer" + user?.userInfo?.username}
                            className="rounded-2lg h-12 w-auto"
                            loading="lazy"
                          />
                          <div
                            className="dark:border-jacarta-600 bg-green absolute -right-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white"
                            data-tippy-content="Verified "
                          >
                            <Tippy content={<span>Verified </span>}>
                              <svg className="icon h-[.875rem] w-[.875rem] fill-white">
                                <use xlinkHref="/icons.svg#icon-right-sign"></use>
                              </svg>
                            </Tippy>
                          </div>
                        </a>
                      </figure>
                      <div className="flex flex-col justify-center">
                        <span className="text-jacarta-400 block text-sm dark:text-white">
                          Social media
                        </span>

                        <a className="text-accent block">
                          <span className="text-sm font-bold">Verified</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </Skeleton>
              ) : (
                <div className="mb-8 flex flex-wrap">
                  <div className="mr-8 mb-4 flex">
                    <figure className="mr-4 shrink-0">
                      {/* <Link href="/user/avatar_6"> */}
                      {/* TODO LINK TO COUNTRY */}
                      <a className="relative block">
                        <img
                          src={`https://flagcdn.com/w80/${getCountryCode(
                            user?.country
                          )}.png`}
                          alt={user?.country}
                          className="rounded-2lg h-12 w-auto"
                          loading="lazy"
                        />
                        <div
                          className="dark:border-jacarta-600 bg-green absolute -right-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white"
                          data-tippy-content="Verified "
                        >
                          <Tippy content={<span>Verified </span>}>
                            <svg className="icon h-[.875rem] w-[.875rem] fill-white">
                              <use xlinkHref="/icons.svg#icon-right-sign"></use>
                            </svg>
                          </Tippy>
                        </div>
                      </a>
                      {/* </Link> */}
                    </figure>
                    <div className="flex flex-col justify-center">
                      <span className="text-jacarta-400 block text-sm dark:text-white">
                        From <strong>{user?.country}</strong>
                      </span>

                      <a className="text-accent block">
                        <span className="text-sm font-bold">Confirmed</span>
                      </a>
                    </div>
                  </div>

                  <div className="mb-4 flex">
                    <figure className="mr-4 shrink-0">
                      <a className="relative block">
                        <img
                          src={images[0]?.imageURL || images[0]?.fileURL}
                          alt={"Influencer" + user?.userInfo?.username}
                          className="rounded-2lg h-12 w-auto"
                          loading="lazy"
                        />
                        <div
                          className="dark:border-jacarta-600 bg-green absolute -right-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white"
                          data-tippy-content="Verified "
                        >
                          <Tippy content={<span>Verified </span>}>
                            <svg className="icon h-[.875rem] w-[.875rem] fill-white">
                              <use xlinkHref="/icons.svg#icon-right-sign"></use>
                            </svg>
                          </Tippy>
                        </div>
                      </a>
                    </figure>
                    <div className="flex flex-col justify-center">
                      <span className="text-jacarta-400 block text-sm dark:text-white">
                        Social media
                      </span>

                      <a className="text-accent block">
                        <span className="text-sm font-bold">Verified</span>
                      </a>
                    </div>
                  </div>
                </div>
              )}

              {fetching ? (
                <Skeleton
                sx={{ bgcolor: "grey.700", transform: 'initial', transformOrigin:'initial' }}
                  variant="rectangle"
                  height={200}
                  width={"100%"}
                />
              ) : (
                <div className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-2lg border bg-white p-8">
                  {!user.shadow && (
                    <div className="mb-8 sm:flex sm:flex-wrap">
                      {/* <!-- Highest bid --> */}
                      <div className="sm:w-1/2 sm:pr-4 lg:pr-8 flex items-center flex-col">
                        <div className="block ">
                          <span className="dark:text-jacarta-300 text-jacarta-400 text-sm">
                            Audience age:
                          </span>

                          <a className="text-accent text-sm font-bold">
                            &nbsp; {audienceAgeFrom} to {audienceAgeTo}
                          </a>
                        </div>
                        <div className="mt-3 flex">
                          <figure className="mr-4 shrink-0">
                            <Link href="#">
                              <a className="relative block">
                                <img
                                  src={`https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/International_Flag_of_Planet_Earth.svg/1280px-International_Flag_of_Planet_Earth.svg.png`}
                                  alt="country"
                                  className="rounded-2lg h-12 w-auto"
                                  loading="lazy"
                                />
                              </a>
                            </Link>
                          </figure>
                          <div>
                            <div className="flex items-center whitespace-nowrap">
                              <Tippy content={<span></span>}>
                                <span className="-ml-1">
                                  <svg className="icon mr-1 h-4 w-4">
                                    {/* <use xlinkHref="/icons.svg#icon-ETH"></use> */}
                                  </svg>
                                </span>
                              </Tippy>
                              <span className="text-green text-lg font-medium leading-tight tracking-tight text-center">
                                ~
                                <CountUp
                                  className=""
                                  start={1}
                                  duration={1}
                                  end={parseFloat(followers / 7 / 1000)}
                                >
                                  {" "}
                                </CountUp>
                                {"k"}+
                              </span>
                            </div>
                            <span className="dark:text-jacarta-300 text-jacarta-400 text-sm whitespace-nowrap">
                              Clicks per campaign
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* <!-- Countdown --> */}
                      <div className="dark:border-jacarta-600 sm:border-jacarta-100 mt-4 sm:mt-0 sm:w-1/2 sm:border-l sm:pl-4 lg:pl-8 flex flex-col items-center justify-center">
                        <span className="js-countdown-ends-label text-jacarta-400 dark:text-jacarta-300 text-sm">
                          Total followers
                        </span>
                        <div
                          className="dark:border-jacarta-600 
						 mt-2
						 group bg-accent border-jacarta-100 font-display   
						 items-center rounded-lg border px-4 py-2 text-lg font-semibold transition-colors border-transparent
						  text-white dark:border-transparent capitalize"
                        >
                          <CountUp
                            className=""
                            start={1}
                            duration={2}
                            end={parseInt(
                              followers / 1000 > 999
                                ? followers / 1000000
                                : followers / 1000
                            )}
                          >
                            {" "}
                          </CountUp>
                          {followers / 1000 > 999 ? "M" : "k"}+
                        </div>
                      </div>
                    </div>
                  )}

                  <Link
                    disabled={user?.shadow}
                    href={chatdisabled ? "#" : `/messages/${user?.uid}`}
                  >
                    <a
                      className={` ${
                        chatdisabled
                          ? "cursor-not-allowed"
                          : "bg-accent hover:bg-accent-dark "
                      } shadow-accent-volume 
                        inline-block w-full rounded-full py-3 px-8 text-center font-semibold text-white transition-all`}
                    >
                      <ChatIcon sx={{ mr: 1 }} />
                      {chatdisabled
                        ? user?.shadow
                          ? "Chat unavailable"
                          : currentUser ? 'Chat now' : 'Login to chat'
                        : "Chat now"}
                    </a>
                  </Link>
                </div>
              )}
            </div>
            {/* <!-- end details --> */}
          </div>

          {/* <ItemsTabs /> */}
       
        </div>
        <div className="container max-w-auto md:max-w-[80%] mx-auto">
     {
      !fetching && 
      <ItemsTabs
      uid={user?.uid || router.query.influencer}
     type='service'
     canEdit={viewingYourself}
     images={user?.images}
     customServices={user?.customServices}
     shadow={user?.shadow}
     services={user?.work}
     schedule={user?.schedule ? user.schedule : null}
     actionsEnabled={router.query.influencer === currentUser?.uid}
     moreItems={ <More_items
       renderCategories
         user={user}
         key={viewingYourself || "0"}
         fetching={fetching}
         parentStyles={' dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border'}
         gridSx={{mx:'0px !important', px:2}}
         disabled={viewingYourself}
         selectedHook={setSelected}
       />}
     specialOffers={  <SpecialOffers
         showBuyBanner={showBuyBanner}
           user={user}
           images={user?.images}
           customServices={user?.customServices}
         />}
   />
     }
        </div>
      </section>
      {/* <!-- end item --> */}
      

      {/* {user?.shadow ? (
        <SpecialOffers
        showBuyBanner={showBuyBanner}
          user={user}
          images={user?.images}
          customServices={user?.customServices}
        />
      ) : (
        <More_items
        renderCategories
          user={user}
          key={viewingYourself || "0"}
          fetching={fetching}
          disabled={viewingYourself}
          selectedHook={setSelected}
        />
      )} */}
    </>
  );
};

export const SpecialOffers = ({
  customServices,
  showBuyBanner,
  images,
  user,
  disabled,
  fabHidden,
  title,
  itemMinWidth,
  subtitle,
  checked,
  noBG,hideTitles
}) => {
  const [products, setProducts] = useState([]);

  customServices?.map(
    (service, key) =>
      (service.img = service.hasOwnProperty("img")
        ? service?.img
        : (key < images?.length && images[key]?.fileURL) || images[0]?.fileURL)
  );

  if(!customServices || customServices?.length<1) {
    return (
      <section className={    `${noBG ? 'bg-transparent' :  'dark:bg-jacarta-800'}  bg-light-base py-0 `}>
      <div className='mb-[20px]'> <AlertMessage headline={'No custom deals'} text={'Influencer has not set his special deals'}/> </div>
      </section>
    )
  }

  return (
    <section className={    `${noBG ? 'bg-transparent' :  'dark:bg-jacarta-800'}  bg-light-base py-12 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border`}>
      {!fabHidden && (
        <Link
          className="disabled-link"
          href={
            products.length > 0
              ? {
                  pathname: `/orders/newOrder/${user?.uid}`,
                  query: {
                    productsSelected: JSON.stringify(
                      products.map(
                        (custom) =>
                          delete customServices[custom].price &&
                          customServices[custom]
                      )
                    ),
                  },
                }
              : "#"
          }
        >
          <Zoom
            key={1}
            in={true}
            timeout={1000}
            style={{
              transitionDelay: `500ms`,
            }}
            unmountOnExit
          >
            <Fab
              sx={{
                bgcolor:
                  products.length > 0
                    ? "#5863ff !important"
                    : "gray !important",
                border: "none",
                borderRadius: 20,
                bottom: 20,
                boxSizing: "border-box",
                color: "white",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 500,
                height: 50,
                letterSpacing: 0.25,
                lineHeight: 1,
                margin: 0,
                minWidth: 50,
                padding: 1,

                position: "fixed",
                right: {xs:15, md:50},
                transition: "all 0.5s ease-out;",
                width: "auto",
                WebkitAppearance: "none",
                "&:hover": {
                  bgcolor: "#4447ff !important",
                  transition: "all 0.5s ease-out;",
                },
              }}
              color="primary"
              className="transition-all"
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ px: 2 }}
              >
                <RocketLaunchIcon />
                {products.length > 0 && (
                  <Typography
                    sx={{ m: 2 }}
                    style={{ animation: "fadeIn 1s" }}
                    component="div"
                  >
                    Purchase {<b>{products.length}</b>} services 😍
                  </Typography>
                )}
              </Stack>
            </Fab>
          </Zoom>
        </Link>
      )}
      {
        !hideTitles &&
        <>
        <HeadLine
        image="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/1f525.png"
        classes="font-display text-jacarta-700  text-center text-3xl dark:text-white container"
        text={title ? title : "Special bundles"}
      ></HeadLine>
      <h2 className="dark:text-jacarta-200  text-lg leading-normal2 text-center mb-5 container">
        {subtitle ? (
          subtitle
        ) : (
          <>
            <span className="text-accent">
              <b>Deals</b>{" "}
            </span>{" "}
            of a lifetime.
          </>
        )}
      </h2></>
      }

      <div className="container mx-auto no-shadow">
        <Grid
          spacing={3}
          container
          className={` mx-auto ${noBG ? 'bg-transparent' : 'dark:bg-jacarta-800'} rounded-t-2lg rounded-b-2lg rounded-tl-none  bg-white 	
		md:max-w-auto p-6 gap-4 md:p-5`}
          sx={{ px: 0, mx: "0px !important", p: {xs: '0 !important'}, width: {xs:'100%'} }}
        >
          {customServices?.map((service, key) => {
          
            if (service?.title?.length < 2 || service.hasOwnProperty('orderitem')) {
              const match = user.customServices.find((x) => x.product === service.type)
              
                if(!match){
                  return null;
                }
                else{
                  service = {...match, img: service.img}
                  
                }
              }
            

            return (
              <Grid
                key={key}
                item
                xs={12}
                sm={key === 0 ? 12 : true}
                md={key === 0 ? 12 : true}
                sx={{ pl: "0 !important" }}
              >
                <div
                  className={` 
					  
            ${
              products.includes(key) || checked
                ? "dark:bg-jacarta-1000 border borders border-accent"
                : "dark:bg-jacarta-900 border borders border-transparent  zoom-sm"
            }
										 dark:border-jacarta-700s
										 border-jacarta-100s 
										 borders
                     border-2
										 shadow-lg
                     ${itemMinWidth ? 'min-w-[20vw]' : 'min-w-[28vw]'}
                    
										 hover:shadow-[0_16px_24px_-8px_rgba(131,88,255,.05),0_-6px_2px_-8px_rgba(131,88,255,.05)]
										 hover:shadow-[0_16px_24px_-8px_rgba(131,88,255,.1),0_-6px_2px_-8px_rgba(131,88,255,.1)]
										 h-full
									   cursor-pointer
                     transition-all
                     max-h-[700px]
                     md:max-h-[350px]
										 rounded-2.5xl relative flex items-center  bg-white  transition-shadow flex flex-col md:flex-row gap-3 py-0   `}
                  style={{ animation: "fadeIn .3s", transition: "all .3s" }}
                  onClick={() =>
                    !disabled &&
                    setProducts(
                      products.includes(key)
                        ? products.filter((p) => p !== key)
                        : [...products, key]
                    )
                  }
                >
                  <img
                    src={service.img}
                    alt={key + "img"}
                    objectfit="cover"
                    className={`rounded-2lg rounded-[20px] overflow-hidden w-full h-full min-w-[150px] my-auto object-cover border-accent  border-b-8 md:border-b-0  border-r-0 md:border-r-8 `}
                    loading="lazy"
                    style={{ animation: "fadeIn 1s" }}
                  />

                  <div className="flex flex-col self-stretch    justify-start w-full px-5 my-5">
                    <div style={{ display: "inline-flex" }}>
                      <div
                        className="my-2 ml-[-5px] flex
                      shadow-accent-volume
                      text-center dark:border-jacarta-600 group bg-accent  font-display  h-9 items-center rounded-lg  px-4 text-sm font-semibold trans"
                      >
                        {service?.social}
                      </div>
                    </div>
                    <h3 className="font-display text-lg text-jacarta-700 mt-2 mb-0  font-semibold dark:text-white">
                      {service?.product}
                    </h3>
                    <div></div>
                    <div className="">
                      <span className="dark:text-jacarta-200 text-jacarta-500 mb-3 block text-sm  mr-1">
                        <span
                        
                          className="text-green text-sm font-bold tracking-tight "
                        >
                          {parseInt(service?.pricePostTax).toFixed(0)}$
                        </span>
                      </span>

                      <span className="dark:text-jacarta-200 text-jacarta-500 mb-3 mt-2 block text-sm  mr-1">
                        <span className=" text-md  tracking-tight productText ">
                          {service?.detail}
                        </span>
                      </span>
                    </div>
                  </div>

                  <div className="dark:border-jacarta-600 border-jacarta-100 ml-auto rounded-full border p-3 m-3">
                    <div>
                      <svg className="icon fill-jacarta-700 dark:fill-white h-6 w-6">
                        <use xlinkHref={`/icons.svg#icon-purchases`}></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </section>
  );
};

export const getCountryCode = (country) => {
  return (
    countries.find((x) => x.label === country)?.code?.toLowerCase() || "us"
  );
};

export default ItemPage;
