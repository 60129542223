import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { styled, lighten, darken } from '@mui/system';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { createTheme, ThemeProvider, InputAdornment, Box, IconButton, Popper, Chip  } from '@mui/material';

import { useRouter } from 'next/router';
import { suggestionsGlobal } from '../../data/help_center_data';
const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : '#10071f',
}));

const GroupItems = styled('ul')({
  padding: 0,
});


const styles = {
    dark : {
        palette: {
            mode: 'dark',
            primary: {
                main: '#f0e7e6',
            },
            secondary: {
              main: '#5f1939'
            },
            textColors: {
              link: '#ecf5e1'
            },
            backgroundColors: {
                purple: '#974dff'
              },
            hover : {
            active: 'rgba(244, 237, 240, 0.1)',
            tooltipExpanded: '#2e354f'
            },
            icons: {
                blue: '#5989f7',
                file : '#cafae9',
            },
            backgroundGradients: {
                red: 'linear-gradient(305deg, rgba(164,10,34,1) 0%, rgba(255,135,135,1) 100%)'
            }
      
        },
        components: {
            
            MuiAppBar: {
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: 'rgb(0, 53, 77)',
                        backgroundImage: 'none',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                      
                        transition: 'all .7s',
                        '&:hover' : {
                           
                            transition: 'all .7s',
                        }
                    },
                },
            },
            MuiPaper : {
                styleOverrides : {
                    root : {
                       backgroundColor: '#161221',
                       borderRadius:5,
                       backgroundImage: 'none',
                       //boxShadow: 'rgb(46 148 240 / 40%) 0px 5px, rgb(46 180 240 / 30%) 0px 10px, rgb(46 229 240 / 20%) 0px 15px, rgb(46 196 240 / 10%) 0px 20px'
                       boxShadow: 'rgb(176 174 190 / 2%) 0px 10px 20px 0px'
                    }
                }
            }
        },
    },
    light: {
       
            palette: {
                mode: 'light',
                primary: {
                    main: '#7cc5e9',
                },
                secondary: {
                  main: '#5f1939'
                },
                textColors: {
                  link: '#ecf5e1'
                },
                backgroundColors: {
                  purple: '#974dff'
                },
                hover : {
                    active: 'rgba(244, 237, 240, 0.1)',
                    tooltipExpanded: '#ebf2f5'
                    },
                icons: {
                    blue: '#4c80f8',
                    file : '#cafae9',
                },
                background: {
                    default: "#ebe9e6",
                  },
            },
            components: {
                MuiAppBar: {
                    styleOverrides: {
                        colorPrimary: {
                            backgroundColor: 'rgb(0, 53, 77)',
                            backgroundImage: 'none',
                        },
                    },
                },
                MuiPaper : {
                    styleOverrides : {
                        root : {
                           backgroundColor: '#ebf2f5',
                           backgroundImage: 'none',
                           
                        }
                    }
                },
                MuiButton : {
                    styleOverrides : {
                        root : {
                         
                           boxShadow: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;'
                        }
                    }
                }
            },
        
    }    
}

const filter = createFilterOptions()
const influencerSearchKeywords = [
  { id: "1", suggestion: "fashion" },
  { id: "2", suggestion: "travel" },
  { id: "3", suggestion: "fitness" },
  { id: "4", suggestion: "food" },
  { id: "5", suggestion: "beauty" },
  { id: "6", suggestion: "lifestyle" },
  { id: "7", suggestion: "art" },
  { id: "8", suggestion: "music" },
  { id: "9", suggestion: "photography" },
  { id: "10", suggestion: "health" },
  { id: "11", suggestion: "diy" },
  { id: "12", suggestion: "tech" },
  { id: "13", suggestion: "cooking" },
  { id: "14", suggestion: "parenting" },
  { id: "15", suggestion: "gaming" },
  { id: "16", suggestion: "books" },
  { id: "17", suggestion: "design" },
  { id: "18", suggestion: "sports" },
  { id: "19", suggestion: "business" },
  { id: "20", suggestion: "finance" },
  { id: "21", suggestion: "motivation" },
  { id: "22", suggestion: "inspiration" },
  { id: "23", suggestion: "home" },
  { id: "24", suggestion: "gardening" },
  { id: "25", suggestion: "pets" },
  { id: "26", suggestion: "humor" },
  { id: "27", suggestion: "comedy" },
  { id: "28", suggestion: "education" },
  { id: "29", suggestion: "science" },
  { id: "30", suggestion: "nature" },
  { id: "31", suggestion: "outdoors" },
  { id: "32", suggestion: "adventure" },
  { id: "33", suggestion: "cars" },
  { id: "34", suggestion: "architecture" },
  { id: "35", suggestion: "vintage" },
  { id: "36", suggestion: "history" },
  { id: "37", suggestion: "style" },
  { id: "38", suggestion: "makeup" },
  { id: "39", suggestion: "skincare" },
  { id: "40", suggestion: "hair" },
  { id: "41", suggestion: "wellness" },
  { id: "42", suggestion: "yoga" },
  { id: "43", suggestion: "meditation" },
  { id: "44", suggestion: "vegan" },
  { id: "45", suggestion: "plantbased" },
  { id: "46", suggestion: "sustainability" },
  { id: "47", suggestion: "environment" },
  { id: "48", suggestion: "travelgram" },
  { id: "49", suggestion: "wanderlust" },
  { id: "50", suggestion: "fitfam" },
  { id: "51", suggestion: "fitlife" },
  { id: "52", suggestion: "foodie" },
  { id: "53", suggestion: "foodstagram" },
  { id: "54", suggestion: "selfcare" },
  { id: "55", suggestion: "mindfulness" },
  { id: "56", suggestion: "positivity" },
  { id: "57", suggestion: "parentingtips" },
  { id: "58", suggestion: "gamingcommunity" },
  { id: "59", suggestion: "bookstagram" },
  { id: "60", suggestion: "creativity" },
  { id: "61", suggestion: "startup" },
  { id: "62", suggestion: "entrepreneur" },
  { id: "63", suggestion: "financialfreedom" },
  { id: "64", suggestion: "motivationalquotes" },
  { id: "65", suggestion: "interiordesign" },
  { id: "66", suggestion: "homedecor" },
  { id: "67", suggestion: "gardeninspiration" },
  { id: "68", suggestion: "petlovers" },
  { id: "69", suggestion: "animallovers" },
  { id: "70", suggestion: "funnymemes" },
  { id: "71", suggestion: "standupcomedy" },
  { id: "72", suggestion: "learning" },
  { id: "73", suggestion: "knowledge" },
  { id: "74", suggestion: "wildlife" },
  { id: "75", suggestion: "camping" },
  { id: "76", suggestion: "hiking" },
  { id: "77", suggestion: "survival" },
  { id: "78", suggestion: "automotive" },
  { id: "79", suggestion: "luxury" },
  { id: "80", suggestion: "fashionista" },
  { id: "81", suggestion: "influencerlife" },
  { id: "82", suggestion: "vintagestyle" },
  { id: "83", suggestion: "retro" },
  { id: "84", suggestion: "throwback" },
  { id: "85", suggestion: "artlover" },
  { id: "86", suggestion: "musician" },
  { id: "87", suggestion: "concerts" },
  { id: "88", suggestion: "streetphotography" },
  { id: "89", suggestion: "portraitphotography" },
  { id: "90", suggestion: "landscapephotography" },
  { id: "91", suggestion: "fitnessjourney" },
  { id: "92", suggestion: "workoutmotivation" },
  { id: "93", suggestion: "cleaneating" },
  { id: "94", suggestion: "nutrition" },
  { id: "95", suggestion: "weightloss" },
  { id: "96", suggestion: "yogalife" },
  { id: "97", suggestion: "mindset" },
  { id: "98", suggestion: "wellbeing" },
  { id: "99", suggestion: "plantlover" },
  { id: "100", suggestion: "ecofriendly" },
  { id: "101", suggestion: "greenliving" },
  { id: "102", suggestion: "wanderer" },
  { id: "103", suggestion: "explorer" },
  { id: "104", suggestion: "traveladdict" },
  { id: "105", suggestion: "instatravel" },
  { id: "106", suggestion: "fitspo" },
  { id: "107", suggestion: "foodgasm" },
  { id: "108", suggestion: "foodporn" },
  { id: "109", suggestion: "selflove" },
  { id: "110", suggestion: "affirmations" },
  { id: "111", suggestion: "positivevibes" },
  { id: "112", suggestion: "momlife" },
  { id: "113", suggestion: "dadlife" },
  { id: "114", suggestion: "gamergirl" },
  { id: "115", suggestion: "gamersunite" },
  { id: "116", suggestion: "booklover" },
  { id: "117", suggestion: "creativeminds" },
  { id: "118", suggestion: "startuptips" },
  { id: "119", suggestion: "businessmind" },
  { id: "120", suggestion: "financialindependence" },
  { id: "121", suggestion: "motivationalspeaker" },
  { id: "122", suggestion: "interiorinspiration" },
  { id: "123", suggestion: "homedesign" },
  { id: "124", suggestion: "petsagram" },
  { id: "125", suggestion: "animalsofinstagram" },
  { id: "126", suggestion: "memesdaily" },
  { id: "127", suggestion: "laughtertherapy" },
  { id: "128", suggestion: "educationmatters" },
  { id: "129", suggestion: "naturephotography" },
  { id: "130", suggestion: "travelholic" },
  { id: "131", suggestion: "fitnessgoals" },
  { id: "132", suggestion: "healthyliving" },
  { id: "133", suggestion: "wellnessjourney" },
  { id: "134", suggestion: "meditate" },
  { id: "135", suggestion: "veganlifestyle" },
  { id: "136", suggestion: "sustainableliving" },
  { id: "137", suggestion: "travelbug" },
  { id: "138", suggestion: "fashionstyle" },
  { id: "139", suggestion: "beautyaddict" },
  { id: "140", suggestion: "luxurylifestyle" },
  { id: "141", suggestion: "inspire" },
  { id: "142", suggestion: "fashionblogger" },
  { id: "143", suggestion: "traveler" },
  { id: "144", suggestion: "fitnessmotivation" },
  { id: "145", suggestion: "foodlover" },
  { id: "146", suggestion: "makeuplover" },
  { id: "147", suggestion: "entrepreneurlife" },
  { id: "148", suggestion: "workfromhome" },
  { id: "149", suggestion: "mindfulnessmeditation" },
  { id: "150", suggestion: "veganfood" },
  { id: "151", suggestion: "instafit" },
  { id: "152", suggestion: "artistic" },
  { id: "153", suggestion: "musiclover" },
  { id: "154", suggestion: "yogainspiration" },
  { id: "155", suggestion: "motivated" },
  { id: "156", suggestion: "positivemindset" },
  { id: "157", suggestion: "homedesignideas" },
  { id: "158", suggestion: "catlover" },
  { id: "159", suggestion: "doglover" },
  { id: "160", suggestion: "animalphotography" },
  { id: "161", suggestion: "creativememes" },
  { id: "162", suggestion: "comedyclub" },
  { id: "163", suggestion: "educational" },
  { id: "164", suggestion: "discover" },
  { id: "165", suggestion: "wildlifephotography" },
  { id: "166", suggestion: "globetrotter" },
  { id: "168", suggestion: "nutritiontips" },
  { id: "169", suggestion: "mentalhealth" },
  { id: "170", suggestion: "ecofriendlyliving" },
  { id: "171", suggestion: "naturelover" },
  { id: "172", suggestion: "fashioninspo" },
  { id: "173", suggestion: "makeupjunkie" },
  { id: "174", suggestion: "luxurytravel" },
  { id: "175", suggestion: "inspirationalquotes" },
  { id: "176", suggestion: "foodblog" },
  { id: "177", suggestion: "wellnessblogger" },
  { id: "178", suggestion: "fashiongram" },
  { id: "179", suggestion: "fitnessinspiration" },
  { id: "180", suggestion: "selflovejourney" }
];

    
    const options = [ ...influencerSearchKeywords].map((option) => {
      const firstLetter = option.suggestion.charAt(0).toUpperCase();
      const restOfWord = option.suggestion.slice(1);
      const capitalizedSuggestion = firstLetter + restOfWord;

      return {
        //firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...option,
        title: capitalizedSuggestion
       
   
      };
    });

    const PopperMy = function (props) {
      return (<Popper {...props} style={{ zIndex:99999 }}  />)
    }
export default function GroupedAutocomplete({variant,id, label, startAdornmentIcon, onSelect, selectedValue, freeSolo, classes}) {
  const theme = React.useMemo(
    () =>
        createTheme({
           ...styles['dark']
         
        }),
    [],
  );



  const router = useRouter()




const {t} =useTranslation()

const [value, setValue] = React.useState(null);
const useStyles = makeStyles({
  horizontalDropdown: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '18px',
    paddingLeft:'15px',
    paddingRight:'15px',
    maxHeight: '200px',
    overflowY: 'auto',
  },

  scrollbarCustom: {
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      height: '0.5rem',
      overflow: 'overlay',
      width: '0.5rem',
    },
    '&::-webkit-scrollbar-track': {
      '--tw-bg-opacity': 1,
      backgroundColor: '#140E1D',
      '@media (prefers-color-scheme: dark)': {
        backgroundColor: '#26212F',
      },
    },
    '&::-webkit-scrollbar-thumb': {
      '--tw-bg-opacity': 1,
      backgroundColor: 'rgba(161, 162, 179, var(--tw-bg-opacity))',
      borderRadius: '0.5rem',
    },
    '&::-webkit-scrollbar-thumb.dark': {
      '--tw-bg-opacity': 1,
      backgroundColor: 'rgba(54, 58, 93, var(--tw-bg-opacity))',
    },
  },
  chipOption : {
    background: '#1f1340'
  },
  option: {
    backgroundColor: 'inherit !important',
  },
});



// Inside your component
const classesMUI = useStyles();
  
  return (
    <ThemeProvider theme={theme}>
    <Autocomplete
     style={{animation: 'fadeIn 1s'}}
    className={`dark:bg-transparent border-jacarta-100  autocompleteDiv
    dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 

   dark:text-white px-3 ${classes} ${classesMUI}`}
    sx={{ width: '100%',
    '& .MuiAutocomplete-popper' : {
      zIndex:9999999
    },
    '&.MuiAutocomplete-root' : {p:0}, borderRadius:20, '&.MuiFilledInput-root ' : {background:'transparent !important', borderRadius:'20px !important'}
   , '& .MuiInputBase-root' : {
      borderRadius:'20px !important'
   }
  }}
    freeSolo={true}
      id={id}
   
   classes={{ listbox: `${classesMUI.scrollbarCustom} ${classesMUI.horizontalDropdown}`,  option: classesMUI.option, }}
      options={options.slice(0,180)}
     
      PopperComponent={(props) => <Popper {...props}  style={{...props.style, zIndex:9999999}} /> }
      value={typeof selectedValue !== 'undefined' ? selectedValue :  value }
      onChange={(event, newValue) => {
        if(!newValue){
          newValue = {title: ''}
        }
        if (typeof newValue === 'string') {
          setValue({
            title: newValue.replace(/"/g, '')
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            title: newValue.inputValue.replace(/"/g, '')
          });
        } else {
          setValue(newValue);
        }
  
        newValue.title=newValue.title?.replace('Search ','').trim().replace(/"/g, '')
       
        onSelect(newValue)
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.title);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `Search "${inputValue}"`,
          });
        }

        return filtered;
      }}

      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      
      renderOption={(props, option) => {
      
        const renderFull = typeof option === 'object' ? option?.title?.includes('Search') : false;
        return (
          <Chip   {...props} label={<span className=' '> 
        {option.title}
      </span>} 
      className={` ${renderFull ? 'w-full' :'w-auto'} bg-jacarta-600  shadow-jacarta-700-volume   cursor-pointer hover:bg-accent  transition-all !important`} 
      sx={{ fontSize: '14px', backgroundColor: '#40365d',     transition: 'all .3s',
      '&:hover': {
        backgroundColor: '#7c89ff', // Hovered state background color
        cursor: 'pointer',
        transition: 'all .3s'
      } }}
      ></Chip>
        )
      } }
      autoHighlight
      
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys

      renderInput={(params) => <TextField {...params}  InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        startAdornment: (
        
           <Box sx={{mt:1, mb:1, ml:-1}}> 
 <InputAdornment sx={{mb:2}} position="start">
      <IconButton>  {startAdornmentIcon ? startAdornmentIcon : <LightbulbIcon/>}</IconButton>
          </InputAdornment>

           </Box>
      
        ),
      }}

   
       variant={variant ? variant : 'filled'} color="primary" 
     
       label={t(label? label : 'autocompleteLabel')} sx={{width:'100%', '&.MuiTextField-root' : {}}} />}
      // renderGroup={(params) => (
      //   <li>
      //     <GroupHeader>{params.group}</GroupHeader>
      //     <GroupItems>{params.children}</GroupItems>
      //   </li>
      // )}
      
    />
    </ThemeProvider>
  );
}


