import React, { useEffect, useState } from "react";
import Activity_item from "../../components/collections/Activity_item";
import Head from "next/head";
import Meta from "../../components/Meta";
import Collection_category_filter from "../../components/collections/collection_category_filter";
import { axiosInstance } from "../../utils/Axios";
import { Grid, Stack, Box, Divider, Skeleton } from "@mui/material";
import Tippy from "@tippyjs/react";
import Link from "next/link";
import { countries } from "../../components/ui/CountrySelect";
import Pagination from "@mui/material/Pagination";
import { useTranslation } from "react-i18next";
import { getMetrics } from "../../components/carousel/bidsCarousel";
import Image from "next/image";
import { TAX_MULTIPLIER } from "../../components/item/Item";
import { useRouter } from "next/router";
const fetchInfluencers = async (searchParams) => {
  const res = await axiosInstance.get("/influencers", {
    params: { ...searchParams },
  });
  if (res.data) {
    return res.data;
  } else {
    return [];
  }
};
const influencersPerPage = 16;
const initialState = {
  countries: "[]",
  categories: "[]",
  search: null,
  priceFrom: null,
  priceTo: null,
  followersFrom: null,
  socialMediaPlatforms: "[]",
};
const index = () => {
  const [influencers, setInfluencers] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState({
    ...initialState,
  });

  const [prevParams, setPrevParams] = useState({
    ...initialState,
  });
  const [emptyData, setEmpty] = useState(false);
  const [targetArr, setTargetArr] = useState([])
  const [counter, setCounter] = useState(0);
  const disabled =
    JSON.stringify(searchParams) === JSON.stringify(prevParams) 

    const router = useRouter();

    const { query } = router;

    useEffect(() => {
      const handleQueryChange = (newQuery) => {
        const search =newQuery.search;
        setSearchParams({
          ...searchParams,
          search,
        });
        setCounter(counter + 1);
      }
    
     
  
      handleQueryChange(query);
  
      const handleRouteChange = (url) => {
        const { query: newQuery } = router;
        handleQueryChange(newQuery);
      };
  
      router.events.on('routeChangeComplete', handleRouteChange);
  
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }, [query]);
    
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("search")) {
      const search = query.get("search");
      setSearchParams({
        ...searchParams,
        search,
      });
    }
    setCounter(counter + 1);
  }, []);

  useEffect(() => {
    setTargetArr( influencers
      .slice(
        (page - 1) * influencersPerPage,
        page === 1
          ? influencersPerPage
          : (page - 1) * influencersPerPage + influencersPerPage))
  }, [influencers])

  useEffect(() => {
    const getUsers = async () => {
      setInfluencers([]);
      setPrevParams(searchParams);
      const { influencers } = await fetchInfluencers(searchParams);
      setInfluencers(influencers);
      setEmpty(influencers.length < 1);
      setFetching(false);
      //setInfluencers([...influencers, ...influencers,...influencers,...influencers]);
    };

    if (counter !== 0) {
      if (
        influencers.length > 0 &&
        JSON.stringify(searchParams) !== JSON.stringify(prevParams)
      ) {
        if (fetching) {
          return;
        }
        setFetching(true);
        getUsers();
      } else if (influencers.length === 0 && !fetching) {
        setFetching(true);
        getUsers();
      }
    }
  }, [counter]);



  const resetSearch = () => {
    setSearchParams({
      ...initialState,
    });
    setCounter(counter + 1);
  };
  useEffect(() => {
      
    window.scrollTo(0,0)

    setTargetArr( influencers
      .slice(
        (page - 1) * influencersPerPage,
        page === 1
          ? influencersPerPage
          : (page - 1) * influencersPerPage + influencersPerPage))
   
  }, [page])
  const { t } = useTranslation();

  return (
    <>
      <Meta title="Influencers | Hire an influencer" />
      <section className="relative mt-24 lg:pb-24 pb-24 min-h-[80vh]">
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img
            src="/images/gradient_light.jpg"
            alt="gradient"
            className="h-full w-full"
          />
        </picture>
        <div className="container-xl mx-auto">
      
          <Collection_category_filter
             key={emptyData ? "static_0" : "static_0"}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            counter={counter}
            setCounter={setCounter}
            disabled={disabled}
          />
      {emptyData && !fetching && (
              <Stack sx={{  alignItems: "center", m:5 }}>
                <h3 className="dark:text-jacarta-200 mb-3 text-lg leading-normal text-center">
                  {" "}
                  {t("items.notFound")}
                </h3>
                <button
                  onClick={() => resetSearch()}
                  className="bg-accent-light shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                >
                  {t("items.resetSearch")}
                </button>
              </Stack>
            )}
          <Grid container spacing={2} disableGutters  >
            {fetching &&
              ["", "", "", ""].map((v, key) => (
                <Grid item xs={12} md={3} key={key}>
                  {" "}
                  <Skeleton
                    sx={{ bgcolor: "grey.700", transform: 'initial', transformOrigin:'initial' }}
                    height={460}
                    width={"100%"}
                    key={key}
                  >
                     {/* <InfluencerItem key={key}  /> */}
                  </Skeleton>
                </Grid>
              ))}
      
            {targetArr
              .map((influencer, key) => {
                return (
                  <Grid item xs={12} key={key} md={3}>
                    <InfluencerItem key={key} influencer={influencer} />
                  </Grid>
                );
              })}
          </Grid>

          <Pagination
            count={Math.ceil(influencers.length / influencersPerPage)}
            onChange={(e, page) => setPage(page)}
            page={page}
            shape="rounded"
            variant="outlined"
            sx={{
              "& .Mui-selected": {
                backgroundColor: "rgb(131, 88, 255) !important",
              },
              "& .MuiButtonBase-root": { color: "white", border: "none" },
              '& .MuiPaginationItem-root' : {
                color: 'white'
              },
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          />
        </div>
      </section>
    </>
  );
};

export const InfluencerItem = ({ influencer }) => {

  const { uid, authorImage, followers, img, socialPlatforms,price, country, images } =
    influencer;
  const metrics = getMetrics(influencer);
  let { lowestPrice } = metrics;

  const itemLink = uid;
  const title = influencer?.userInfo?.username;
  const finalimg = images[0]?.imageURL || images[0]?.fileURL;
  return (
    <article>
      <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl block border bg-white block overflow-hidden  transition-shadow hover:shadow-lg text-jacarta-500">
        <figure>
          {/* {`item/${itemLink}`} */}
          <Link href={"/influencers/" + itemLink}>
            <a>
              <div className="w-full zoom-sm rounded-[0.625rem] max-h-230 h-230 group  SwiperSlide img  block overflow-hidden relative">
                <Image
                  src={finalimg}
                  alt={finalimg}
                  height={230}
                  width={230}
                  layout="responsive"
                  objectfit="cover"
                  className="image rounded-[0.625rem] w-full transition-transform transform scale-100 
                  group-hover:scale-105"
                  loading="lazy"
                  stlye={{
                    maxHeight: "230px !important",
                    objectFit: "contain"
                  }}
                />
                <div className="gradient-overlay absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-[rgba(55,40,69,0.66)]"></div>
                <div className="title-wrapper absolute bottom-0 left-0 w-full p-1 py-2">
                  
          <div className="flex items-center justify-center px-5 flex-wrap w-full">
          {influencer?.categories?.map((cat, index) => {
                    return (
                      <div
                        key={index}
                        className="my-1 mr-2.5 
                  dark:border-jacarta-600 group dark:bg-accent-light dark:bg-opacity-10 dark:border-jacarta-300 font-display flex h-9 items-center rounded-lg border px-4 text-sm font-semibold transition-colors  text-white dark: capitalize
                  "
                      >
                        {cat}
                      </div>
                    );
                  })}
          </div>
                  </div>
              </div>
              
            </a>
          </Link>
        </figure>
        <div className="p-3">
          <div className="mt-1.5 flex items-center justify-between">
            <Link href={"/influencers/" + itemLink}>
              <a>
                <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white hover:dark:text-accent-light transition-all">
                  {title}
                </span>
              </a>
            </Link>
            <span className="hidden dark:border-jacarta-600 group bg-accent border-jacarta-100 font-display items-center rounded-lg border px-4 py-2 text-sm font-semibold transition-colors border-transparent text-white dark:border-transparent capitalize">
              <Tippy content={<span>Followers</span>}></Tippy>

              <span className="text-white text-sm font-medium tracking-tight">
                {parseInt(
                  followers / 1000 > 999
                    ? followers / 1000000
                    : followers / 1000
                )}
                {followers / 1000 > 999 ? "M+" : "k+"} followers
              </span>
            </span>
          </div>
          <div className=" text-sm">
          <span className="text-jacarta-200  descText">
                  {influencer?.userInfo?.bio} <br/> &nbsp;
                </span>
          <br />

            <span className="hidden dark:text-jacarta-300 text-jacarta-500">
              Social plaftorms: &nbsp;
            </span>
            <span className="dark:text-jacarta-100 text-jacarta-700 hidden">
              <b> {socialPlatforms} </b>
            </span>
          </div>

          <div className="mt-0 pt-4 flex-wrap flex items-center justify-between dark:border-jacarta-600 border-jacarta-100 border-t gap-3">
            <div className="flex  ">
              <figure className=" shrink-0">
                {/* <Link href="/user/avatar_6"> */}
                {/* TODO LINK TO COUNTRY */}
                <a className="relative block">
                  <img
                    src={`https://flagcdn.com/w80/${getCountryCode(
                      country
                    )}.png`}
                    alt={country}
                    className="rounded-2lg h-10 w-auto"
                    loading="lazy"
                  />
                  <div
                    className="dark:border-jacarta-600 bg-green absolute -right-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white"
                    data-tippy-content="Verified "
                  >
                    <Tippy content={<span>Verified </span>}>
                      <svg className="icon h-[.875rem] w-[.875rem] fill-white">
                        <use xlinkHref="/icons.svg#icon-right-sign"></use>
                      </svg>
                    </Tippy>
                  </div>
                </a>
                {/* </Link> */}
              </figure>
              {/* <div className="flex flex-col justify-center">
                <span className="text-jacarta-400 block text-sm dark:text-white">
                  From <strong>{country}</strong>
                </span>

                <a className="text-accent block">
                  <span className="text-sm font-bold">Confirmed</span>
                </a>
              </div> */}
            </div>

            <Link href={`/influencers/${itemLink}`}>
              <a type="button flex-1">
                <span className="hover:text-accent transition-all">
                  <span className="dark:text-jacarta-300 text-jacarta-500">
                    Price starts from: &nbsp;
                  </span>
                  <span className="dark:text-jacarta-100 text-md text-jacarta-700 dark:hover:text-green">
                    <b> {influencer?.shadow ? parseInt(price*TAX_MULTIPLIER).toFixed(0) : lowestPrice}$</b>
                  </span>
                </span>
              </a>
            </Link>
            {/* <Likes
                      like={9999}
                      classes="flex items-center space-x-1"
                    /> */}
          </div>
          
          
        </div>
      </div>
    </article>
  );
};

const getCountryCode = (country) => {
  return (
    countries.find((x) => x.label === country)?.code?.toLowerCase() || "us"
  );
};

export default index;
