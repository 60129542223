import React, { useEffect, useState, useContext } from "react";
import { collection_activity_item_data } from "../../data/collection_data";
import { AuthContext } from "../../context/AuthContext";
import Link from "next/link";
import Image from "next/image";
import { Skeleton, Stack, useTheme } from "@mui/material";
import { ref, get, getDatabase, set, child } from "firebase/database";
import { useTranslation } from "react-i18next";
import Pagination from "@mui/material/Pagination";
import { CustomDropdown } from "./collection_category_filter";
import Trending_categories_items from "../categories/trending_categories_items";
import { tranding_category_filter } from "../../data/categories_data";
import { cities } from "../../pages/create";
import useMediaQuery from '@mui/material/useMediaQuery';
const influencersPerPage = 10;
export const getOrderRedirect = (item) => {
  const type = item.type
  if(type==='BUNDLE'){
    return 'newOrder'
  }
  if(type==='CUSTOM'){
    return 'customOrder'
  }
 
    return 'new'
  
}
const Activity_item = ({ onSelect, aside, children, type, hideFilters }) => {
  const { currentUser } = useContext(AuthContext);
  const [filterVal, setFilterVal] = useState(null);
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  const { t } = useTranslation();
  const [categories, setCategories] = useState(null);
  const [city, setCity] = useState(null);
  const [data, setData] = useState(collection_activity_item_data);
  const [fetching, setFetching] = useState(true);
  const [page, setPage] = useState(1);
  const [filterData, setfilterData] = useState(
    collection_activity_item_data.map((item) => {
      const { category } = item;
      return category;
    })
  );

  const [orders, setOrders] = useState([]);
  const [initialData, setInitial] = useState([]);

  useEffect(() => {
    if (onSelect) {
      onSelect(orders);
    }
  }, [orders]);

  useEffect(() => {
    if (city && city?.length!==0) {
     
      const newArr = initialData.filter((o) => o.hasOwnProperty('city') && city[0]?.text===o.city[0]?.text )
      setOrders(newArr)
    }
    else{
    
      setOrders(initialData)
    }
    
  }, [city]);

  useEffect(() => {
    if(orders/influencersPerPage<1){
     // setPage(1)
    }
  }, [orders])
  useEffect(() => {

    window.scrollTo(0, 0)

  }, [page])
  useEffect(() => {
    const categoryarr = categories?.map((c) => c.text);

    const newArr = initialData.filter((o) =>
      o?.categories?.some((c) => categoryarr?.includes(c))
    );

    if (newArr.length === 0 && categories?.length === 0) {
      setOrders(initialData);
    } else setOrders(newArr);
  }, [categories]);

  const [inputText, setInputText] = useState("");

  const handleFilter = (category) => {
    setData(
      collection_activity_item_data.filter((item) => item.category === category)
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const newArray = collection_activity_item_data.filter((item) => {
      return item.title.toLowerCase().includes(inputText);
    });
    setData(newArray);
    setInputText("");
  };

  useEffect(() => {
    setfilterData(filterData.filter(onlyUnique));

    const getData = async () => {
      const path = currentUser.influencer ? "activeOrders" : "orders";

      await get(child(ref(getDatabase()), `${path}/${currentUser.uid}`)).then(
        async (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            const keys = Object.keys(data);
            const orderarr = [];
            for await (const key of keys) {
              let obj = {
                ...data[key],
              };

              await get(
                child(ref(getDatabase()), `influencers/${obj.sellerUid}`)
              ).then(async (snapshot) => {
                if (snapshot.exists()) {
                  const sellerInfo = snapshot.val()?.userInfo;
                  const img = snapshot.val()?.images[0];
                  obj = {
                    ...obj,
                    sellerInfo,
                    img,
                  };
                }
              });

              orderarr.push(obj);
            }

            setOrders(orderarr);
            setInitial(orderarr);
            setFetching(false);
          } else {
            await get(
              child(ref(getDatabase()), `${"orders"}/${currentUser.uid}`)
            ).then(async (snapshot) => {
              if (snapshot.exists()) {
                const data = snapshot.val();
                const keys = Object.keys(data);
                const orderarr = [];
                for await (const key of keys) {
                  let obj = {
                    ...data[key],
                  };

                  await get(
                    child(ref(getDatabase()), `influencers/${obj.sellerUid}`)
                  ).then(async (snapshot) => {
                    if (snapshot.exists()) {
                      const sellerInfo = snapshot.val()?.userInfo;
                      const img = snapshot.val()?.images[0];
                      obj = {
                        ...obj,
                        sellerInfo,
                        img,
                      };
                    }
                  });

                  orderarr.push(obj);
                }

                setOrders(orderarr);
                setInitial(orderarr);
                setFetching(false);
              }
            });
            setFetching(false);
          }
        }
      );
    };

    const getRequests = async () => {
      const path =
        currentUser?.influencer || !currentUser
          ? "requests"
          : `/author_requests/${currentUser?.uid}`;

      await get(child(ref(getDatabase()), `bids/${path}`)).then(
        async (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            const keys = Object.keys(data);
            const orderarr = [];
            for await (const key of keys) {
              let obj = {
                key,
                ...data[key],
              };

              orderarr.push(obj);
            }
            setOrders(orderarr);
            setInitial(orderarr);
          }
          setFetching(false);
        }
      );
    };

    if (currentUser && type !== "REQUESTS") {
      getData();
    } else if (type === "REQUESTS") {
      getRequests();
    } else {
    }
  }, [currentUser?.influencer, type, currentUser]);

  const getClassNameByStatus = (state, dispute) => {
    if (dispute) {
      return "alert-error";
    }

    if (state === 4) {
      return "alert-success";
    }

    return "dark:bg-jacarta-700";
  };
  const theme = useTheme()
  const isMobile = !useMediaQuery( theme.breakpoints.up('sm'));


  const sortedData = orders
    ?.sort(function (a, b) {
      return a.date < b.date ? 1 : -1;
    })



 
  return (
    <>
      {/* <!-- Activity Tab --> */}
      <div className="tab-pane fade">
        {/* <!-- Records / Filter --> */}

        {!fetching && orders.length < 1 && type !== "REQUESTS" && (
          <div className="container">
            <div className="mx-auto max-w-lg text-center">
              {/* <span className="mb-14 inline-block">
					<Image
					  src="/images/404.png"
					  width={336}
					  height={165}
					  alt="gradient"
					/>
				  </span>
				  <h1 className="text-jacarta-700 font-display mb-6 text-4xl dark:text-white md:text-6xl">
					No orders yet
				  </h1> */}
              <p className="dark:text-jacarta-300 mb-12 text-lg leading-normal">
                {type !== "REQUESTS" && type !== "EARNINGS" && t("orderFirst")}
              </p>
              {type !== "REQUESTS" && type !== "EARNINGS" && (
                <Link href="/influencers">
                  <a className="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
                    {t("navigateToInfluencers")}
                  </a>
                </Link>
              )}
            </div>
          </div>
        )}
        
        <aside className="basis-4/12 lg:pl-5 pb-5" style={{display: !isMobile? 'none' : 'block'}}>
            {aside && (
              <>
                {/* <form
                  action="search"
                  className="relative mb-12 block"
                  onSubmit={handleSubmit}
                >
                  <input
                    type="search"
                    className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-[0.6875rem] px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
                    placeholder="Search"
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="fill-jacarta-500 h-4 w-4 dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z"></path>
                    </svg>
                  </button>
                </form> */}

                {!hideFilters && (
                  <React.Fragment>
                    <h3 className="font-display text-jacarta-500 mb-4 font-semibold dark:text-white">
                      {t("filters")}
                    </h3>
                    <div className="flex flex-wrap">
                  <CustomDropdown data={tranding_category_filter} type="Categories"multiSelect onSelect={setCategories}/>
                    </div>
                  </React.Fragment>
                )}
              </>
            )}
            {/* {filterData.map((category, i) => {
                    return (
                      <button
                        className={
                          filterVal === i
                            ? "dark:border-jacarta-600 group bg-accent border-jacarta-100 mr-2.5 mb-2.5 inline-flex items-center rounded-xl border px-4 py-3 border-transparent text-white dark:border-transparent"
                            : "dark:border-jacarta-600 dark:bg-jacarta-700 group dark:hover:bg-accent hover:bg-accent border-jacarta-100 mr-2.5 mb-2.5 inline-flex items-center rounded-xl border bg-white px-4 py-3 hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent"
                        }
                        key={i}
                        onClick={() => {
                          handleFilter(category);
                          setFilterVal(i);
                        }}
                      >
                        <svg
                          className={
                            filterVal === i
                              ? "icon mr-2 h-4 w-4 fill-white"
                              : "icon fill-jacarta-700 mr-2 h-4 w-4 group-hover:fill-white dark:fill-white"
                          }
                        >
                          <use xlinkHref={`/icons.svg#icon-${category}`}></use>
                        </svg>
                        <span className="text-2xs font-medium capitalize">
                          {category}
                        </span>
                      </button>
                    );
                  })} */}

            {orders.length > 0 && !fetching && children}
          </aside>

        <div className="lg:flex">
          {fetching && (
            <Stack spacing={2} sx={{ width: "100%", px:3 }}>
              <Skeleton
                sx={{ bgcolor: "grey.700" }}
                width={"100%"}
                height={200}
              />
              <Skeleton
                sx={{ bgcolor: "grey.700" }}
                width={"100%"}
                height={200}
              />
              <Skeleton
                sx={{ bgcolor: "grey.700" }}
                width={"100%"}
                height={200}
              />
            </Stack>
          )}

          <div className={`mb-10 shrink-0 basis-8/12 space-y-5 lg:mb-0 lg:pr-10 fade  ${fetching ? 'hidden' : ''}`}>
            {!fetching && type === "REQUESTS" && sortedData.length === 0 && (
              <p className="dark:text-jacarta-300 mb-12 text-lg leading-normal">
                {t("noRequests")}
              </p>
            )}
            {sortedData
              .slice(
                (page - 1) * influencersPerPage,
                page === 1
                  ? influencersPerPage
                  : (page - 1) * influencersPerPage + influencersPerPage
              )
              .map((item, index) => {
                let {
                  orderid,
                  total,
                  date,
                  description,
                  sellerUid,
                  sellerInfo,
                  img,
                  author,
                  timestamp,
                  categories,
                  budget,
                  key,
                  title,
                  city
                } = item;
             
                
                let username = sellerInfo?.username || author?.displayName;

                date =
                  date ||
                  new Date(
                    timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000
                  ).toDateString();

                if(budget){
                  if(parseInt(budget)>0){
                    total=budget
                  }
                }

                const itemLink = `?orderid=${orderid}`;
                const path =
                  type === "REQUESTS"
                    ? `/requests/${author?.uid}/${key}`
                    : `/orders/${getOrderRedirect(item)}/${sellerUid}?success=true&orderid=${orderid}`;

                const requestImg =
                  item?.files?.find((x) => x?.type?.includes("image"))
                    .fileURL || "/images/socialmedia1.jpg";
                return (
                  <Link href={path} key={orderid}>
                    <a
                      className={` ${getClassNameByStatus(
                        item.state,
                        item.hasOwnProperty("dispute")
                      )}
										 dark:border-jacarta-700s
										 border-jacarta-100s 
										 borders
										 shadow-lg
										 hover:shadow-[0_16px_24px_-8px_rgba(131,88,255,.05),0_-6px_2px_-8px_rgba(131,88,255,.05)]
										 hover:shadow-[0_16px_24px_-8px_rgba(131,88,255,.1),0_-6px_2px_-8px_rgba(131,88,255,.1)]
										 rounded-2.5xl relative flex items-center  bg-white p-8 transition-shadow flex flex-col md:flex-row gap-5`}
                     style={{animation: 'fadeIn .7s'}}
                    >
                      <figure className="h-full ">
                        <img
                          src={img?.imageURL || requestImg}
                          alt={img?.imageURL || requestImg}
                          height={type === "REQUESTS" ? 100 : 50}
                          width={type === "REQUESTS" ? 100 : 50}
                          objectFit="cover"
                          className={`rounded-2lg ${
                            type === "REQUESTS" ? "w-48" : "w-28"
                          } h-auto min-w-[200px] mb-5 md:mb-0`}
                          loading="lazy"
                          style={{animation: 'fadeIn 1s'}}
                        />
                      </figure>

                      <div className="flex flex-col self-stretch justify-between p-3 w-full">
                   
                        <div>
                        <h3 className="font-display text-lg text-jacarta-700 mb-1  font-semibold dark:text-white">
                          {title || 'Custom order'}
                        </h3>

                        <div className="min-h-[7em]">
                          <span className="dark:text-jacarta-200 text-jacarta-500 mb-3 block text-sm break-all mr-1">
                            <span className="text-green text-sm font-bold tracking-tight ">
                              {total}€{" "}
                            </span>
                            <b>{city && '| ' + city[0]?.text}</b>

                         
                         
                          </span>
                          <div className="flex flex-row flex-wrap">
                           
                          {categories?.map((cat) => (
                              <div className="my-1 mr-2.5 dark:border-jacarta-600 group bg-accent border-jacarta-100 font-display flex h-9 items-center rounded-lg border px-4 text-sm font-semibold trans">
                                {" "}
                                {typeof cat === "object" ? cat?.text : cat}{" "}
                              </div>
                            ))}
                          </div>

                          <span className="dark:text-jacarta-200 text-jacarta-500 mb-3 mt-2 block text-sm break-all mr-1">
                            <span className=" text-md  tracking-tight ">
                              {description}
                            </span>
                            

                         
                         
                          </span>
                        </div>
                        </div>
                    
                       
                     

                      <div className="ml-auto text-end mt-4">
                      <span className="text-jacarta-300 block text-xs">
                          {date.slice(0, 16)}
                        </span>
                        <p className="dark:text-jacarta-300 text-jacarta-400 font-bold text-sm">{username}</p>
                      </div>
                      </div>

                      <div className="dark:border-jacarta-600 border-jacarta-100 ml-auto rounded-full border p-3">
                        <svg className="icon fill-jacarta-700 dark:fill-white h-6 w-6">
                          <use xlinkHref={`/icons.svg#icon-purchases`}></use>
                        </svg>
                      </div>
                    </a>
                  </Link>
                );
              })}
          </div>

          {/* <!-- Filters --> */}
          <aside className="basis-4/12 lg:pl-5" style={{display: isMobile? 'none' : 'block'}}>
            {aside && (
              <>
                {/* <form
                  action="search"
                  className="relative mb-12 block"
                  onSubmit={handleSubmit}
                >
                  <input
                    type="search"
                    className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-[0.6875rem] px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
                    placeholder="Search"
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="fill-jacarta-500 h-4 w-4 dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z"></path>
                    </svg>
                  </button>
                </form> */}

                {!hideFilters && (
                  <React.Fragment>
                    <h3 className="font-display text-jacarta-500 mb-4 font-semibold dark:text-white">
                      {t("filters")}
                    </h3>
                    <div className="flex flex-wrap">
                    <CustomDropdown data={tranding_category_filter} type="Categories"multiSelect onSelect={setCategories}/>
                    </div>
                  </React.Fragment>
                )}
              </>
            )}
            {/* {filterData.map((category, i) => {
                    return (
                      <button
                        className={
                          filterVal === i
                            ? "dark:border-jacarta-600 group bg-accent border-jacarta-100 mr-2.5 mb-2.5 inline-flex items-center rounded-xl border px-4 py-3 border-transparent text-white dark:border-transparent"
                            : "dark:border-jacarta-600 dark:bg-jacarta-700 group dark:hover:bg-accent hover:bg-accent border-jacarta-100 mr-2.5 mb-2.5 inline-flex items-center rounded-xl border bg-white px-4 py-3 hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent"
                        }
                        key={i}
                        onClick={() => {
                          handleFilter(category);
                          setFilterVal(i);
                        }}
                      >
                        <svg
                          className={
                            filterVal === i
                              ? "icon mr-2 h-4 w-4 fill-white"
                              : "icon fill-jacarta-700 mr-2 h-4 w-4 group-hover:fill-white dark:fill-white"
                          }
                        >
                          <use xlinkHref={`/icons.svg#icon-${category}`}></use>
                        </svg>
                        <span className="text-2xs font-medium capitalize">
                          {category}
                        </span>
                      </button>
                    );
                  })} */}

            {orders.length > 0 && !fetching && children}
          </aside>
        </div>
        <Pagination
          count={Math.ceil(sortedData.length / influencersPerPage)}
          onChange={(e, page) => setPage(page)}
          page={page}
          shape="rounded"
          variant="outlined"
          sx={{
            "& .Mui-selected": {
              backgroundColor: "rgb(131, 88, 255) !important",
            },
            "& .MuiButtonBase-root": { color: "white", border: "none" },
            '& .MuiPaginationItem-root' : {
              color: 'white'
            },
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
          }}
        />
      </div>
    </>
  );
};

export default Activity_item;
