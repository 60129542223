import React, { useState, useEffect, useContext, useTransition } from "react";

import "tippy.js/dist/tippy.css"; // optional

import Meta from "../../components/Meta";
import {
  CustomDropdown,
  socialMediaPlatforms,
} from "../../components/collections/collection_category_filter";
import { tranding_category_filter } from "../../data/categories_data";
import FileUpload from "../../components/ui/FileUpload";
import { v4 as uuid } from "uuid";
import { AuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { Timestamp } from "@firebase/firestore";
import { getDatabase, ref, set } from "@firebase/database";
import { useRouter } from "next/router";

const Create = () => {

  const [socialmedias, setSocial] = useState([]);
  const [categories, setCategories] = useState([]);
  const [files, setFiles] = useState([]);
  const [fetching, setFetching] = useState(false);
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);
  const [request, setRequest] = useState(null);

  const router = useRouter();

  useEffect(() => {
    if (currentUser) {
      const id = uuid();
      setRequest({
        id: id,
        dbkey: `${currentUser?.uid}/requests/${id}`,
      });
    }
  }, [currentUser]);

  const [data, setData] = useState({
    title: null,
    description: null,
    goals: null,
    budget: null,
  });

  const disabled =
    !data.title || !data.description || !data.goals || !currentUser || fetching

  const createRequest = () => {
    const categoriesarr = categories.map((c) => c.text);
    const orderData = {
      timestamp: Timestamp.now(),
      ...data,
      budget: data.budget ? parseInt(data.budget) : 0,
      author: {
        displayName: currentUser.displayName,
        photoURL: currentUser.photoURL,
        uid: currentUser.uid,
      },
      categories: categoriesarr,
      socialmedias,
      files,
    };

    set(
      ref(
        getDatabase(),
        `bids/author_requests/${currentUser?.uid}/${request.id}`
      ),
      {
        ...orderData,
      }
    ).then((x) => {
      // success
      set(ref(getDatabase(), `bids/requests/${request.id}`), {
        ...orderData,
      }).then((x) => {
        router.push(`requests/${currentUser?.uid}/${request.id}`);
      });
    });
  };

  return (
    <div>
      <Meta title={t("createRequestTitle")} />
      {/* <!-- Create --> */}
      <section className="relative py-24">
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img
            src="/images/gradient_light.jpg"
            alt="gradient"
            className="h-full w-full"
          />
        </picture>
        <div className="container">
          <h1 className="font-display text-jacarta-700 py-16 text-center text-4xl font-medium dark:text-white">
            {t("common.create")}
          </h1>

          <div className="mx-auto max-w-[48.125rem]">
            {/* <!-- Name --> */}
            <div className="mb-6">
              <label
                htmlFor="item-name"
                className="font-display text-jacarta-700 mb-2 block dark:text-white"
              >
                {t("common.title")}
                <span className="text-red">*</span>
              </label>
              <input
                type="text"
                id="item-name"
                maxLength={50}
                value={data.title}
                onChange={(e) => setData({ ...data, title: e.target.value })}
                className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                placeholder={t("labels.title")}
                required
              />
            </div>

            {/* <!-- Description --> */}
            <div className="mb-6">
              <label
                htmlFor="item-description"
                className="font-display text-jacarta-700 mb-2 block dark:text-white"
              >
                {t("common.description")} <span className="text-red">*</span>
              </label>
              <p className="dark:text-jacarta-300 text-2xs mb-3"></p>
              <textarea
                id="item-description"
                className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                rows="4"
                value={data.description}
                onChange={(e) =>
                  setData({ ...data, description: e.target.value })
                }
                required
                placeholder={t("labels.describeLabel")}
              ></textarea>
            </div>

            <div className="mb-6">
              <label
                htmlFor="item-goals"
                className="font-display text-jacarta-700 mb-2 block dark:text-white"
              >
                {t("common.goals")} <span className="text-red">*</span>
              </label>
              <p className="dark:text-jacarta-300 text-2xs mb-3">
                {t("labels.viewsGoals")}
              </p>
              <textarea
                value={data.goals}
                onChange={(e) => setData({ ...data, goals: e.target.value })}
                id="item-goals"
                className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                rows="4"
                required
                placeholder="Tell the potential influencers your goals, so they would be intrigued to help you!"
              ></textarea>
            </div>

            <div className="mb-6">
              <label
                htmlFor="item-budget"
                className="font-display text-jacarta-700 mb-2 block dark:text-white"
              >
                {t("common.budget")}
                <span className="text-red"></span>
              </label>
              <input
                type="number"
                id="item-budget"
                className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                placeholder={t("labels.budget") + " $"}
                onChange={(e) => setData({ ...data, budget: e.target.value })}
                required
              />
            </div>

            <div className="mb-6">
              <FileUpload
              maxFiles={4}
                hook={files}
                loadingHook={setFetching}
                setHook={setFiles}
                uploadKey={request?.dbkey}
              />
            </div>

            <div className="flex flex-wrap items-center flex-col md:flex-row mb-10 pt-4">
              <CustomDropdown
                data={tranding_category_filter}
                type="Categories"
                multiSelect
                max={3}
                onSelect={setCategories}
              />

              <CustomDropdown
                data={socialMediaPlatforms}
                type="Social media platforms"
                cls={"social-media"}
                multiSelect
                onSelect={setSocial}
              />
            </div>

            {/* <div className="dark:border-jacarta-600 border-jacarta-100 relative border-b py-6">
              <div className="flex items-center justify-between">
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-accent mr-2 mt-px h-4 w-4 shrink-0"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M7 10h13a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h1V9a7 7 0 0 1 13.262-3.131l-1.789.894A5 5 0 0 0 7 9v1zm-2 2v8h14v-8H5zm5 3h4v2h-4v-2z" />
                  </svg>

                  <div>
                    <label className="font-display text-jacarta-700 block dark:text-white">
                      Unlockable Content
                    </label>
                    <p className="dark:text-jacarta-300">
                      Include unlockable content that can only be revealed by
                      the owner of the item.
                    </p>
                  </div>
                </div>
                <input
                  type="checkbox"
                  value="checkbox"
                  name="check"
                  className="checked:bg-accent checked:focus:bg-accent checked:hover:bg-accent after:bg-jacarta-400 bg-jacarta-100 relative h-6 w-[2.625rem] cursor-pointer appearance-none rounded-full border-none after:absolute after:top-[0.1875rem] after:left-[0.1875rem] after:h-[1.125rem] after:w-[1.125rem] after:rounded-full after:transition-all checked:bg-none checked:after:left-[1.3125rem] checked:after:bg-white focus:ring-transparent focus:ring-offset-0"
                />
              </div>
            </div> */}

            {/* <!-- Explicit & Sensitive Content --> */}
            {/* <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-6 border-b py-6">
              <div className="flex items-center justify-between">
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-jacarta-700 mr-2 mt-px h-4 w-4 shrink-0 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12.866 3l9.526 16.5a1 1 0 0 1-.866 1.5H2.474a1 1 0 0 1-.866-1.5L11.134 3a1 1 0 0 1 1.732 0zM11 16v2h2v-2h-2zm0-7v5h2V9h-2z" />
                  </svg>

                  <div>
                    <label className="font-display text-jacarta-700 dark:text-white">
                      Explicit & Sensitive Content
                    </label>

                    <p className="dark:text-jacarta-300">
                      Set this item as explicit and sensitive content.
                      <Tippy
                        content={
                          <span>
                            Setting your asset as explicit and sensitive
                            content, like pornography and other not safe for
                            work (NSFW) content, will protect users with safe
                            search while browsing Xhibiter
                          </span>
                        }
                      >
                        <span className="inline-block">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className="dark:fill-jacarta-300 fill-jacarta-500 ml-2 -mb-[2px] h-4 w-4"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z"></path>
                          </svg>
                        </span>
                      </Tippy>
                    </p>
                  </div>
                </div>
                <input
                  type="checkbox"
                  value="checkbox"
                  name="check"
                  className="checked:bg-accent checked:focus:bg-accent checked:hover:bg-accent after:bg-jacarta-400 bg-jacarta-100 relative h-6 w-[2.625rem] cursor-pointer appearance-none rounded-full border-none after:absolute after:top-[0.1875rem] after:left-[0.1875rem] after:h-[1.125rem] after:w-[1.125rem] after:rounded-full after:transition-all checked:bg-none checked:after:left-[1.3125rem] checked:after:bg-white focus:ring-transparent focus:ring-offset-0"
                />
              </div>
            </div> */}

            {/* <!-- Submit --> */}
            <button
              disabled={disabled}
              onClick={createRequest}
              className={`block b ${
                !disabled ? "bg-accent" : "bg-accent-lighter"
              }  ${
                !disabled ? "cursor-pointer" : "cursor-default"
              } text-end ml-auto rounded-full py-3 px-8 text-center font-semibold text-white transition-all`}
            >
              {t("common.create")}
            </button>
          </div>
        </div>
      </section>
      {/* <!-- end create --> */}
    </div>
  );
};

export default Create;
