const styles = {
    dark : {
        palette: {
            mode: 'dark',
            primary: {
                main: '#f0e7e6',
            },
            secondary: {
              main: '#5f1939'
            },
            textColors: {
              link: '#ecf5e1'
            },
            backgroundColors: {
                purple: '#974dff'
              },
            hover : {
            active: 'rgba(244, 237, 240, 0.1)',
            tooltipExpanded: '#2e354f'
            },
            icons: {
                blue: '#5989f7',
                file : '#cafae9',
            },
            backgroundGradients: {
                red: 'linear-gradient(305deg, rgba(164,10,34,1) 0%, rgba(255,135,135,1) 100%)'
            }
      
        },
        components: {
            
            MuiAppBar: {
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: 'rgb(0, 53, 77)',
                        backgroundImage: 'none',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                      
                        transition: 'all .7s',
                        '&:hover' : {
                           
                            transition: 'all .7s',
                        }
                    },
                },
            },
            MuiPaper : {
                styleOverrides : {
                    root : {
                       backgroundColor: '#140e1d',
                       backgroundImage: 'none',
                       //boxShadow: 'rgb(46 148 240 / 40%) 0px 5px, rgb(46 180 240 / 30%) 0px 10px, rgb(46 229 240 / 20%) 0px 15px, rgb(46 196 240 / 10%) 0px 20px'
                       boxShadow: 'rgb(176 174 190 / 2%) 0px 10px 20px 0px'
                    }
                }
            }
        },
    },
    light: {
       
            palette: {
                mode: 'light',
                primary: {
                    main: '#7cc5e9',
                },
                secondary: {
                  main: '#5f1939'
                },
                textColors: {
                  link: '#ecf5e1'
                },
                backgroundColors: {
                  purple: '#974dff'
                },
                hover : {
                    active: 'rgba(244, 237, 240, 0.1)',
                    tooltipExpanded: '#ebf2f5'
                    },
                icons: {
                    blue: '#4c80f8',
                    file : '#cafae9',
                },
                background: {
                    default: "#ebe9e6",
                  },
            },
            components: {
                MuiAppBar: {
                    styleOverrides: {
                        colorPrimary: {
                            backgroundColor: 'rgb(0, 53, 77)',
                            backgroundImage: 'none',
                        },
                    },
                },
                MuiPaper : {
                    styleOverrides : {
                        root : {
                           backgroundColor: '#ebf2f5',
                           backgroundImage: 'none',
                           
                        }
                    }
                },
                MuiButton : {
                    styleOverrides : {
                        root : {
                         
                           boxShadow: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;'
                        }
                    }
                }
            },
        
    }    
}
export default styles