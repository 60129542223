import { current } from "@reduxjs/toolkit";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/AuthContext";
import { ChatContext } from "../../context/ChatContext";
import More_items from "../../pages/item/more_items";

const Message = ({ message, last }) => {
  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);
  const { user } = data;

  if(!currentUser){
    return <div> </div>
  }




  const myImage =
    currentUser?.imageURL ||
    localStorage.getItem("influence-avatar-url", currentUser.photoURL);
  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  }, [message]);
  const owner = message.senderId === currentUser.uid;
  return (
    <div ref={ref}>
      <div
        className={`message ${owner && "owner"}`}
        style={{ animation: `fadeIn ${2}s` }}
      >
        <div className="messageInfo">
          <img src={owner ? myImage : data.user.photoURL} alt="" />
          <span className="dark:text-jacarta-300 text-jacarta-400 text-sm"></span>
        </div>
        {message.hasOwnProperty("bidData") ? (
          <OfferComponent
          currentUser={currentUser}
            message={message}
            influencer={currentUser?.influencer}
            user={currentUser?.influencer ? currentUser?.influencerObj : user}
          />
        ) : (
          <div className="messageContent ">
            <p
              className={`leading-normal2 break-all ${
                !owner ? "bg-jacarta-700" : "bg-accent"
              }`}
            >
              {message.text}
            </p>
            {message.img && <img src={message.img} alt="" />}
          </div>
        )}
      </div>
      {last && <div className="h-[50px]" style={{ opacity: 0 }} />}
    </div>
  );
};

export const OfferComponent = ({ message, user, influencer , currentUser, activeBid}) => {

  const { bid, followersPerUsd, discount, selected } = message ? message.bidData : activeBid;
  const [cart, setCart] = useState([]);
  const [fancyArr, setArr] = useState([])
useEffect(() => {
    if(selected && fancyArr.length===0){
      const arr = [];
      arr.push(`${selected.length} campaigns`);
      arr.push(`${followersPerUsd} followers per dollar`);
      arr.push(`${discount}$ discount from usual price`);
      setArr(arr)
    }
}, [selected])
const router = useRouter()
  const acceptOffer = async () => {

router.push( {
  pathname: `/orders/customOrder/${user?.uid}`,
  query: {
    selected: JSON.stringify(selected), bid
  },
})
    // (function async() {
    //   fetch(`${window?.location?.origin}/api/custom_checkout_session`, {
    //     method: "POST",
    //     body: JSON.stringify({
    //       cart : cart,
    //       sellerUid: user?.uid,
    //       buyerUid: currentUser?.uid,
    //       description: 'Custom',
    //       total: bid,
    //     }),
    //   })
    //     .then((res) => res.json())
    //     .then((data) => {
    //       window.location.href = data.session.url;
    //     })
    //     .catch((err) => console.log(err));
    // })();
  }

  const { t } = useTranslation();




  return (
    <div
      className="relative rounded-2.5xl mb-5 border border-jacarta-100 bg-white p-10 hover:shadow-[0_5px_0_0_#8358ff]
       transition-shadow shadow-[0_16px_24px_-8px_rgba(131,88,255,.3)] 
    dark:border-jacarta-700 dark:bg-jacarta-700 w-full
    dark:border-jacarta-800
    "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        className="absolute right-3 top-3 h-16 w-16 fill-jacarta-50 dark:fill-jacarta-600"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z" />
      </svg>
      <div className="mb-6 inline-flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className="h-12 w-12 fill-accent"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z" />
        </svg>
      </div>
      <h3 className="mb-4 font-display text-lg text-jacarta-700 dark:text-white">
        <span className="text-green text-xl mr-2">{bid}$</span>
        {t("customOffer")}
      </h3>
      <p className="text-jacarta-100 mb-5">
        {t("sellerIsOffering")}:
        {fancyArr.map((x, key) => (
          <div className="mt-3 flex space-x-4" key={key}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              className="h-8 w-8 shrink-0 fill-accent"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" />
            </svg>
            <div>
              <span className="mb-1 mt-1 block text-base  dark:text-jacarta-100">
                {x}
              </span>
            </div>
          </div>
        ))}
        <span className="font-bold"></span>
      </p>
      <More_items
        thumbnailHeight={200}
        gridSx={{ m: 0 }}
        selections={selected}
        selectedItemsHook={selected}
        cartHook={setCart}
        user={user}
        gridItemSize={6}
        disabled={true}
        parentStyles={
          "py-0 dark:bg-transparent dark:border-jacarta-600 border-jacarta-100 border-t border-b pb-4 "
        }
        title={" "}
        subtitle={" "}
        fabHidden
      />

      {!influencer && (
        <div className="flex justify-between w-full flex-wrap mt-0 pt-7 dark:border-jacarta-600 border-jacarta-100 border-t border-b">
          <button className="alert-error" disabled style={{ opacity: 0 }}>
            {t("decline")}
          </button>

          <button
          onClick={acceptOffer}
            className="alert-success cursor-pointer py-3 px-5 rounded-full block transition-all
         font-bold text-center dark:hover:bg-green dark:hover:text-white"
          >
            {t("acceptOffer")}
          </button>
        </div>
      )}
    </div>
  );
};

export default Message;
