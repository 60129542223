const newseLatterData = [
	{
		id: '1',
		icon: { parentBg: '#c4f2e3', childBg: 'rgb(16 185 129)', svg: 'icon-wallet' },

		title: 'Discover Influencers',
		text: 'Explore a diverse range of influencers with audiences that align with your project or product.',
	},
	{
		id: '2',
		icon: { parentBg: '#beaaf7', childBg: 'rgb(131 88 255) ', svg: 'icon-wallet' },

		title: 'Pay with Crypto',
		text: 'Complete transactions seamlessly using cryptocurrencies like Ethereum through MetaMask integration.',
	},

	{
		id: '3',
		icon: { parentBg: '#cddffb', childBg: 'rgb(66 138 248)', svg: 'icon-gallery' },
		title: 'Boost Engagement',
		text: 'Watch your project gain traction as influencers share it with their engaged communities, driving clicks and conversions.',
	},
	{
		id: '4',
		icon: { parentBg: '#ffd0d0', childBg: 'rgb(239 68 68)', svg: 'icon-list' },
		title: 'Provide Feedback',
		text: 'Share your experience and provide valuable feedback to influencers, helping to shape future campaigns and improve outcomes.',
	},
];

export { newseLatterData };
