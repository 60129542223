import { Paper, Box, Stack } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { FileUploader } from "react-drag-drop-files";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  getStorage,
  deleteObject,
} from "firebase/storage";
import { useTranslation } from "react-i18next";
import MuiLoader from "./MuiLoader";
import Tippy from "@tippyjs/react";

const fileFormats = {
  all: [
    "JPG",
    "PNG",
    "GIF",
    "SVG",
    "MP4",
    "MP3",
    "DOCX",
    "DOC",
    "XLS",
    "XLSX",
    "PDF",
  ],
  images: ["JPG", "PNG", "JPEG"],
};
const FileUpload = ({
  uploadKey,
  setHook,
  hideOnLoad,
  hook,
  maxFiles,
  required,
  label,
  maxSize,
  loadingHook,
  fileTypes,
}) => {
  const [files, setFiles] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingtext, setLoadingtxt] = useState("");
  const [progress, setProgress] = useState(0);

  const handleChange = (files) => {

    if(maxFiles+1 > files.length+hook?.length){
      setFiles(Array.from(files));
    }
    
   
  };
  const acceptedTypes = fileFormats[fileTypes] || fileFormats.all;
  useEffect(() => {
    let i = 0;
    if (files && files.length > 0) {
      loadingHook(true);
      if (files.length > 1) {
        // setHook([]);
      }
      const payload = [];
      for (const file of files) {
        setProgress(0);
        const storage = getStorage();
        const storageRef = ref(storage, `${uploadKey}/${file.name}`);

        const uploadTask = uploadBytesResumable(storageRef, file);

        if (hook.some((x) => x.size === file.size && x.name === file.name)) {
          return;
        }

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            setLoading(true);
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress);
            console.log("Upload is " + progress + "% done");

            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            loadingHook(false);
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              const obj = {
                key: `file-${i}`,
                fileURL: downloadURL,
                type: file.type,
                size: file.size,
                name: file.name,
              };

              payload.push(obj);

              i = i + 1;

              setLoadingtxt(`${i}/${files.length}`);
              if (i === files.length) {
                loadingHook(false);
                setHook([...hook, ...payload]);
                setProgress(100);
                setLoading(false);
              }
            });
          }
        );
      }
    }
  }, [files]);

  const handleRemove = async (file) => {
    const storage = getStorage();
    const storageRef = ref(storage, `${uploadKey}/${file.name}`);
    // Delete the file
    deleteObject(storageRef)
      .then(() => {
        const newVals = hook.filter(
          (f) => f.name !== file.name && f.size !== file.size
        );
        setHook(newVals);
        setFiles(newVals)
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const labelText = label ? label : t("fileUpload.attachments");
  return (
    <Box>
     <>
     {
     ( (hideOnLoad &&  files.length<1 || hook.length<1 ) || !hideOnLoad) &&
      <>
       <label className="font-display text-jacarta-700  block dark:text-white">
        {labelText} {required && <span className="text-red">*</span>}
        {/* <span className="text-red">*</span> */}
      </label>
      <div
        className="dark:bg-jacarta-700 dark:border dark:border-accent border-jacarta-100 group relative flex
                flex-col items-center justify-center rounded-lg border-2 border-dashed bg-white py-20 text-center
							mx-auto my-2
							"
      >
        <div className="relative z-10 cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-500 mb-4 inline-block dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M16 13l6.964 4.062-2.973.85 2.125 3.681-1.732 1-2.125-3.68-2.223 2.15L16 13zm-2-7h2v2h5a1 1 0 0 1 1 1v4h-2v-3H10v10h4v2H9a1 1 0 0 1-1-1v-5H6v-2h2V9a1 1 0 0 1 1-1h5V6zM4 14v2H2v-2h2zm0-4v2H2v-2h2zm0-4v2H2V6h2zm0-4v2H2V2h2zm4 0v2H6V2h2zm4 0v2h-2V2h2zm4 0v2h-2V2h2z" />
          </svg>
          <p className="dark:text-jacarta-300 mx-auto max-w-xs text-xs">
            {t("fileUpload.maxFileSize")}: {maxSize || 5} MB
          </p>
          <p className="dark:text-jacarta-300 mx-auto max-w-xs text-xs px-3">
            {acceptedTypes.join(", ")}
          </p>
        </div>
        <div
          className="dark:bg-jacarta-600 bg-jacarta-50 absolute inset-4 cursor-pointer rounded opacity-0 group-hover:opacity-100 "
          style={{ zIndex: 9999 , transition:'all .3s'}}
        >
          
          <FileUploader
            handleChange={handleChange}
            onDrop={handleChange}
            onSelect={handleChange}
            name="file"
            multiple
            types={acceptedTypes}
            classes="file-drag w-full max-w-100 "
            maxSize={maxSize || 5}
            maxFiles={maxFiles || 3}
            minSize={0}
          />
        </div>
      </div>
      {
      loading &&   <MuiLoader
      progress={progress}
    
      label={loadingtext}
    />
    }

      {hook?.length < 1 && !loading && (
        <p className="dark:text-jacarta-300 text-2xs mb-3 mt-2">
          {t("fileUpload.dragAndDrop")}
        </p>
      )}
      </>
     }
     </>


      {hook?.length > 0 && (
        <FileList files={hook} handleRemove={handleRemove} />
      )}
    </Box>
  );
};

const FileList = ({ files, handleRemove }) => {
  const [imageModal, setImageModal] = useState(false);
  const [image, setImage] = useState("");

  return (
    <Stack
      direction="row"
      className="gap-4 mt-6"
      sx={{
        alignItems: "center",
        justifyContent: { xs: "center", md: "start" },
        width: "100%",
        flexWrap: "wrap",
        "& > *": {
          mr: { xs: "16px !important", md: "inherit !important" },
        },
      }}
    >
      <figure>
        {/* <!-- Modal --> */}
        <div className={imageModal ? "modal fade show block" : "modal fade"}>
          <div className="relative">
            <div className="modal-dialog !my-0 flex h-auto w-auto max-w-[80vw] items-center justify-center relative">
              <img
                src={image}
                alt={"hero image"}
                className="h-auto w-auto rounded-2xl max-h-[80vh]"
              />
            </div>
            <button
              type="button"
              className="btn-close absolute top-[-25px] right-[-25px]"
              onClick={() => setImageModal(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="h-6 w-6 fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
              </svg>
            </button>
          </div>
        </div>
        {/* <!-- end modal --> */}
      </figure>

      {files?.map((file, index) => {
        return (
          <figure
            key={index}
            className=" shrink-0 md:self-stretch  "
            style={{ animation: `fadeIn ${1}s` }}
          >
            <a className="relative flex mt-2 md:mt-0  h-full">
              {file.type.includes("image") && (
                <div
                  onClick={() => {
                    setImage(file.fileURL);
                    setImageModal(true);
                  }}
                  className="service-item relative rounded-2.5xl border
                  border-jacarta-100 bg-white p-10 pt-12 transition-shadow
                  hover:shadow-xl dark:border-jacarta-700 dark:bg-jacarta-700
                  dark:border dark:border-accent
                  cursor-pointer
                  flex flex-col items-center justify-center"
                >
                  <img
                    src={`${file.fileURL}`}
                    alt={"file"}
                    className="rounded-2lg h-32 mb-6 w-auto pb-2"
                    loading="lazy"
                  />
                  <h3 className=" font-display text-md text-jacarta-700 dark:text-accent max-w-[9rem]">
                    <div className="block overflow-hidden text-ellipsis whitespace-nowrap">
                      <span className="text-white text-md font-bold">
                        {file.name}
                      </span>
                    </div>
                  </h3>
                  <p className="dark:text-jacarta-300">
                    {(file.size / (1024 * 1024)).toFixed(2) + "Mb"}
                  </p>
                </div>
              )}
              {!file.type.includes("image") && (
                <a
                  href={`${file.fileURL}`}
                  target="_blank"
                  className="service-item relative rounded-2.5xl dark:border dark:border-accent bg-white p-10 pt-12 transition-shadow hover:shadow-xl dark:border-jacarta-700 dark:bg-jacarta-700 flex flex-col items-center justify-center
                 dark:hover:bg-jacarta-600 dark:border dark:border-accent
                  transition-all
                  hover:transition-all
                  "
                >
                  <span className="absolute top-[32%] left-30 block h-10 w-10 rounded-full bg-[#DCD0FF] dark:bg-accent"></span>
                  <svg className="icon relative mb-6 h-32 w-8 fill-accent-dark dark:fill-white">
                    <use xlinkHref="/icons.svg#icon-service-ico-5"></use>
                  </svg>
                  <h3 className=" font-display text-md text-jacarta-700 dark:text-accent max-w-[9rem]">
                    <div className="block overflow-hidden text-ellipsis whitespace-nowrap">
                      <span className="text-white text-md font-bold">
                        {file.name}
                      </span>
                    </div>
                  </h3>

                  <p className="dark:text-jacarta-300">
                    {(file.size / (1024 * 1024)).toFixed(2) + "Mb"}
                  </p>
                </a>
              )}
              {handleRemove && (
                <button
                  className="dark:border dark:border-accent bg-red absolute -right-3 top-[-5%] flex h-6 w-6 items-center cursor-pointer justify-center rounded-full border-2 border-white btn-close"
                  data-tippy-content="Remove "
                  onClick={() => handleRemove(file)}
                >
                  <Tippy content={<span>Remove </span>}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                    </svg>
                  </Tippy>
                </button>
              )}
            </a>
          </figure>
        );
      })}
    </Stack>
  );
};
export default FileUpload;

export const FileItem = ({ file, key }) => {
  const [imageModal, setImageModal] = useState(false);
  const [image, setImage] = useState("");
  return (
    <>
      {/* <!-- Modal --> */}
      <div className={imageModal ? "modal fade show block" : "modal fade"}>
        <div className="relative">
          <div className="modal-dialog !my-0 flex h-auto w-auto max-w-[80vw] items-center justify-center relative">
            <img
              src={image}
              alt={"hero image"}
              className="h-auto w-auto max-h-[60vh]  rounded-2xl"
            />
          </div>
          <button
            type="button"
            className="btn-close absolute top-[-25px] right-[-25px]"
            onClick={() => setImageModal(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              className="h-6 w-6 fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
            </svg>
          </button>
        </div>
      </div>
      {/* <!-- end modal --> */}
      <figure
        key={key}
        className=" shrink-0 md:self-stretch "
        style={{ animation: `fadeIn ${1}s` }}
      >
        <a className="relative flex mt-2 md:mt-0  h-full">
          {file.type.includes("image") && (
            <div
              onClick={() => {
                setImage(file.fileURL);
                setImageModal(true);
              }}
              className="service-item relative rounded-2.5xl border
                  border-jacarta-100 bg-white p-5 transition-shadow
                  hover:shadow-xl dark:border-jacarta-700 dark:bg-jacarta-700
                  cursor-pointer
                    dark:hover:bg-jacarta-600 dark:border dark:border-accent
                  transition-all
                  hover:transition-all
                  flex flex-col items-center justify-center"
            >
              <img
                src={`${file.fileURL}`}
                alt={"file"}
                className="rounded-2lg h-9 mb-2 w-auto pb-2"
                loading="lazy"
              />
              <h3 className=" font-display text-md text-jacarta-700 dark:text-accent max-w-[9rem]">
                <div className="block overflow-hidden text-ellipsis whitespace-nowrap">
                  <span className="text-white text-sm font-bold">
                    {file.name}
                  </span>
                </div>
              </h3>
              <p className="dark:text-jacarta-300">
                {(file.size / (1024 * 1024)).toFixed(2) + "Mb"}
              </p>
            </div>
          )}
          {!file.type.includes("image") && (
            <a
              href={`${file.fileURL}`}
              target="_blank"
              className="service-item relative rounded-2.5xl dark:border dark:border-accent bg-white p-5
             transition-shadow hover:shadow-xl dark:border-jacarta-700 dark:bg-jacarta-700 flex flex-col items-center justify-center
                 dark:hover:bg-jacarta-600 dark:border dark:border-accent
                  transition-all
                  hover:transition-all
                  "
            >
              <span className="absolute top-[12%] left-30 block h-10 w-10 rounded-full bg-[#DCD0FF] dark:bg-accent"></span>
              <svg className="icon relative mb-6 h-9 mb-2 w-8 fill-accent-dark dark:fill-white">
                <use xlinkHref="/icons.svg#icon-service-ico-5"></use>
              </svg>
              <h3 className=" font-display text-sm text-jacarta-700 dark:text-accent max-w-[9rem]">
                <div className="block overflow-hidden text-ellipsis whitespace-nowrap">
                  <span className="text-white sm-md font-bold">
                    {file.name}
                  </span>
                </div>
              </h3>

              <p className="dark:text-jacarta-300">
                {(file.size / (1024 * 1024)).toFixed(2) + "Mb"}
              </p>
            </a>
          )}
        </a>
      </figure>
    </>
  );
};
export const ImageItem = ({ file, index }) => {
  const [imageModal, setImageModal] = useState(false);
  const [image, setImage] = useState("");

return (
   <React.Fragment key={index}>
 <div className={imageModal ? "modal fade show block" : "modal fade"}>
        <div className="relative">
          <div className="modal-dialog !my-0 flex h-auto w-auto max-w-[80vw] items-center justify-center relative">
            <img
              src={image}
              alt={"hero image"}
              className="h-auto w-auto max-h-[60vh] rounded-2xl"
              style={{animation: 'fadeIn .5s'}}
            />
          </div>
          <button
            type="button"
            className="btn-close absolute top-[-25px] right-[-25px]"
            onClick={() => setImageModal(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              className="h-6 w-6 fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
            </svg>
          </button>
        </div>
      </div>
    <img
     onClick={() => {
      setImage(file.fileURL);
      setImageModal(true);
    }}
                src={`${file.fileURL}`}
                alt={"file"}
                className="rounded-2lg w-32 h-full my-auto cursor-pointer zoom "
                loading="lazy"
                style={{animation: 'fadeIn .9s'}}
              />
    </React.Fragment>
)
  
  return (
    <>

    
      {/* <!-- Modal --> */}
      <div className={imageModal ? "modal fade show block" : "modal fade"}>
        <div className="relative">
          <div className="modal-dialog !my-0 flex h-auto w-auto max-w-[80vw] items-center justify-center relative">
            <img
              src={image}
              alt={"hero image"}
              className="h-auto w-auto max-h-[60vh] rounded-2xl"
              style={{animation: 'fadeIn 1.2s'}}
            />
          </div>
          <button
            type="button"
            className="btn-close absolute top-[-25px] right-[-25px]"
            onClick={() => setImageModal(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              className="h-6 w-6 fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
            </svg>
          </button>
        </div>
      </div>
      {/* <!-- end modal --> */}
      <figure
        key={key}
        className=" shrink-0 md:self-stretch "
        style={{ animation: `fadeIn ${1}s` }}
      >
        <a className="relative flex mt-2 md:mt-0  h-full">
      
            <div
              onClick={() => {
                setImage(file.fileURL);
                setImageModal(true);
              }}
              className="service-item relative rounded-2.5xl border
                  border-jacarta-100 bg-white p-5 transition-shadow
                  hover:shadow-xl dark:border-jacarta-700 dark:bg-jacarta-700
                  cursor-pointer
                    dark:hover:bg-jacarta-600 dark:border dark:border-jacarta-300
                  transition-all
                  hover:transition-all
                  flex flex-col items-center justify-center"
            >
              <img
                src={`${file.fileURL}`}
                alt={"file"}
                className="rounded-2lg h-24  w-auto "
                loading="lazy"
              />
         
              <p className="dark:text-jacarta-300">
              
              </p>
            </div>
          
         
        </a>
      </figure>
    </>
  );
};
