import { Swiper, SwiperSlide } from "swiper/react";
import React, { useState, useEffect } from "react";
import { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Image from "next/image";
import "tippy.js/dist/tippy.css";
import Skeleton from "@mui/material/Skeleton";
import { bidsData } from "../../data/bids_data";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { bidsModalShow } from "../../redux/counterSlice";
import { useDispatch, useSelector } from "react-redux";
import Likes from "../likes";

import { showHeroes, fetchingHeroInfluencers } from "../../redux/counterSlice";
import { countries } from "../ui/CountrySelect";
import { InfluencerItem } from "../../pages/influencers";
const gigKeys = ["instagram", "tiktok", "facebook", "twitch", "youtube"];

const getCountryCode = (country) => {
  return (
    countries.find((x) => x.label === country)?.code?.toLowerCase() || "us"
  );
};

export const getMetrics = (user) => {
  const arr = [];
  const socialPlatforms = new Set();
  let priceArr = [];
  Object.keys(user).map((key) => gigKeys.includes(key) && arr.push(user[key]));

  arr.map((val) => {
    val.items.map((sub) => {
      if (sub.checked) {
        socialPlatforms.add(val);
        priceArr.push(sub.price);
      }
    });
  });
  priceArr = priceArr.sort(function (a, b) {
    return a - b;
  });
  const returnVal = priceArr.length > 0 ? priceArr[0] : 29.99;

  return {
    lowestPrice: returnVal,
    socialPlatforms: Array.from(socialPlatforms).length,
  };
};

const BidsCarousel = () => {
  const dispatch = useDispatch();
  const { influencers } = useSelector(showHeroes);
  const fetching = useSelector(fetchingHeroInfluencers).payload.counter
    .fetchingHeroInfluencers;

  const [data, setData] = useState(["", "", "", "", "", "", ""]);

  const [metrics, setMetrics] = useState(null);
  useEffect(() => {
    if (influencers) {
      let heroData = [];
      influencers.map((x) => {
        const metrics = getMetrics(x);
        const nonAvatarArr = x.images.filter((x) => x.key !== "avatar");

        heroData = [
          ...heroData,
          {
            ...x,
            id: x.uid,
            subtext:
              x.instagram.username.length > 0
                ? "@" + x.instagram.username
                : x.userInfo.bio,
            authorImage: x.images[0].imageURL || x.images[0].fileURL,
            title: x.userInfo.username,
            country: x.country,
            img: x.images[1].imageURL || x.images[1].fileURL,
            // nonAvatarArr[Math.floor(Math.random() * x.images.length)]?.imageURL,
            followers: x.followers,
            ...metrics,
          },
        ];
      });
      setData([...heroData]);
    }
  }, [influencers]);
  const handleclick = () => {};
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar]}
        spaceBetween={30}
        slidesPerView="auto"
        loop={true}
        breakpoints={{
          240: {
            slidesPerView: 1,
          },
          565: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 3,
          },
          1100: {
            slidesPerView: 4,
          },
        }}
        navigation={{
          nextEl: ".bids-swiper-button-next",
          prevEl: ".bids-swiper-button-prev",
        }}
        className=" card-slider-4-columns !py-5"
      >
        {data.map((item, key) => {
          if (typeof item !== "object") {
            return (
              <SwiperSlide key={key}>
                <Skeleton     sx={{ bgcolor: "grey.700", transform: 'initial', transformOrigin:'initial' }} height={450} />
              </SwiperSlide>
            );
          }
          const {
            id,
            authorImage,
            title,
            followers,
            lowestPrice,
            img,
            socialPlatforms,
            country,
          } = item;

          const itemLink = id;
          return (
            <SwiperSlide className="text-white" key={id}>
             <InfluencerItem influencer={item} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* <!-- Slider Navigation --> */}
      <div className="group bids-swiper-button-prev swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-left-6 after:hidden">
        <MdKeyboardArrowLeft />
      </div>
      <div className="group bids-swiper-button-next swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-right-6 after:hidden">
        <MdKeyboardArrowRight />
      </div>
    </>
  );
};

export default BidsCarousel;
