const Browse_category_data = [
	// {
	// 	id: '0Virtual Worlds',
	// 	image: '/images/categories/category_4.png',
	// 	title: 'Virtual Worlds',
	// 	href: '#',
	// 	bgColor: 'rgb(70 199 227)',
	// },
	{
		id: '1Sport',
		image: '/images/categories/category_5.png',
		title: 'Sport',
		href: '#',
		bgColor: 'rgb(115 126 242)',
	},
	{
		id: '2Photography',
		image: '/images/categories/category_6.png',
		title: 'Photo',
		href: '#',
		bgColor: 'rgb(66 138 248)',
	},
	{
		id: '3Music',
		image: '/images/categories/category_7.png',
		title: 'Music',
		href: '#',
		bgColor: 'rgb(243 91 199)',
	},
	{
		id: '4Art',
		image: '/images/categories/category_1.png',
		title: 'Art',
		href: '#',
		bgColor: 'rgb(16 185 129)',
	},
	{
		id: '5',
		image: '/images/categories/category_1.png',
		title: 'Makeup',
		href: '#',
		bgColor: 'rgb(16 185 50)',
	},
	{
		id: '6',
		image: '/images/categories/category_2.png',
		title: 'Beauty',
		href: '#',
		bgColor: 'rgb(16 185 50)',
	},
	
];

export default Browse_category_data;
