import { createContext, useEffect, useState } from "react";
import { auth } from "../utils/fire";
import { onAuthStateChanged } from "firebase/auth";
import { axiosInstance } from "../utils/Axios";
import { Router } from "next/router";
import { useMetaMask } from "metamask-react";
export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const { status, connect, account, chainId, ethereum } = useMetaMask();
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    const getUserType = async () => {
      if(account){
        currentUser.uuid = currentUser.uid
        currentUser.uid = account
        currentUser.photoURL = ''
      }

      const res = await axiosInstance.get(`/users/exists/${currentUser.uid}`);
      const { influencer, verified, userinfo, onboarded, influencerObj } = res.data;

    
      const { username, email, bio } = userinfo;
      let { imageURL } = userinfo;
      if (!imageURL) {
        imageURL = currentUser.photoURL;
      }
      setCurrentUser({
        ...currentUser,
        influencer,
        onboarded,
        influencerObj,
        verified,
        imageURL,
        username,
        email,
        bio,
      });
     
    };

    
    if (currentUser?.uid) {
      getUserType();
    }
   
  }, [currentUser?.uid]);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
