import * as React from "react";
import PropTypes from "prop-types";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Stack from "@mui/material/Stack";

function LinearProgressWithLabel(props) {
  const { label } = props;
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="white">{`${Math.round(
          props.value + "|" + label
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#5A5D79",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#8358FF",
  },
}));

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function MuiLoader({ progress, label, sx }) {
  return (
    <Stack
      sx={{ ...sx, width: "100%", mt: 1.5, alignItems: "center" }}
      direction={'row'}
      spacing={1}
      justifyContent='space-between'
    >
      <Box sx={{ width: "100%" }}>
        <BorderLinearProgress variant="determinate" value={progress} />
      </Box>
      <Box sx={{ minWidth: 20, mt: { xs: 2, md: 0, textAlign:'end' } }}>
        <Typography variant="body2" color="white">{`${Math.round(
          progress
        )}%`}</Typography>
      </Box>
    </Stack>
  );
}
