import Link from 'next/link';
import React, { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { buyModalHide } from '../../redux/counterSlice';


import { loadStripe } from '@stripe/stripe-js';
import { AuthContext } from '../../context/AuthContext';
import { CircularProgress, Skeleton } from '@mui/material';

const stripePromise = loadStripe('pk_live_51MH5ZeGIQ5dHZBpJIe4UqEYaFeXUAzQyjwArcafAFtmT2NwmvYodQlrmhVi2nwlm49OHmKXDpujQ1Z5Zgr1E0wdH00rOkgcexl')
export const OrderBenefits = ({list}) => {
	const targetList = list || fancyList
	return (
	  <div className="py-4 ">
	  {/* <h2 className="mb-2 font-display text-xl text-jacarta-700 md:text-left text-center dark:text-white">
		Visų sau dirbančių žmonių svajonių programėlė.
	  </h2> */}
	  <p className="mb-4 text-lg md:text-left text-center  leading-normal dark:text-jacarta-200">
		<b>Influenz</b> has it's own unique vision. A fast and global scale platform sheltering thousands of influencers and buyers enables the next generation level of marketing.
		 
	  </p>
  
	  {targetList.map((val, i) => (
		<div className="mb-2 flex space-x-4" key={i}>
		  <svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width="24"
			height="24"
			className="h-8 w-8 shrink-0 fill-accent"
		  >
			<path fill="none" d="M0 0h24v24H0z" />
			<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" />
		  </svg>
		  <div>
			<span className="mb-3 mt-2 block font-display text-base font-semibold text-jacarta-700 dark:text-jacarta-100">
			  {val}
			</span>
		  </div>
		</div>
	  ))}
  
  
	</div>
	)
  }


const PricingOptions = ({activeItem, setActiveItem}) => {
	
	const {currentUser} = useContext(AuthContext)
    const [fetching, setFetching] = useState(false)
	async function handlePurchase({ price, uuid, duration }) {
		try {
		  setFetching(true);
		  const stripe = await stripePromise;
	  
		  // Create a checkout session
		  const response = await fetch('/api/subscribe_session', {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json',
			},
			body: JSON.stringify({ price, uuid, duration }),
		  });
	  
		  const session = await response.json();
	  
		  // Redirect to Stripe checkout page
		  const result = await stripe.redirectToCheckout({
			sessionId: session.id,
		  });
	  
		  if (result.error) {
			// Handle any error during redirect
			console.error(result.error.message);
			setFetching(false);
		  }
		  setFetching(false);
		} catch (error) {
		  console.error(error);
		  setFetching(false);
		}
	  }
	  
if(fetching){
	return (
		<div className="mx-auto my-auto flex h-[150px] align-center items-center md:h-[250px]">
			<CircularProgress sx={{mx:'auto'}} size="3rem" color="inherit" />
		</div>
		//    <Skeleton      sx={{ bgcolor: "grey.700", transform: 'initial', transformOrigin:'initial' , mx:'auto', width: {xs:'90%', md:'50%'}}} height={200} 
		   
		//     />
	)
}
  return (
    <div className="flex justify-center flex-wrap flex-col md:flex-row gap-3">
		
      <div 
	  	// onClick={() => handlePurchase({
		// 	price: 999,
		// 	uuid: currentUser.uid || 0
		// })}
	  className={
		  `cursor-pointer max-w-sm flex-1 bg-white dark:bg-accent-darker border border-jacarta-700   shadow-[0_-5px_0_0_#8358ff] 
        shadow-[0_-5px_0_0_#8358ff,0_5px_0_0_#8358ff]
        shadow-[0_16px_24px_-8px_rgba(131,88,255,.3),0_-6px_2px_-8px_rgba(131,88,255,.3)]
        transition-shadow hover:shadow-[0_16px_24px_-8px_rgba(131,88,255,.3)] rounded-xl rounded-xl overflow-hidden shadow-lg m-4 zoom-sm`
	  }>
        <div className="px-6 py-4 my-auto">
          <div className="text-center">
            <h3 className="text-xl font-bold mb-2">1 month</h3>
            <p className="text-gray-700 dark:text-jacarta-100 mb-2">Boost your influencer career with a month-long membership!</p>
            <p className="text-gray-700 text-green font-bold text-xl mb-2">29.99$</p>
       <button
		  	onClick={() => handlePurchase({
			price: 2999,
			uuid: currentUser.uid,
			duration: 1
		})}
              className="rounded-full bg-white py-3 px-8 text-center font-semibold mt-2 text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume">
				  Order
			  </button>
          </div>
		  
        </div>
      </div>
      <div className="max-w-sm  flex-1 zoom-sm  dark:bg-accent-dark  mt-auto mb-auto md:mt-[-20px] md:mb-[20px]  shadow-[0_-5px_0_0_#8358ff] 
        shadow-[0_-5px_0_0_#8358ff,0_5px_0_0_#8358ff]
        shadow-[0_16px_24px_-8px_rgba(131,88,255,.3),0_-6px_2px_-8px_rgba(131,88,255,.3)]
        transition-shadow hover:shadow-[0_16px_24px_-8px_rgba(131,88,255,.3)] rounded-xl rounded-xl overflow-hidden shadow-lg m-4 t">
			
        <div className="px-6 py-4">
          <div className="text-center my-auto block">
            <h3 className="text-xl font-bold mb-2">2 months</h3>
            <p className="text-gray-700 dark:text-jacarta-100 mb-2">Get ready for growth with a two-month subscription.</p>
            <p className="text-gray-700 rounded-lg alert-success mx-auto p-2 max-w-[150px] text-white font-bold text-xl mb-2">39.99$</p>
          <button
		  	onClick={() => handlePurchase({
			price: 3999,
			uuid: currentUser.uid,
			duration: 2
		})}
              className="rounded-full bg-white py-3 px-8 text-center font-semibold mt-2 text-accent  transition-all hover:bg-green hover:text-white ">
				 🚀 Order 
			  </button>
          </div>
        </div>
      </div>
      <div className="max-w-sm flex-1 zoom-sm  bg-white dark:bg-jacarta-900 border border-jacarta-700  shadow-[0_-5px_0_0_#8358ff] 
        shadow-[0_-5px_0_0_#8358ff,0_5px_0_0_#8358ff]
        shadow-[0_16px_24px_-8px_rgba(131,88,255,.3),0_-6px_2px_-8px_rgba(131,88,255,.3)]
        transition-shadow hover:shadow-[0_16px_24px_-8px_rgba(131,88,255,.3)] rounded-xl rounded-xl overflow-hidden shadow-lg m-4">
        <div className="px-6 py-4">
          <div className="text-center">
            <h3 className="text-xl font-bold mb-2">3 months</h3>
            <p className="text-gray-700 dark:text-jacarta-200 mb-2">Supercharge your influence with a three-month membership.</p>
            <p className="text-gray-700 text-green font-bold text-xl mb-2">59.99$</p>
          <button
		  	onClick={() => handlePurchase({
			price: 5999,
			uuid: currentUser.uid,
			duration: 3
		})}
              className="rounded-full bg-white py-3 px-8 text-center font-semibold mt-2 text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume">
				  Order
			  </button>
          </div>
        </div>
      </div>
    </div>
  );
};




const BuyModal = () => {
	const { buyModal } = useSelector((state) => state.counter);
	const dispatch = useDispatch();
	const [activeItem, setActiveItem] = useState('')

	return (
		<div>
			{/* <!-- Buy Now Modal --> */}
			<div className={buyModal ? 'modal fade show block' : 'modal fade'}   style={{ animation: `fadeIn ${.3}s` }}>
				<div className="modal-dialog max-w-[90vw] md:max-w-[70vw] ">
					<div className="modal-content dark:bg-jacarta-700">
						<div className="modal-header">
							<h5 className="modal-title " id="buyNowModalLabel">
								Purchase membership
							</h5>
							<button type="button" className="btn-close" onClick={() => dispatch(buyModalHide())}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									width="24"
									height="24"
									className="fill-jacarta-700 h-6 w-6 dark:fill-white"
								>
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
								</svg>
							</button>
						</div>

						{/* <!-- Body --> */}
						<div className="modal-body p-6 hidden">
							<div className="mb-2 flex items-center justify-between">
								<span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">
									Item
								</span>
								<span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">
									Subtotal
								</span>
							</div>

							<div className="dark:border-jacarta-600 border-jacarta-100 relative flex items-center border-t border-b py-4">
								<figure className="mr-5 self-start">
									<img
										src="/images/avatars/avatar_2.jpg"
										alt="avatar 2"
										className="rounded-xl"
										loading="lazy"
									/>
								</figure>

								<div>
									<a href="collection.html" className="text-green text-sm">
										-20%
									</a>
									<h3 className="font-display text-jacarta-700 mb-1 text-base font-semibold dark:text-white">
										Lazyone Panda
									</h3>
									<div className="flex flex-wrap items-center">
										<span className="dark:text-jacarta-300 text-jacarta-500 mr-1 block text-sm">
											Creator Earnings: 5%
										</span>
										<span data-tippy-content="The creator of this collection will receive 5% of the sale total from future sales of this item.">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												width="24"
												height="24"
												className="dark:fill-jacarta-300 fill-jacarta-700 h-4 w-4"
											>
												<path fill="none" d="M0 0h24v24H0z" />
												<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z" />
											</svg>
										</span>
									</div>
								</div>

								<div className="ml-auto">
									<span className="mb-1 flex items-center hidden whitespace-nowrap">
										<span data-tippy-content="ETH">
											<svg className="h-4 w-4">
												<use xlinkHref="/icons.svg#icon-ETH"></use>
											</svg>
										</span>
										<span className="dark:text-jacarta-100 text-sm font-medium tracking-tight">
											1.55 ETH
										</span>
									</span>
									<div className="dark:text-jacarta-300 text-right text-sm">$130.82</div>
								</div>
							</div>

							{/* <!-- Total --> */}
							<div className="dark:border-jacarta-600 border-jacarta-100 mb-2 flex items-center justify-between border-b py-2.5">
								<span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
									Total
								</span>
								<div className="ml-auto">
									<span className="flex items-center whitespace-nowrap">
										<span data-tippy-content="ETH">
											<svg className="h-4 w-4">
												<use xlinkHref="/icons.svg#icon-ETH"></use>
											</svg>
										</span>
										<span className="text-green font-medium tracking-tight">1.55 ETH</span>
									</span>
									<div className="dark:text-jacarta-300 text-right">$130.82</div>
								</div>
							</div>

							{/* <!-- Terms --> */}
							<div className="mt-4 flex items-center space-x-2">
								<input
									type="checkbox"
									id="buyNowTerms"
									className="checked:bg-accent dark:bg-jacarta-600 text-accent border-jacarta-200 focus:ring-accent/20 dark:border-jacarta-500 h-5 w-5 self-start rounded focus:ring-offset-0"
								/>
								<label htmlFor="buyNowTerms" className="dark:text-jacarta-200 text-sm">
									By checking this box, I agree to {"Xhibiter's"}{' '}
									<Link href="/tarms">
										<a className="text-accent">Terms of Service</a>
									</Link>
								</label>
							</div>
						</div>

						<div className="modal-body p-6 pb-2 pt-2 max-h-[64vh] overflow-y-auto scrollbar-custom">
						<div className="py-0 md:py-4 mt-2">
								<PricingOptions activeItem={activeItem} setActiveItem={activeItem}/>
						</div>
							<OrderBenefits list={[
								'Be a part of a global network',
								'Forget dealing with countless questions about your marketing services',
								'Position yourself in the market and watch your data-driven buyers going back for more'
							]}/>
						</div>
						{/* <!-- end body --> */}

						<div className="modal-footer hidden md:block">
							<div className="flex items-center justify-center space-x-4">
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BuyModal;
