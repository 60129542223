import * as React from "react";
import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import Skeleton from "@mui/material/Skeleton";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Link from "@mui/joy/Link";
import Favorite from "@mui/icons-material/Favorite";
import Visibility from "@mui/icons-material/Visibility";
import {
  Paper,
  Stack,
  Divider,
  CardActionArea,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Slider from "@mui/material/Slider";
import Tippy from "@tippyjs/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "tippy.js/dist/tippy.css"; // optional
export default function CustomizableGig({
  title,
  type,
  priceProps,
  thumbnailHeight,
  arrayIndex,
  handleUrlChange,
  onChange,
  item,
  category,
  displayMode,
  buyerView,
  checkedProp,
  disabled,
  activePurchaseMode,
  postedToSocialMedia,
  postURL,
}) {
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(false);
  const [focused, setFocused] = React.useState(false);
  const [touched, setTouched] = React.useState(false);
  const [price, setPrice] = React.useState(priceProps);
  const [copied, setCopied] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [url, setUrl] = React.useState("");

  React.useEffect(() => {
    if (url) {
      handleUrlChange({
        index: arrayIndex,
        url,
        state: null,
      });
    }
  }, [url]);

  React.useEffect(() => {
    touched && setChecked(true);
  }, [touched]);

  React.useEffect(() => {
    checkedProp && setChecked(checkedProp);
  }, [checkedProp]);

  React.useEffect(() => {
    onChange && onChange(checked, item, category, price, checked);
    if (disabled && !checked) {
      //retard
      setChecked(true);
    }
  }, [checked, price]);

  return (
    <>
      <Card
        sx={{
          minHeight: thumbnailHeight ? thumbnailHeight : "350px",
          width: "100%",
          //  height:'100%',
          pr: 0,
          pl: 0,
          "& .JoyTypography-root": { zIndex: 9 },
          "& .MuiStack-root": { zIndex: 9 },
        }}
      >
        <CardActionArea
          disabled={disabled ? disabled : false}
          onClick={() => !focused && setChecked(!checked)}
          sx={{
            height: "100%",
            position: "absolute",
            width: "100%",
            cursor: "pointer",
            "& .MuiTouchRipple-root": {
              zIndex: 9999,
              display: "none",
              //cursor:'inherit'
            },
            "&.MuiButtonBase-root": {
              display: "flex",
              alignItems: "flex-end",
              //cursor:'inherit'
            },
          }}
        >
          <CardCover>
            <img
              src={`/images/selection/${type}.jpg`}
              srcSet={`/images/selection/${type}.jpg`}
              loading="lazy"
              alt=""
            />
          </CardCover>
          <CardCover
            sx={{
              background: activePurchaseMode
                ? postedToSocialMedia
                  ? "linear-gradient(to top, rgb(26 7 42 / 60%), rgb(40 232 155 / 41%) 200px),linear-gradient(to top, rgb(42 26 64), rgb(9 9 9 / 0%) 300px)"
                  : "linear-gradient(to top, rgb(0 0 0), rgb(39 37 37 / 6%) 200px),linear-gradient(to top, rgb(55 49 75 / 75%), rgb(0 0 0 / 23%) 300px)"
                : //'linear-gradient(to top, rgb(26 7 42 / 60%), rgb(128 19 250 / 68%) 200px),linear-gradient(to top, rgb(42 26 64), rgb(9 9 9 / 0%) 300px)'
                //  'linear-gradient(to top, rgb(26 7 42 / 60%), rgb(222 237 83/ 68%) 200px),linear-gradient(to top, rgb(42 26 64), rgb(9 9 9 / 0%) 300px)'
                checked
                ? "linear-gradient(to top, rgb(26 7 42 / 60%), rgb(117 45 232 / 44%) 200px),linear-gradient(to top, rgb(42 26 64), rgb(9 9 9 / 0%) 300px)"
                : "linear-gradient(to top, rgb(0 0 0), rgb(39 37 37 / 6%) 200px),linear-gradient(to top, rgb(55 49 75 / 75%), rgb(0 0 0 / 23%) 300px)",
              border: checked ? "1px double  #8358FF" : "none",
              //  'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px),linear-gradient(to top, rgb(56 53 62), rgba(0,0,0,0) 300px)'
            }}
          />

          <CardContent sx={{ justifyContent: "space-between", height: "100%" }}>
            <Box
              sx={{
                zIndex: 999999999,
                display: "flex",
                alignItems: "end",
                justifyContent: "flex-end",
              }}
            >
              <Box
                sx={{
                  bgcolor: "#d4c4f0",
                  borderBottomLeftRadius: 20,
                  p: 0.5,
                  "& .MuiSvgIcon-root path": { fill: "#8358FF" },
                }}
              >
                {!!activePurchaseMode && !postedToSocialMedia ? (
                  <CircularProgress
                    sx={{ color: "#6042f5" }}
                    variant="indeterminate"
                    size={30}
                  />
                ) : (
                  <IconButton
                    onClick={(e) => setChecked(!checked)}
                    disabled={disabled ? disabled : false}
                    color="#8358FF"
                    size="large"
                  >
                    {checked && !postedToSocialMedia ? (
                      <CheckCircleIcon />
                    ) : (
                      !postedToSocialMedia && <RadioButtonUncheckedIcon />
                    )}{" "}
                    {postedToSocialMedia && <CheckCircleIcon />}
                  </IconButton>
                )}
              </Box>
            </Box>

            <Box>
              <Typography
                sx={{
                  fontSize: thumbnailHeight ? 20 : 30,
                  textAlign: "center",
                }}
                fontWeight="bold"
                mb={1}
              >
                {title.split(".")?.length > 0 ? t(title) : title}
              </Typography>

              <Stack
                direction="column"
                spacing={1}
                justifyContent="center"
                alignItems="center"
                sx={{ width: "auto", zIndex: 9, mb: 3.7 }}
              >
                <Divider sx={{ width: "40%" }} color="#a981cc" />
                <span style={{ whiteSpace: "nowrap" }}>
                  {" "}
                  {activePurchaseMode ? (
                    postedToSocialMedia ? (
                      <span
                        className="dark:border-jacarta-600 dark:bg-accent-dark dark:hover:bg-accent-light
        group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border 
        bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white
         dark:hover:border-transparent dark:hover:text-white"
                      >
                        Posted!
                      </span>
                    ) : (
                      <Skeleton
                        sx={{
                          //background: 'linear-gradient(180deg, rgba(153,172,18,0.7875525210084033) 0%, rgba(74,73,82,0.4150035014005602) 100%)',
                          bgcolor: "grey.900",
                          p: 2,
                        }}
                      >
                        <span
                          className="text-md font-semibold "
                          style={{ zIndex: 20, visibility: "visible" }}
                        >
                          Not posted yet
                        </span>
                      </Skeleton>
                    )
                  ) : (
                    "Price starts from:"
                  )}
                </span>
                {!activePurchaseMode && (
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "auto" }}
                  >
                    <input
                      disabled={displayMode}
                      onFocus={() => {
                        setFocused(true), setTouched(true);
                      }}
                      onBlur={() => setFocused(false)}
                      onChange={(e) => {
                        setPrice(e.target.value), setTouched(true);
                      }}
                      type="number"
                      id="price"
                      className="dark:bg-jacarta-700
                 
                   text-end
                   border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300  rounded-lg  hover:ring-2 dark:text-white "
                      placeholder="Enter price"
                      name="price"
                      min="5"
                      max={displayMode ? String(price) : String(10000000)}
                      value={price}
                    />
                    <span className="text-accent text-xl">
                      <b>$</b>
                    </span>
                  </Stack>
                )}
              </Stack>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
      {!!postedToSocialMedia && (
        <Paper
          className="dark:bg-jacarta-800 flex align-center justify-center flex-col"
          sx={{ p: 3, mt: 1 }}
        >
          <p className="dark:text-jacarta-300 mx-auto mb-2 max-w-xl text-sm">
            Click to <span className="text-accent-lighter">copy</span> post link
            or media below
          </p>
          <div
            className="dark:bg-jacarta-700   inline-flex items-center justify-center 
      rounded-full border-2  
  
      dark:border-jacarta-600
      
      py-1.5 px-4"
          >
            <Tippy content="Link">
              {/* <svg className="icon h-4 w-4 mr-1">
                          <use xlinkHref="/icons.svg#icon-ETH"></use>
                        </svg> */}
            </Tippy>

            <Tippy
              hideOnClick={false}
              content={copied ? <span>copied</span> : <span>copy</span>}
            >
              <button className="js-copy-clipboard dark:text-jacarta-200 max-w-[18rem] select-none overflow-hidden text-ellipsis whitespace-nowrap">
                <CopyToClipboard text={postURL} onCopy={() => setCopied(true)}>
                  <span>{postURL}</span>
                </CopyToClipboard>
              </button>
            </Tippy>
          </div>
        </Paper>
      )}
      {!buyerView && !postedToSocialMedia && activePurchaseMode && (
        <Paper
          className="dark:bg-jacarta-800 flex align-center justify-center flex-col"
          sx={{ p: 3, mt: 1 }}
        >
          <p className="dark:text-jacarta-300 mx-auto mb-2 max-w-xl text-sm">
            <span className="text-accent"> Posted? </span> Pleast paste post
            link or image link to post.
          </p>
          <div className="">
            <input
              onChange={(e) => {
                setUrl(e.target.value);
              }}
              type="text"
              id="url"
              className="dark:bg-jacarta-700
                   dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 inline-flex items-center justify-center rounded-full border bg-white py-1.5 px-4
                   text-center
                   border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300  rounded-full
                    dark:text-white w-full "
              placeholder="Enter post or image link"
              name="url"
              value={url}
            />
          </div>
          <button
            onClick={() =>
              handleUrlChange({
                index: arrayIndex,
                url,
                state: "posted",
              })
            }
            className="bg-accent py-3 px-8 mt-5  flex cursor-pointer mx-auto   shadow-accent-volume hover:bg-accent-dark rounded-full  text-center font-semibold text-white transition-all"
          >
            Post{" "}
          </button>
        </Paper>
      )}
      {!displayMode && (
        <Paper className="dark:bg-jacarta-800" sx={{ pt: 2.5 }}>
          <Slider
            aria-label="Temperature"
            value={parseInt(price)}
            onChange={(e) => {
              setPrice(e.target.value), setTouched(true);
            }}
            min={10}
            max={price > 500 ? parseInt(price) + parseInt(500) : 1000}
            valueLabelDisplay="auto"
            sx={{
              "& .MuiSlider-thumb": {
                backgroundColor: "#6632fc",
              },
              "& .MuiSlider-rail": {
                color: "#8358ff",
              },
              "&.MuiSlider-root": {
                color: "#8358ff",
              },
            }}
          />
        </Paper>
      )}
    </>
  );
}
