import React, { useState, useEffect } from "react";
import Recently_added_dropdown from "../dropdown/recently_added_dropdown";
import { tranding_category_filter } from "../../data/categories_data";
import CountrySelect from "../ui/CountrySelect";
import Tippy from "@tippyjs/react";
import { SearchBar } from "../ui/SearchBar";
import GroupedAutocomplete from "../ui/GroupedAutocomplete";

export const socialMediaPlatforms = [
  {
    id: 0,
    text: "All",
  },

  {
    id: 1,
    text: "Instagram",
  },

  {
    id: 2,
    text: "Facebook",
  },

  {
    id: 3,
    text: "YouTube",
  },
  {
    id: 5,
    text: "TikTok",
  },
  {
    id: 6,
    text: "Twitch",
  },
  {
    id: 7,
    text: "Real life",
  },
];

const Collection_category_filter = ({
  searchParams,
  setSearchParams,
  setCounter,
  counter,
  disabled,
}) => {
  const [propertiesModal, setPropertiesModal] = useState(false);
  const [propetiesAccordionValue, setPropetiesAccordionValue] = useState(null);

  const [countries, setCountries] = useState(null);
  const [categories, setCategories] = useState(null);
  const [followers, setFollowers] = useState(null);
  const [prices, setPrices] = useState(null);
  const [socialmedias, setSocial] = useState(null);
  const [search, setSearch] = useState(searchParams.search);
  React.useEffect(() => {
    const socialMediaPlatforms =
      socialmedias?.map((social) => social?.text) || null;
    const categoriesArr = categories?.map((cat) => cat?.text);

    setSearchParams({
      ...searchParams,
      countries: JSON.stringify(countries),
      categories:
        tranding_category_filter?.length === categoriesArr?.length
          ? null
          : JSON.stringify(categoriesArr),
      socialMediaPlatforms:
        socialMediaPlatforms?.length === 7
          ? null
          : JSON.stringify(socialMediaPlatforms),
    
      priceFrom: prices,
      priceTo: null,
      followersFrom: followers,
    });
  }, [socialmedias, prices, followers, categories, countries]);

  return (
    <>
      {/* <!-- Filter --> */}
      <div className="mb-8 flex flex-wrap items-center md:justify-between sm:justify-center sm-justify-center filterWrap">
        <div className="relative  w-full md:w-auto min-w-[25%] my-3">
        <GroupedAutocomplete 
       onSelect={(e) => {
        e.title = e.title.replace('Search', '').trim()
        setSearchParams({
          ...searchParams, 
          search: e.title
        })
     
       }}
       selectedValue={searchParams.search}
       label={
        "Search influencers by any keyword, @username, category"
      }/>
         
        </div>

        <div className="block py-2 md:py-0">
          <CountrySelect onSelect={setCountries} multiSelect />
        </div>
        <div className="flex flex-wrap items-center flex-col md:flex-row">
          <CustomDropdown
            data={tranding_category_filter}
            type="Categories"
            multiSelect
            onSelect={setCategories}
          />

          {/* <CustomDropdown
            data={tranding_category_filter}
            type="Prices"
            number
            disableToInput
            onSelect={setPrices}
          />

          <CustomDropdown
            data={tranding_category_filter}
            type="Followers"
            number
            disableToInput
            onSelect={setFollowers}
          /> */}

          <CustomDropdown
            data={socialMediaPlatforms}
            type="Social media platforms"
            cls={"social-media"}
            multiSelect
            onSelect={setSocial}
          />
        </div>

        {/* <Recently_added_dropdown data={sortText} dropdownFor="recently_added" /> */}
      </div>
<div className="w-full flex justify-between flex-wrap items-center mb-2  md:mb-4 mt-[-10px] md:mt-[-20px]  border-jacarta-600 border-t false  py-1">
<button
      disabled={disabled}
        onClick={() => setCounter(counter + 1)}
        className={`
				
				 mt-5
				 mb-5
				 text-center
				 justify-center
         w-full
         md:w-auto
				max-w-[auto] md:max-w-[150px]
				   flex
		 ${disabled ? "cursor-not-allowed " : "cursor-pointer bg-accent"}
					ml-auto sm:mr-auto md:mr-0 mx-auto shadow-accent-volume 
					hover:${
            !disabled ? "bg-accent-dark" : ""
          } rounded-full py-3 px-8 text-center font-semibold text-white transition-all`}
      >
        Search
      </button>
</div>
    
    </>
  );
};

export const CustomDropdown = ({
  multiSelect,
  onSelect,
  data,
  type,
  number,
  cls,
  disableToInput,
  max,
  classes,
  buttonClasses,
}) => {
  const [dropdown, setdropdown] = useState(false);
  const [sortActive, setsortActive] = useState([]);
  const [currencyValFrom, setCurrencyValFrom] = useState(null);
  const [currencyValTo, setCurrencyValTo] = useState(null);

  const [visible, setVisible] = useState(false);

  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  React.useEffect(() => {
    if (sortActive instanceof Array && !number) {
      onSelect(sortActive);
    } else if (!(sortActive instanceof Array) && number) {
      sortActive && onSelect(sortActive);
    }
  }, [sortActive]);

  const handleCurrencyValTo = (e) => {
    const value = e.target.value;
    if (value < 0) {
      setCurrencyValTo(0);
    } else {
      setCurrencyValTo(value);
    }
  };
  const handleCurrencyValFrom = (e) => {
    const value = e.target.value;
    onSelect(value);
    if (value < 0) {
      setCurrencyValFrom(0);
    } else {
      setCurrencyValFrom(value);
    }
  };

  const handleCurrencyDropdown = () => {
    if (dropdown) {
      setdropdown(false);
    } else {
      setdropdown(true);
    }
  };

  const handleDropdown = () => {
    window.addEventListener("click", (w) => {
      if (w.target.closest(`.${cls ? cls : type}`)) {
        if (dropdown) {
          setdropdown(false);
        } else {
          setdropdown(true);
        }
      } else if (!w.target.closest(".dropdown-item")) {
        setdropdown(false);
      }
    });
  };

  if (number) {
    return (
      <div className={`my-1 mr-2.5 w-full md:w-auto md:w-auto ${classes}`}>
        <Tippy
          visible={visible}
          onClickOutside={hide}
          animation="fade"
          arrow={false}
          trigger="click"
          interactive="true"
          placement="bottom"
          className="tooltip-container"
          content={
            <div
              className={`dropdown-menu dark:bg-jacarta-800 z-10 min-w-[220px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl show
						
							`}
              aria-labelledby="priceRangeFilter"
            >
              {/* <!-- From / To --> */}
              <div className="flex items-center space-x-3 px-5 pb-2">
                <input
                  type="number"
                  placeholder="From"
                  max={100000}
                  value={currencyValFrom}
                  onChange={(e) => handleCurrencyValFrom(e)}
                  className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full max-w-[8rem] min-w-[6rem] rounded-lg border py-[0.6875rem] px-4 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
                />
                {!disableToInput && (
                  <input
                    type="number"
                    placeholder="To"
                    value={currencyValTo}
                    onChange={(e) => handleCurrencyValTo(e)}
                    className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full max-w-[8rem] min-w-[4rem] rounded-lg border py-[0.6875rem] px-4 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
                  />
                )}
              </div>
            </div>
          }
        >
          <button
            onClick={() => setVisible(!visible)}
            className={`group dropdown-toggle dark:border-jacarta-600 dark:bg-jacarta-700 group dark:hover:bg-accent hover:bg-accent border-jacarta-100 font-display
						 text-jacarta-700 flex h-11 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent
						  hover:text-white dark:text-white
						  ${(currencyValFrom || currencyValTo) && "dark:bg-accent"} 
						  ${visible && "dark:bg-accent-dark"} 
              ${buttonClasses}
						  `}
            id="priceRangeFilter"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <div className="mx-auto flex justify-center align-center items-center mt-[5px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="fill-jacarta-700 dark:fill-jacarta-100 mr-1 h-4 w-4 transition-colors group-hover:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M17 16h2V4H9v2h8v10zm0 2v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3zM5.003 8L5 20h10V8H5.003zM7 16h4.5a.5.5 0 1 0 0-1h-3a2.5 2.5 0 1 1 0-5H9V9h2v1h2v2H8.5a.5.5 0 1 0 0 1h3a2.5 2.5 0 1 1 0 5H11v1H9v-1H7v-2z"></path>
              </svg>
              <span>{type}</span>
            </div>
          </button>
        </Tippy>
      </div>
    );
  }

  return (
    <div className={`my-2 md:my-1 mr-2.5 relative w-full md:w-auto ${classes}`}>
      <button
        className={`group dropdown-toggle w-full md:w-auto category-dropdown ${
          cls ? cls : type
        }  dark:border-jacarta-600 dark:bg-jacarta-700 dark:hover:bg-accent hover:bg-accent border-jacarta-100
					 font-display text-jacarta-700 flex h-11 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent 
					 hover:text-white dark:text-white min-w-[220px] text-center
					 ${sortActive && sortActive.length > 0 && "dark:bg-accent"} 
              ${buttonClasses}
					 `}
        onClick={handleDropdown}
      >
        <div className="mx-auto flex justify-center align-center items-center mt-[5px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-700 dark:fill-jacarta-100 mr-1 h-4 w-4 transition-colors group-hover:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M14 10v4h-4v-4h4zm2 0h5v4h-5v-4zm-2 11h-4v-5h4v5zm2 0v-5h5v4a1 1 0 0 1-1 1h-4zM14 3v5h-4V3h4zm2 0h4a1 1 0 0 1 1 1v4h-5V3zm-8 7v4H3v-4h5zm0 11H4a1 1 0 0 1-1-1v-4h5v5zM8 3v5H3V4a1 1 0 0 1 1-1h4z"></path>
          </svg>
          <span>{type}</span>
        </div>
      </button>

      <div
        className={
          dropdown
            ? "dropdown-menu dark:bg-jacarta-800 z-10 min-w-[220px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl show absolute top-full right-0"
            : "dropdown-menu dark:bg-jacarta-800 z-10 min-w-[220px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl hidden absolute top-full right-0"
        }
      >
        <ul className="scrollbar-custom flex max-h-64 flex-col overflow-y-auto">
          {data.map((item) => {
            const { id, text } = item;
            if (item.text === "All") {
              if (max) {
                return <div />;
              }
            }
            return (
              <li
                key={id}
                onClick={() => {
                  if (item.text === "All") {
                    if (sortActive.length > 1) {
                      setsortActive([]);
                    } else setsortActive(data.slice(1));
                  } else if (sortActive.some((x) => x.id === id)) {
                    const filteredArr = sortActive.filter((x) => x.id !== id);
                    setsortActive(filteredArr);
                  } else {
                    if (sortActive.length > max) {
                    } else {
                      setsortActive([...sortActive, item]);
                    }
                  }
                }}
              >
                <button className="dropdown-item font-display dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white">
                  <span className="text-jacarta-700 dark:text-white">
                    {text}
                  </span>
                  {sortActive.some((x) => x.id === id) && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="fill-accent mb-[3px] h-4 w-4"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                    </svg>
                  )}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Collection_category_filter;
