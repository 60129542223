import React, { useState, useEffect, useTransition } from "react";
import { HeadLine } from "../../components/component";
import BidsCarousel from "../../components/carousel/bidsCarousel";
import CustomizableGig from "../../components/ui/CustomizableGig";
import Skeleton from "@mui/material/Skeleton";
import Fab from "@mui/material/Fab";
import { Typography } from "@mui/material";
import { Grid, Stack, Box, Divider } from "@mui/material";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import styled from "@emotion/styled";
import Zoom from "@mui/material/Zoom";
import Link from "next/link";
import { useTranslation } from "react-i18next";
const excludedProps = ["background", "bottomOffset", "color", "rightOffset"];

const shouldForwardProp = (p) => !excludedProps.includes(p);

const More_items = ({
  gridSx,
  renderCategories,
  parentStyles,
  gridItemSize,
  user,
  thumbnailHeight,
  fetching,
  selectedHook,
  title,
  subtitle,
  selections,
  fabHidden,
  selectedItemsHook,
  priceHook,
  cartHook,
  renderPrice,
  price,
  disabled,
}) => {
 
  const [influencerSources, setSources] = useState(null);
  const [selected, setSelected] = useState([]);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  useEffect(() => {
    let price = 0;
    const selectedItems = selectedItemsHook || selected;
    const cart = [];
    user &&
      Object.keys(user)?.map((key) => {
        const socialkeys = [
          "instagram",
          "facebook",
          "tiktok",
          "twitch",
          "realLife",
          "youtube",
        ];
        if (!socialkeys.includes(key) || fetching) {
          return;
        }
        const socialMedia = user[key];

        socialMedia.items.map((value, index) => {
          if (selectedItems.includes(value.type)) {
            price = parseInt(price + value.price);
            cart.push({
              ...value,
              key: key,
            });
          }
        });
      });
    if (cartHook) {
      cartHook(cart);
    }
    if (priceHook) {
      priceHook(price);
      setTotal(price);
    }
  }, [selectedItemsHook]);

  useEffect(() => {
    if (selectedHook) {
      selectedHook(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (user) {
      setSources(user);
    }
  }, [user]);
 

  const onChange = (type, item, category, price, checked) => {
    if (disabled) {
      return;
    }
    const selectedArr = selectedItemsHook ? selectedItemsHook : selected;
    selectedArr.find((x) => x === item.type)
      ? !checked && setSelected(selectedArr.filter((x) => x !== item.type))
      : checked && setSelected([...selectedArr, item.type]);
  };

  return (
    <section
      className={`dark:bg-jacarta-800 bg-light-base py-12 more__items ${parentStyles}`}
    >
      {!fabHidden && (
        <Link
        className='disabled-link'
          href={selected.length>0 ? {
            pathname: `/orders/new/${user?.uid}`,
            query: selected,
          } : '#'}
        >
          <Zoom
            key={1}
            in={true}
            timeout={1000}
            style={{
              transitionDelay: `500ms`,
            }}
            unmountOnExit
          >
            <Fab
              sx={{
                bgcolor:
                  selected.length > 0
                    ? "#5863ff !important"
                    : "gray !important",
                border: "none",
                borderRadius: 20,
                bottom: 20,
                boxSizing: "border-box",
                color: "white",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 500,
                height: 50,
                letterSpacing: 0.25,
                lineHeight: 1,
                margin: 0,
                minWidth: 50,
                padding: 1,

                position: "fixed",
                right: 50,
                transition: "all 0.5s ease-out;",
                width: "auto",
                WebkitAppearance: "none",
                "&:hover": {
                  bgcolor: "#5863ff !important",
                  transition: "all 0.5s ease-out;",
                },
              }}
              color="primary"
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ px: 2 }}
              >
                <RocketLaunchIcon />
                {selected.length > 0 && (
                  <Typography
                    sx={{ m: 2 }}
                    style={{ animation: "fadeIn 1s" }}
                    component="div"
                  >
                    Purchase {<b>{selected.length}</b>} services 😍
                  </Typography>
                )}
              </Stack>
            </Fab>
          </Zoom>
        </Link>
      )}

      <HeadLine
        text={title ? title : "Available services from this user"}
        classes="font-display text-jacarta-700 mb-2 text-center text-3xl dark:text-white container"
      />
      <h2 className="dark:text-jacarta-300  text-lg leading-normal2 text-center mb-5 container">
        {subtitle ? (
          subtitle
        ) : (
          <>
            <span className="text-accent">
              <b>Select</b>{" "}
            </span>{" "}
            what interests you and start discussing a{" "}
            <span className="text-accent">
              <b>deal</b>{" "}
            </span>
            of a lifetime.
          </>
        )}
      </h2>

      {priceHook || renderPrice && (
        <h2 suppresshydrationwarning 
          className="dark:border-jacarta-600 border-jacarta-100  border-t border-b
						pt-5 
						font-display text-jacarta-700   font-semibold dark:text-white text-center text-md"
        >
          Total price: &nbsp;
          <span className="text-green font-medium tracking-tight">
            {renderPrice ? price : total}$
          </span>
        </h2>
      )}

      <Grid container spacing={3} sx={{ md: { px: 5 } }}>
        {fetching &&
          ["", "", ""].map((value, key) => (
            <Grid item key={key} xs={12} md>
              {" "}
              <Skeleton
               sx={{ bgcolor: "grey.700", transform: 'initial', transformOrigin:'initial' }}
            
               
                height={420}
                width={"100%"}
              />{" "}
            </Grid>
          ))}

        {user &&
          Object.keys(user)?.map((key) => {
            const socialkeys = [
              "instagram",
              "facebook",
              "tiktok",
              "twitch",
              "realLife",
              "youtube",
            ];
            if (!socialkeys.includes(key) || fetching) {
              return;
            }
            const socialMedia = user[key];
            const checkCount = socialMedia.items.filter(
              (x) => x.checked === true
            );
         
            if (checkCount?.length < 1) {
              return;
            }

            return (
              <>
                {
               ( (socialMedia.items.filter((f) => f.checked && selections?.includes(f.type)).length>0) || renderCategories) && 
                  <h3 className="text-jacarta-700 font-display mb-[-20px] pt-4 mt-7 text-3xl text-jacarta-100 bg-transparent no-shadow  normal-case w-full text-center container">
                  {t(`platforms.${key}`)} 
                </h3>
                }
                
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 1.5, ...gridSx }}
                  key={key}
                >
                  {socialMedia.items.map((value, index) => {
                    if (!value.checked) return;

                    const checkedProp = selections?.includes(value.type);
                    if (checkedProp && selections)
                      return (
                        <Grid
                          key={index + "&"}
                          item
                          xs={12}
                          md={
                            gridItemSize &&
                            socialMedia?.items.length > 2 &&
                            index + 1 !== socialMedia?.items?.length
                              ? gridItemSize
                              : true
                          }
                        >
                          <CustomizableGig
                            thumbnailHeight={thumbnailHeight}
                            checkedProp={checkedProp}
                            displayMode
                            title={value.title}
                            item={value}
                            category={key}
                            disabled={disabled}
                            image={value.image}
                            type={value.type}
                            priceProps={value.price}
                            onChange={onChange}
                          />
                        </Grid>
                      );
                    else if (!selections) {
                      return (
                        <Grid key={index + "&"} item xs={12} md>
                          <CustomizableGig
                            thumbnailHeight={thumbnailHeight}
                            checkedProp={checkedProp}
                            displayMode
                            title={value.title}
                            item={value}
                            category={key}
                            image={value.image}
                            type={value.type}
                            priceProps={value.price}
                            onChange={onChange}
                          />
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </>
            );
          })}
      </Grid>
      {/* <!-- end hot bids --> */}
    </section>
  );
};

export default More_items;
