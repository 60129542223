import Image from "next/image";
import Link from "next/link";

import Logo from "./../../public/images/logo.png";
import WhiteLogo from "./../../public/images/logo_white.png";

export default function Header03() {
  return (
    <>
      <header className="js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors">
        <div className="container">
          <div className="flex items-center py-[1.5625rem] lg:py-[1.8125rem]">
            {/* Logo */}
            <Link href="/">
            <a>
              <div className="dark:hidden">
              <img
                    src="/images/logo.png"
                    className="max-h-10 dark:hidden"
                    alt="Xhibiter | NFT Marketplace"
                  />
              </div>
              <div className="hidden dark:block">
           
                  <img
                    src="/images/logo_white.png"
                    alt="Xhibiter | NFT Marketplace"
                    className="max-h-10 dark:block hidden"
                  />
              </div>
            </a>
            </Link>
          
            <a
              href="#"
              className="bg-accent opacity-0 shadow-accent-volume hover:bg-accent-dark ml-auto inline-block rounded-full py-2.5 px-8 text-center text-sm font-semibold text-white transition-all"
            >
              <span className="hidden lg:block">Subscribe to Updates</span>
              <span className="lg:hidden">Subscribe</span>
            </a>
          </div>
        </div>
      </header>
    </>
  );
}
